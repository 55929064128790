import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

const ViaFormatter = ({ value }) => <TextField value={value == "0" ? "0 - Indefinido" : 
                                                      value == "1" ? "1 - Única" : 
                                                      value == "2" ? "2 - Mesma via" : 
                                                      value == "3" ? "3 - Diferentes vias" : ""} InputProps={{ disableUnderline: true }} />;

const ViaEditor = ({ value, onValueChange }) => (
  <Select input={<Input />} value={value} onChange={(event) => onValueChange(event.target.value)} style={{ width: "100%" }}>
    <MenuItem value="">N/A</MenuItem>
    <MenuItem value="0">0 - Indefinido</MenuItem>
    <MenuItem value="1">1 - Única</MenuItem>
    <MenuItem value="2">2 - Mesma via</MenuItem>
    <MenuItem value="3">3 - Diferentes vias</MenuItem>
  </Select>
);

export const ViaTypeProvider = (props) => <DataTypeProvider formatterComponent={ViaFormatter} editorComponent={ViaEditor} {...props} />;
