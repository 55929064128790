import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

const BooleanFormatter = ({ value }) => <Chip label={value ? "Sim" : "Não"} />;

const BooleanEditor = ({ value, onValueChange, disabled }) => {
  if (disabled) {
    return null;
  }

  return (
    <Select input={<Input />} value={value ? "Yes" : "No"} onChange={(event) => onValueChange(event.target.value === "Yes")} style={{ width: "100%" }}>
      <MenuItem value="Yes">Sim</MenuItem>
      <MenuItem value="No">Não</MenuItem>
    </Select>
  );
};

export const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} editorComponent={BooleanEditor} {...props} />;
