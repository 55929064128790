import Input from "@material-ui/core/Input";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import TextField from "@material-ui/core/TextField";

const DateTimeFormatter = ({ value }) => OnlyDateTime(value).replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/, "$3/$2/$1 $4:$5:$6");

const DateTimeEditor = ({ value, onValueChange, disabled }) => {
  if (disabled) {
    return value ? DateTimeFormatter({ value }) : "";
  }

  let dateField = "";
  if (value) {
    dateField = OnlyDateTime(value);
  }
  return (
    <TextField
      input={<Input />}
      id="date"
      type="date"
      defaultValue={dateField}
      onChange={(event) => onValueChange(event.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

const OnlyDateTime = (value) => {
  var newValue = "";
  if (value) {
    var apiDate = new Date(value);
    newValue = apiDate?.toLocaleString('pt-BR')
    newValue = newValue?.substring(0, 16)
  }
  return newValue;
};

export const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={DateTimeFormatter} editorComponent={DateTimeEditor} {...props} />;
