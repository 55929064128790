import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState, EditingState, CustomPaging, FilteringState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, ExportXlsx, APPNAME_RELATORIOPERSONALIZADO } from "../utils/react_grid_utils";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import authenticatedUserContext from "../security/AuthenticatedUserContext";
import { NumberTypeProvider } from './../utils/react_grid_number_column';

const getRowId = (row) => row.id;
const api_resource = "relatorio-personalizado/ticket-medio";

export default function TicketMedioTable() {
  const alert = useAlert();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [filteringStateColumnExtensions] = useState([{ columnName: "ticket_medio", filteringEnabled: false }]);
  const [tableColumnExtensions] = useState([
    { columnName: "id", width: 60 },
    { columnName: "codigo_especialidade", width: 180 },
    { columnName: "descricao_especialidade", width: 500 },
    { columnName: "ticket_medio", width: 200 },
  ]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);

  function get(page, filters) {
    const queryString = QueryString(page, pageSize, filters);
    http.get(api_resource + queryString).then(
      (response) => {
        console.log(response?.data?.data);
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    get(0, []);
  }, []);

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, 1000)
    );
  }

  function handlePage(page, totalRows) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      Create(api_resource, added[0], alert).then((response) => {
        var new_row = response.data[0];
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      var item = changedRows.find((row) => changed[row.id]);
      Update(api_resource, item, alert);
      setRows(changedRows);
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };

  var columns = [
    { name: "id", title: "ID" },
    { name: "codigo_especialidade", title: "Código especialidade" },
    { name: "descricao_especialidade", title: "Descrição da especialidade" },
    { name: "ticket_medio", title: "Ticket médio" },
    { name: "tipo_recebimento", title: "Tipo de recebimento" },
  ];

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Ticket médio</h2>
          </div>
          <div className="col-lg-2">
            <button className="btn btn-primary btn-sm" style={{ marginTop: "20px" }} type="button" onClick={() => ExportXlsx(api_resource, alert)}>
              Exportar
            </button>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} />
                    <FilteringState onFiltersChange={handleFilters} columnExtensions={filteringStateColumnExtensions}/>
                    <Table columnExtensions={tableColumnExtensions} />
                    <NumberTypeProvider for={["ticket_medio"]} />
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <TableEditRow />
                    {authenticatedUserContext.allow_edit(APPNAME_RELATORIOPERSONALIZADO) === true ? <TableEditColumn showAddCommand={!addedRows.length} showEditCommand showDeleteCommand messages={editColumnMessages} /> : <></>}
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableFilterRow />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
