import Input from "@material-ui/core/Input";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import TextField from "@material-ui/core/TextField";

const DateFormatter = ({ value }) => OnlyDate(value).replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1");

const DateEditor = ({ value, onValueChange, disabled }) => {
  if (disabled) {
    return value ? DateFormatter({ value }) : "";
  }

  let dateField = "";
  if (value) {
    dateField = OnlyDate(value);
  }
  return (
    <TextField
      input={<Input />}
      id="date"
      type="date"
      defaultValue={dateField}
      onChange={(event) => onValueChange(event.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

const OnlyDate = (value) => {
  var newValue = "";
  if (value) {
    newValue = value?.substr(0, 10);
  }
  return newValue;
};

export const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={DateFormatter} editorComponent={DateEditor} {...props} />;
