// import { useContext } from "react";
// import { useHistory } from "react-router-dom";
import CookieService from "../../services/CookieService";
// import TakaokaContext from "../contexts/TakaokaContext";
// import { http } from "../../services/http";

// export default function SetAuthenticatedUserContext() {
//   const history = useHistory();

//   var consumer = useContext(TakaokaContext);
//   var email = CookieService.get("email");

//   http
//     .get("authenticated-user" + "?email=" + email)
//     .then(function (response) {
//       let user = response?.data?.user;

//       consumer.updateContext({
//         user,
//       });

//       history.push({ pathname: "/" });
//       history.replace({ pathname: "/" });
//     })
//     .catch(function (error) {
//       CookieService.remove("access_token");
//       CookieService.remove("email");
//       history.push({ pathname: "/" });
//     });
// }

const emailAdmin = process.env.REACT_APP_EMAIL_ADMIN;

const authenticatedUserContext = () => {
  const allow_edit = (ui_name) => {
    let user = CookieService.get("user");

    if (user?.email == emailAdmin) {
      return true;
    } else {
      let ui = user?.user_access?.find((elem) => elem.name == ui_name);

      return ui?.allow_edit;
    }
  };

  return {
    allow_edit,
  };
};

export default authenticatedUserContext();
