import React, { useState, useEffect } from "react";
import Input from "@material-ui/core/Input";
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import { Autocomplete } from '@material-ui/lab';
import MenuItem from "@material-ui/core/MenuItem";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { SmartService } from "../../services/SmartService";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 550,
    marginTop: theme.spacing(0.4),
    // '& > * + *': {
    //   marginTop: theme.spacing(2),
    // },
  },
}));

const ProcedimentosSmartFormatter = ({ value }) => { 
  const classes = useStyles();
return (<TextField className={classes.root}  value={value} InputProps={{ disableUnderline: true }} />)
};


const ProcedimentosSmartEditor = ({ value, onValueChange, disabled }) => {
  const [options, setOptions] = useState([]);
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState({ externalCode: "", name: value });

  function searchProcedimentos(value) {
    SmartService.post("authentication/login", {
        'username':process.env.REACT_APP_SMART_USER,
        'password':process.env.REACT_APP_SMART_PASSWORD,
      }
  )      
  .then((response) => {
      SmartService.get("scheduling-types?keyword=" + value,
        {
          headers: {
            'Authorization': `Bearer ${response.data}`
        }
      })
      .then((response) => {
        if(response?.status == 200)
        {
          setOptions(response?.data);
        }
      });
  
  })
  }

  const [timer, setTimer] = useState(null);

  function triggerChange(inputText) {
    if (inputText.length > 2 || inputText.length == 0) {
      searchProcedimentos(inputText);
    }
  }

  function handleKeyUp(value) {

    clearTimeout(timer);

    setTimer(
      setTimeout(function () {
        triggerChange(value);
      }, 400)
    );
  }

  function getText(codigo, descricao)
  {
    let text = "";
    if(codigo != undefined && codigo != "")
      text = `${codigo} - `;
    
    if(descricao != undefined && descricao != "")
      text = text + descricao;

      return text;
  }

  if (disabled) {
    return value;
  }

  return (
    <div className={classes.root}>
    <Autocomplete
    options={options}
    defaultValue={defaultValue}
    size="small"
    getOptionLabel={(option) => getText(option?.externalCode, option?.name)}
    onInputChange={(event, newInputValue) => {
      handleKeyUp(newInputValue);
      onValueChange(newInputValue);
    }}
    renderInput={(params) => <TextField {...params} label="" variant="standard" />}/>

</div>
  );
};
export const ProcedimentosSmartTypeProvider = (props) => <DataTypeProvider formatterComponent={ProcedimentosSmartFormatter} editorComponent={ProcedimentosSmartEditor} {...props} />;
