import React, { useState } from "react";
import { Route, Switch, MemoryRouter } from "react-router-dom";
import App from "./App";
import Login from "./components/login/login";
import UserTable from "./components/users/UserTable";
import UserForm from "./components/users/UserForm";
import UserDetails from "./components/users/UserDetails";
import UserEditForm from "./components/users/UserEditForm";
import PrivateRoute from "./components/security/PrivateRoute";

import TakaokaContext from "./components/contexts/TakaokaContext";
import AgendamentosTable from "./components/fat_geracao_xml/AgendamentosTable";
import RevisaoAgendamentosTable from "./components/fat_geracao_xml/RevisaoAgendamentosTable";
import RevisaoLotesTable from "./components/fat_geracao_xml/RevisaoLotesTable";
import CadastroManualTable from "./components/fat_geracao_xml/CadastroManualTable";
import MatrizTable from "./components/fat_geracao_xml/MatrizTable";
import MatrizConveniosTable from "./components/fat_geracao_xml/MatrizConveniosTable";
import CrmCpfTable from "./components/fat_geracao_xml/CrmCpfTable";
import CnesCnpjTable from "./components/fat_geracao_xml/CnesCnpjTable";
import DeparaTable from "./components/fat_geracao_xml/DeparaTable";
import FeriadosTable from "./components/fat_geracao_xml/FeriadosTable";
import PortesTable from "./components/fat_geracao_xml/PortesTable";
import OperadorasTable from "./components/fat_geracao_xml/OperadorasTable";
import TussTable from "./components/fat_geracao_xml/TussTable";
import GrupoSlotsTable from "./components/predicao_overbooking/GrupoSlotsTable";
import SlotsTable from "./components/predicao_overbooking/SlotsTable";
import AgrupamentosTable from "./components/predicao_overbooking/AgrupamentosTable";
import AgrupamentosHospitaisTable from "./components/predicao_overbooking/AgrupamentosHospitaisTable";
import AgendamentoRetroativo from "./components/smart_sap/AgendamentoRetroativo";
import ReprocessarSapTable from "./components/smart_sap/ReprocessarSapTable";
import AlterarStatusTable from "./components/smart_sap/AlterarStatusTable";
import PesquisadeAvaliacaoTable from "./components/mailing/PesquisadeAvaliacaoTable";


import DescontoMedicoTable from "./components/demonstrativo_pagamento/DescontoMedicoTable";
import MedicoTable from "./components/demonstrativo_pagamento/MedicoTable";
import ProcessarDemonstrativoTable from "./components/demonstrativo_pagamento/ProcessarDemonstrativoTable";

import DeParaConveniosTable from "./components/agendamento/DeParaConveniosTable";
import ImportacaoLoteAgendamentosTable from "./components/agendamento/ImportacaoLoteAgendamentosD2ITable";
import DeParaProcedimentosH94Table from "./components/automacao_cargah9j/DeParaProcedimentosH9JTable";

import ConvenioLoteTable from "./components/notafiscal/ConvenioLoteTable";
import IndividualTable from "./components/notafiscal/IndividualTable";
import NPedidosTable from "./components/notafiscal/NPedidosTable";

import AnestesistasInclusaoTable from "./components/relatorio_personalizado/AnestesistasInclusaoTable";
import AnestesistasExclusaoTable from "./components/relatorio_personalizado/AnestesistasExclusaoTable";
import ProcedimentosAnestesistaExclusaoTable from "./components/relatorio_personalizado/ProcedimentosAnestesistasExclusaoTable";
import TicketMedioTable from "./components/relatorio_personalizado/TicketMedioTable";
import ParametroTable from "./components/parametro/ParametroTable";
import GestaoContatos from "./components/apa_digital/GestaoContatos";

export const AppRoute = "/";
export const LoginRoute = "/login";
export const UsersTableRoute = "/users";
export const NewUserRoute = "/new-user";
export const EditUserRoute = "/edit-user";
export const UserDetailsRoute = "/user-details";
export const AgendamentosTableRoute = "/agendamentos";
export const RevisaoAgendamentosTableRoute = "/revisao-agendamentos";
export const RevisaoLotesTableRoute = "/revisao-lotes";
export const CadastroManualTableRoute = "/processamento-manual"
export const MatrizTableRoute = "/matriz";
export const MatrizConveniosTableRoute = "/matriz-convenios";
export const CrmCpfTableRoute = "/crm-cpf";
export const CnesCnpjTableRoute = "/cnes-cnpj";
export const DeParaTableRoute = "/depara";
export const FeriadosTableRoute = "/feriados";
export const PortesTableRoute = "/portes";
export const OperadorasTableRoute = "/operadoras";
export const TussTableRoute = "/tuss";
export const GrupoSlotsTableRoute = "/grupo-slots";
export const SlotsTableRoute = "/slots";
export const AgrupamentosTableRoute = "/agrupamentos";
export const AgrupamentosHospitaisTableRoute = "/agrupamentos-hospitais";

export const DescontoMedicoTableRoute = "/desconto-medico";
export const MedicoTableRoute = "/medicos";
export const ProcessarDemonstrativoRoute = "/processar-demonstrativo"

export const DeParaConveniosTableRoute = "/de-para-convenios";
export const DeParaProcedimentosH9JTablerRoute = "/de-para-procedimentos";
export const ImportacaoLoteAgendamentosTableRoute = "/importacao-lote-agendamentos";

export const ConvenioLoteTableRoute = "/notafiscal/convenios";
export const IndividualTableRoute = "/notafiscal/individual";
export const NPedidosTableRoute = "/notafiscal/npedidos";

export const AgendamentoRetroativoRoute = '/agendamento-retroativo'
export const ReprocessarSapTableRoute = '/reprocessar-sap'
export const AlterarStatusTableRoute = '/alterar-sap'
export const PesquisadeAvaliacaoRoute = '/pesquisa-avaliaca'

export const RelatorioPersonalizadoAnestesistasInclusaoRoute = '/relatorio-personalizado/anestesistas-inclusao'
export const RelatorioPersonalizadoAnestesistasExclusaoRoute = '/relatorio-personalizado/anestesistas-exclusao'
export const RelatorioPersonalizadoProcedimentosAnestesistaExclusaoRoute = '/relatorio-personalizado/procedimentos-anestesista-exclusao'
export const RelatorioPersonalizadoTicketMedioRoute = '/relatorio-personalizado/ticket-medio'

export const ApaDigitalGestaoContatos = '/apa-digital/gestao-contatos'

export const ParametroRoute = '/parametro/parametro-configuracao'
export const ParametersRoute = '/parameters'

export default function Routes() {
  const [user, setUser] = useState({});

  return (
    <React.Fragment>
      <TakaokaContext.Provider
        value={{
          user,
          setUser,
          updateContext: (context) => {
            setUser(context.user);
          },
        }}
      >        
          <MemoryRouter>
            <Switch>
              <PrivateRoute path={LoginRoute} component={(props) => <Login {...props} />} />
              <PrivateRoute path={UsersTableRoute} component={(props) => <UserTable {...props} />} />
              <PrivateRoute path={NewUserRoute} component={(props) => <UserForm {...props} />} />
              <PrivateRoute path={EditUserRoute} component={(props) => <UserEditForm {...props} />} />
              <PrivateRoute path={UserDetailsRoute} component={(props) => <UserDetails {...props} />} />
              <PrivateRoute path={AgendamentosTableRoute} component={(props) => <AgendamentosTable {...props} />} />
              <PrivateRoute path={RevisaoAgendamentosTableRoute} component={(props) => <RevisaoAgendamentosTable {...props} />} />
              <PrivateRoute path={RevisaoLotesTableRoute} component={(props) => <RevisaoLotesTable {...props} />} />
              <PrivateRoute path={CadastroManualTableRoute} component={(props) => <CadastroManualTable {...props} />} />
              <PrivateRoute path={MatrizTableRoute} component={(props) => <MatrizTable {...props} />} />
              <PrivateRoute path={MatrizConveniosTableRoute} component={(props) => <MatrizConveniosTable {...props} />} />
              <PrivateRoute path={CrmCpfTableRoute} component={(props) => <CrmCpfTable {...props} />} />
              <PrivateRoute path={CnesCnpjTableRoute} component={(props) => <CnesCnpjTable {...props} />} />
              <PrivateRoute path={DeParaTableRoute} component={(props) => <DeparaTable {...props} />} />
              <PrivateRoute path={FeriadosTableRoute} component={(props) => <FeriadosTable {...props} />} />
              <PrivateRoute path={PortesTableRoute} component={(props) => <PortesTable {...props} />} />
              <PrivateRoute path={OperadorasTableRoute} component={(props) => <OperadorasTable {...props} />} />
              <PrivateRoute path={TussTableRoute} component={(props) => <TussTable {...props} />} />
              <PrivateRoute path={DescontoMedicoTableRoute} component={(props) => <DescontoMedicoTable {...props} />} />
              <PrivateRoute path={MedicoTableRoute} component={(props) => <MedicoTable {...props} />} />
              <PrivateRoute path={ProcessarDemonstrativoRoute} component={(props) => <ProcessarDemonstrativoTable {...props} />} />
              <PrivateRoute path={DeParaConveniosTableRoute} component={(props) => <DeParaConveniosTable {...props} />} />
              <PrivateRoute path={ImportacaoLoteAgendamentosTableRoute} component={(props) => <ImportacaoLoteAgendamentosTable {...props} />} />
              <PrivateRoute path={DeParaProcedimentosH9JTablerRoute} component={(props) => <DeParaProcedimentosH94Table {...props} />} />
              <PrivateRoute path={ConvenioLoteTableRoute} component={(props) => <ConvenioLoteTable {...props} />} />
              <PrivateRoute path={IndividualTableRoute} component={(props) => <IndividualTable {...props} />} />
              <PrivateRoute path={NPedidosTableRoute} component={(props) => <NPedidosTable {...props} />} />
              <PrivateRoute path={GrupoSlotsTableRoute} component={(props) => <GrupoSlotsTable {...props} />} />
              <PrivateRoute path={SlotsTableRoute} component={(props) => <SlotsTable {...props} />} />
              <PrivateRoute path={AgrupamentosTableRoute} component={(props) => <AgrupamentosTable {...props} />} />
              <PrivateRoute path={AgrupamentosHospitaisTableRoute} component={(props) => <AgrupamentosHospitaisTable {...props} />} />
              <PrivateRoute path={AgendamentoRetroativoRoute} component={(props) => <AgendamentoRetroativo {...props} />} />
              <PrivateRoute path={ReprocessarSapTableRoute} component={(props) => <ReprocessarSapTable {...props} />} />
              <PrivateRoute path={AlterarStatusTableRoute} component={(props) => <AlterarStatusTable {...props} />} />
              <PrivateRoute path={PesquisadeAvaliacaoRoute} component={(props) => <PesquisadeAvaliacaoTable {...props} />} />
              <PrivateRoute path={RelatorioPersonalizadoAnestesistasInclusaoRoute} component={(props) => <AnestesistasInclusaoTable {...props} />} />
              <PrivateRoute path={RelatorioPersonalizadoAnestesistasExclusaoRoute} component={(props) => <AnestesistasExclusaoTable {...props} />} />
              <PrivateRoute path={RelatorioPersonalizadoProcedimentosAnestesistaExclusaoRoute} component={(props) => <ProcedimentosAnestesistaExclusaoTable {...props} />} />
              <PrivateRoute path={RelatorioPersonalizadoTicketMedioRoute} component={(props) => <TicketMedioTable {...props} />} />
              <PrivateRoute path={ApaDigitalGestaoContatos} component={(props) => <GestaoContatos {...props} />} />
              <PrivateRoute path={ParametroRoute} component={(props) => <ParametroTable {...props} />} />
              <PrivateRoute path={ParametersRoute} component={(props) => <ParametroTable {...props} />} />
              <PrivateRoute path={AppRoute} component={(props) => <App {...props} />} />
            </Switch>
          </MemoryRouter>
      </TakaokaContext.Provider>
    </React.Fragment>
  );
}
