import React from "react";
import { useHistory } from "react-router-dom";
import CookieService from "../services/CookieService";
import {
  useMsal,
} from "@azure/msal-react";

export default function Sair() {
  const history = useHistory();

  const { accounts, instance } = useMsal();

  function handleLogout() {
    CookieService.remove("access_token");
    CookieService.remove("email");
    CookieService.remove("api_token");

    signOutClickHandler(instance);

    history.push({ pathname: "/" });
  }

  function signOutClickHandler(instance) {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
    };
    instance.logoutRedirect(logoutRequest);
  }

  return (
    <a href="#" onClick={handleLogout}>
      <i className="fa fa-sign-out"></i> <span className="nav-label">Sair</span>
    </a>
  );
}
