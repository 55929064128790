import React, { useState } from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import { size } from "lodash";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  numericInput: {
    fontSize: "14px",
    paddingRight: "5px",
    textAlign: "right",
    width: "100%",
  },
});

const CpfEditorBase = ({ value, onValueChange, disabled }) => {
  // const [mask, setMask] = useState("999.999.999-99");

  // const handleMask = (e) => {
  //   if (size(e.target.value) < 14) {
  //     setMask("99.999.999-999");
  //   } else {
  //     setMask("999.999.999-99");
  //   }
  // };

  return (
    <InputMask mask="999.999.999-99" maskChar={null} value={value} onChange={(event) => onValueChange(event.target.value)}>
      {(inputProps) => <MaterialInput {...inputProps} />}
    </InputMask>
  );
};

const CpfEditor = withStyles(styles)(CpfEditorBase);

export const CpfTypeProvider = (props) => <DataTypeProvider editorComponent={CpfEditor} {...props} />;
