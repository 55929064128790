import "./App.css";
import Menu from "./components/Menu";
import Header from "./components/Header";

export default function App() {
  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <Header />
        <h2>Takaoka Bots</h2>
        <div className="wrapper wrapper-content animated fadeInRight"></div>
      </div>
    </div>
  );
}
