import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { Link } from "react-router-dom";
import Header from "../Header";
import DataTable from "react-data-table-component";
import { http } from "../../services/http";

const ENTER_KEY = 13;

export default function UserTable() {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [inputText, setInputText] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [inactive, setInactive] = useState(false);

  function searchUsers(page, search) {
    var paging = `&page=${page}&limit=10`;
    http.get("users?search=" + search + paging).then(
      (response) => {
        console.log(response?.data?.data);
        setUsers(response?.data?.data);
        setTotalItens(parseInt(response?.data?.total));
        setPageIndex(page);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    searchUsers(1, inputText);
  }, []);

  const [timer, setTimer] = useState(null);

  function triggerChange() {
    if (inputText.length > 2 || inputText.length == 0) {
      setSearchText(inputText);
      searchUsers(1, inputText);
    }
  }

  function handleKeyUp(e) {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(timer);
      triggerChange();
    }

    clearTimeout(timer);

    setTimer(
      setTimeout(function () {
        triggerChange();
      }, 400)
    );
  }

  function handleSearchText(e) {
    setInputText(e.target.value);
  }

  function handlePage(page, totalRows) {
    setPageIndex(page);
    searchUsers(page, inputText);
  }

  function handleInactive(e) {
    setPageIndex(1);
    setInactive(e.target.checked);
  }

  function handleChangeRowsPerPage(perPage) {
    setPageSize(perPage);
    searchUsers(1, inputText);
  }

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <Header />
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Users</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Home</a>
              </li>
            </ol>
          </div>
          <div className="col-lg-2" style={{ textAlign: "right", alignSelf: "flex-end" }}>
            <Link to="/new-user">
              <button type="button" className="btn btn-w-m btn-info">
                New User
              </button>
            </Link>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-title">
                  <div className="form-group row">
                    <div className="col-sm-8">
                      <input value={inputText} placeholder="Search" onChange={handleSearchText} onKeyUp={handleKeyUp} type="text" className="form-control" />
                    </div>
                    <div className="col-sm-2" style={{ textAlign: "right", alignSelf: "flex-end" }}>
                      <input type="checkbox" value={inactive} onChange={handleInactive} defaultChecked={inactive} /> <label>Inactive</label>
                    </div>
                  </div>
                </div>
                <div className="ibox-content">
                  <DataTable
                    data={users?.map(({ name, id, email, active }) => ({
                      id,
                      name,
                      email,
                      active,
                      active: active != null ? active.toString() : "false",
                    }))}
                    pagination={true}
                    paginationServer={true}
                    paginationTotalRows={totalItens}
                    paginationPerPage={pageSize}
                    paginationDefaultPage={pageIndex}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onChangePage={handlePage}
                    columns={[
                      {
                        name: "Name",
                        minWidth: "150px",
                        cell: (row) => (
                          <div>
                            <Link to={{ pathname: "/user-details", user_id: row.id }}>
                              <a>{row.name}</a>
                            </Link>
                          </div>
                        ),
                      },
                      {
                        name: "Email",
                        selector: "email",
                        minWidth: "230px",
                        wrap: true,
                        // cell: row => <div data-tag="allowRowEvents">{row.email}</div>
                      },
                      {
                        name: "Active",
                        selector: "active",
                        compact: true,
                        grow: true,
                      },
                      {
                        name: "#",
                        compact: true,
                        cell: (row) => (
                          <Link to={{ pathname: "/edit-user", user_id: row.id }}>
                            <button type="button" className="btn btn-primary btn-sm">
                              Edit
                            </button>
                          </Link>
                        ),
                        grow: true,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
