import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

const MailingFormatter = ({ value }) => <TextField value={value == "COD" ? "Código operadora" : value} InputProps={{ disableUnderline: true }} />;

const MailingEditor = ({ value, onValueChange, disabled }) => {
  if (disabled) {
    return value ?? "";
  }

  return (
    <Select input={<Input />} value={value} onChange={(event) => onValueChange(event.target.value)} style={{ width: "100%" }}>
      <MenuItem value="CONCLUIDO">CONCLUIDO</MenuItem>
      <MenuItem value="CANCELADO">CANCELADO</MenuItem>
    </Select>
  );
};
export const MailingTypeProvider = (props) => <DataTypeProvider formatterComponent={MailingFormatter} editorComponent={MailingEditor} {...props} />;
