import React, { useEffect, useState } from "react";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import AlertTemplate from "react-alert-template-basic";
import EnvironmentContext from "./components/contexts/EnvironmentContext";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import axios from "axios";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";

export default function Main() {
  axios.defaults.baseURL = process.env.REACT_APP_TAKAOKABOTS_API_URL;
  axios.defaults.headers.get["Accept"] = "application/json";
  axios.defaults.headers.post["Accept"] = "application/json";

  const [takaokabotsApiUrl, setTakaokaBotsApiUrl] = useState("");

  const getConfig = () => {
    fetch("config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (configJson) {
        setTakaokaBotsApiUrl(configJson.TAKAOKABOTS_API_URL);
        console.log(configJson);
      });
  };

  useEffect(() => {
    getConfig();
  }, []);

  // optional configuration
  const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 2000,
    offset: "30px",
    // you can also just use 'scale'
    transition: transitions.SCALE,
  };

  return (
    <EnvironmentContext.Provider
      value={{
        takaokabotsApiUrl,
        setTakaokaBotsApiUrl,
        updateContext: (context) => {
          setTakaokaBotsApiUrl(context.takaokabotsApiUrl);
        },
      }}
    >
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <AlertProvider template={AlertTemplate} {...options}>
            <BrowserRouter>
              <Routes></Routes>
            </BrowserRouter>
          </AlertProvider>
        </MsalAuthenticationTemplate>
    </EnvironmentContext.Provider>
  );
}
