export default function possuiValor(propriedade) {
  return propriedade !== null && propriedade !== undefined && propriedade !== "";
}

export const toInputUppercase = (e) => {
  e.target.value = ("" + e.target.value).toUpperCase();
};

export const toInputLowercase = (e) => {
  e.target.value = ("" + e.target.value).toLowerCase();
};

export const downloadXlsx = (data) => {
  var link = document.createElement("a");
  const url = window.URL.createObjectURL(new Blob([data]));
  //link.href = "data:attachment/xlsx," + +encodeURI(data);
  link.href = url;
  link.target = "_blank";
  link.download = "export.xlsx";
  link.click();
};

Array.prototype.sortBy = function (p) {
  return this.slice(0).sort(function (a, b) {
    return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
  });
};
