import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { PagingState, EditingState, CustomPaging, FilteringState, IntegratedFiltering } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, ExportXlsx, TIMER_FILTER } from "../utils/react_grid_utils";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import { TimeTypeProvider } from "../utils/react_grid_time_column";
import { DateTypeProvider } from "../utils/react_grid_date_column";

const GrupoSlotEditor = ({ value, onValueChange, disabled }) => {
  const [options, setOptions] = useState([]);

  function getGrupoSlots() {
    http.get("previsao-agendamentos/grupo-slots").then((response) => {
      setOptions(response?.data?.data);
    });
  }

  useEffect(() => {
    getGrupoSlots();
  }, []);

  if (disabled) {
    return value;
  }

  return (
    <Select input={<Input />} value={value} onChange={(event) => onValueChange(event.target.value)} style={{ width: "95%" }}>
      <MenuItem value="">Todos</MenuItem>
      {options?.map((item) => {
        return (
          <MenuItem key={item.nome} value={item.nome}>
            {item.nome ?? item.nome}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const GrupoSlotTypeProvider = (props) => <DataTypeProvider editorComponent={GrupoSlotEditor} {...props} />;

const getRowId = (row) => row.id;
const api_resource = "previsao-agendamentos/slots";

export default function SlotsTable(props) {
  const alert = useAlert();

  const grupo_slot_id = props.location?.grupo_slot_id;
  const grupo_slot = props.location?.grupo_slot;

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "id", width: 60 },
    { columnName: "hora_inicial", width: 200 },
    { columnName: "hora_final", width: 200 },
    { columnName: "data_cadastro", width: 200, editingEnabled: false },
    { columnName: "usuario_cadastro", width: 200, editingEnabled: false },
  ]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);

  function get(page, filters) {
    let queryString = QueryString(page, pageSize, filters);

    if (grupo_slot_id) {
      queryString = queryString + "&grupo_slot_id=" + grupo_slot_id;
    }

    http.get(api_resource + queryString).then(
      (response) => {
        console.log(response?.data?.data);
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    get(0, []);
  }, []);

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function handlePage(page, totalRows) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      added[0].grupo_slot_id = grupo_slot_id;
      Create(api_resource, added[0], alert).then((response) => {
        var new_row = response.data[0];
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      var item = changedRows.find((row) => changed[row.id]);
      if(item)
      {
        item.grupo_slot_id = grupo_slot_id;
      }
      Update(api_resource, item, alert);
      setRows(changedRows);
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };

  var columns = [
    { name: "id", title: "ID" },
    { name: "hora_inicial", title: "Hora inicial" },
    { name: "hora_final", title: "Hora final" },
    { name: "data_cadastro", title: "Data cadastro" },
    { name: "usuario_cadastro", title: "Usuário" },
  ];

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Slots</h2>
          </div>
          <div className="col-lg-2">
            <button className="btn btn-primary btn-sm" style={{ marginTop: "20px" }} type="button" onClick={() => ExportXlsx(api_resource, alert)}>
              Exportar
            </button>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
              <div className="ibox-content">
                  <h3>{grupo_slot}</h3>
                </div>
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} columnExtensions={tableColumnExtensions} />
                    <Table columnExtensions={tableColumnExtensions} />
                    <FilteringState onFiltersChange={handleFilters} />
                    <TableHeaderRow />
                    <DateTypeProvider for={["data_cadastro"]} />
                    <TimeTypeProvider for={["hora_inicial","hora_final"]} />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <TableEditRow />
                    <TableEditColumn showAddCommand={!addedRows.length} showEditCommand showDeleteCommand messages={editColumnMessages} />
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
