import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

const TipoFeriadoEditor = ({ value, onValueChange }) => (
  <Select input={<Input />} value={value} onChange={(event) => onValueChange(event.target.value)} style={{ width: "100%" }}>
    <MenuItem value="">Todos</MenuItem>
    <MenuItem value="Nacional">Nacional</MenuItem>
    <MenuItem value="Estadual">Estadual</MenuItem>
    <MenuItem value="Municipal">Municipal</MenuItem>
  </Select>
);

export const TipoFeriadoTypeProvider = (props) => <DataTypeProvider editorComponent={TipoFeriadoEditor} {...props} />;
