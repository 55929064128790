import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState, EditingState, CustomPaging, FilteringState, DataTypeProvider } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, ExportXlsx, TIMER_FILTER, APPNAME_GERACAOXML } from "../utils/react_grid_utils";
import Import from "../utils/import";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import { DiaSemanaTypeProvider } from "../utils/react_grid_dia_semana_column";
import { TipoFeriadoTypeProvider } from "../utils/react_grid_tipo_feriado_column";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import { FilterCell } from "../utils/react_grid_date_filter";
import authenticatedUserContext from "../security/AuthenticatedUserContext";

const getRowId = (row) => row.id;
const api_resource = "feriados";

export default function FeriadosTable() {
  const alert = useAlert();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "dia", width: 220 },
    { columnName: "semana", editingEnabled: false },
  ]);
  const [defaultHiddenColumnNames] = useState([]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [dateColumns] = useState(["dia"]);
  const [filtersApi, setFiltersApi] = useState([]);

  function get(page, filters) {
    const queryString = QueryString(page, pageSize, filters);
    http.get(`${api_resource}` + queryString).then(
      (response) => {
        console.log(response?.data?.data);
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    get(0, []);
  }, []);

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function handlePage(page, totalRows) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      Create(api_resource, added[0], alert).then((response) => {
        var new_row = response.data[0];
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      var item = changedRows.find((row) => changed[row.id]);
      Update(api_resource, item, alert).then((response) => {
        var changed_row = response.data[0];
        changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed_row } : row));
        setRows(changedRows);
      });
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };

  var columns = [
    { name: "dia", title: "Dia" },
    { name: "semana", title: "Semana" },
    { name: "nome", title: "Nome" },
    { name: "tipo", title: "Tipo" },
  ];

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Feriados</h2>
          </div>
          <div className="col-lg-2">
            <button className="btn btn-primary btn-sm" style={{ marginTop: "20px" }} type="button" onClick={() => ExportXlsx(api_resource, alert)}>
              Exportar
            </button>
            <Import api_resource={api_resource} alert={alert} appname={APPNAME_GERACAOXML} onFinish={() => get([0], [])} />
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} columnExtensions={tableColumnExtensions} />
                    <FilteringState onFiltersChange={handleFilters} />
                    <DateTypeProvider for={dateColumns} />
                    <DiaSemanaTypeProvider for={["semana"]} />
                    <TipoFeriadoTypeProvider for={["tipo"]} />
                    <Table columnExtensions={tableColumnExtensions} />
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <TableEditRow />
                    {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true ? <TableEditColumn showAddCommand={!addedRows.length} showEditCommand showDeleteCommand messages={editColumnMessages} /> : <></>}
                    <TableFilterRow cellComponent={FilterCell} />
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
