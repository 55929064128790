import Input from "@material-ui/core/Input";
import * as PropTypes from "prop-types";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  numericInput: {
    fontSize: "14px",
    paddingRight: "5px",
    textAlign: "right",
    width: "100%",
  },
});

const PortesEditorBase = ({ value, onValueChange, classes }) => {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue.trim() === "") {
      onValueChange();
      return;
    }
    onValueChange(parseInt(targetValue, 10));
  };

  // let porteField = "";

  // if (value) {
  //   porteField = value.toString();
  // }

  return (
    <Input
      type="number"
      classes={{
        input: classes.numericInput,
        root: classes.root,
      }}
      fullWidth
      value={value === undefined ? "" : value}
      inputProps={{
        min: 0,
      }}
      onChange={handleChange}
    />
  );
};

PortesEditorBase.propTypes = {
  value: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

PortesEditorBase.defaultProps = {
  value: undefined,
};

const PortesEditor = withStyles(styles)(PortesEditorBase);

export const IntegerTypeProvider = (props) => <DataTypeProvider editorComponent={PortesEditor} {...props} />;
