import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { useAlert } from "react-alert";
import { http } from "../../services/http";
import { PagingState, FilteringState, IntegratedFiltering, SelectionState, IntegratedSelection, IntegratedPaging, EditingState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableEditRow, TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { QueryString, Update, APPNAME_APADIGITAL } from "../utils/react_grid_utils";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import { BooleanCheckboxTypeProvider } from "../utils/react_grid_boolean_checkbox_column";
import { SelectTypeProvider } from "../utils/react_grid_select_column";
import authenticatedUserContext from "../security/AuthenticatedUserContext";
import { ExportXlsx } from "../utils/react_grid_utils";
import CookieService from "../../services/CookieService";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";


const api_resource = "apa-digital/gestao-contatos";
let getRowId = (row) => row.form_id;

export default function GestaoContatos() {

   const alert = useAlert();
   const [pageSize] = useState(10);
   const [rows, setRows] = useState([]);
   const [tableColumnExtensions] = useState([
      { columnName: "ID Agendamento", editingEnabled: false  ,width: 150},
      { columnName: "Risco do Paciente", editingEnabled: false ,width: 150 },
      { columnName: "Data Agendamento", editingEnabled: false  ,width: 150},
      { columnName: "Data Submissão", editingEnabled: false  ,width: 150},
      { columnName: "Suspensão Medicação?", editingEnabled: false  ,width: 180},
      { columnName: "Medicações em Uso", editingEnabled: false  ,width: 300},
      { columnName: "Paciente", editingEnabled: false ,width: 250},
      { columnName: "WhatsApp", editingEnabled: false ,width: 150},
      { columnName: "Cirurgião", editingEnabled: false ,width: 200 },
      { columnName: "Procedimento Principal", editingEnabled: false ,width: 250 },
      { columnName: "Contato", editingEnabled: true, width: 100 },
      { columnName: "Status Contato", editingEnabled: true, width: 130},
   ]);
   const [selection, setSelection] = useState([]);
   const [inputTipo, setInputTipo] = useState("ALL");

   const [addedRows, setAddedRows] = useState([]);
   const [editingRowIds, setEditingRowIds] = useState([]);
   const [rowChanges, setRowChanges] = useState({});

   //Modal
   const [selectedRow, setSelectedRow] = useState(null); 
   const [open, setOpen] = useState(false); 

   const handleClickOpen = (row) => {
      setSelectedRow(row);
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };

   //
   const filterCell = props => {
      if (props.column.name === "Contato" || props.column.name === "Status Contato")
        return <TableCell className={props.className} />;
      else return <TableFilterRow.Cell {...props} />;
    };

   const changeAddedRows = (value) => {
      const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
      setAddedRows(initialized);
   };

   const commitChanges = ({ changed }) => {
      let changedRows;
      if (changed) {
         changedRows = rows.map((row) => (changed[row.form_id] ? { ...row, ...changed[row.form_id] } : row ));
         var item = changedRows.find((row) => changed[row.form_id]);
         if(!item || item === undefined || item === null)
            return
         if (item.Contato && (item["Status Contato"] === '' || item["Status Contato"] === null || item["Status Contato"] === undefined)){
            alert.error('Selecione o status do Contato')
            return
         }
         if(!item.Contato){
            item["Status Contato"] = ''
         }
         Update(
            api_resource, 
            {
               id: item.form_id,
               contacted: item.Contato,
               contacted_status: item["Status Contato"],
               user:  CookieService.get("user"),
            }, 
            alert);
         setRows(changedRows);
      }
   }

   var dtStart = new Date();
   dtStart.setDate(dtStart.getDate() - 30); // Subtrai 30 dias

   var dtEnd = new Date();
   dtEnd.setDate(dtEnd.getDate() + 30); // Adiciona 30 dias

   const [dtStartAgendamento, setDtStartAgendamento] = useState('');
   const [dtEndAgendamento, setDtEndAgendamento] = useState('');

   const [dtStartSubmissao, setDtStartSubmissao] = useState(dtStart.toISOString().slice(0, 10));
   const [dtEndSubmissao, setDtEndSubmissao] = useState(new Date().toISOString().slice(0, 10));

   const [filtroStatus, setFiltroStatus] = useState('all');
   const [filtroContato, setFiltroContato] = useState('all');

   function get(page, filters) {
      if (inputTipo === 'VINCULADO' || inputTipo === 'NAO_VINCULADO' || inputTipo === 'ALL') {
         //alert.show(`Mostrando Triagens ${inputTipo === 'VINCULADO' ? 'Vinculadas' : 'Não Vinculadas'}`);
         let queryString = QueryString(page, pageSize, filters);
         queryString = queryString + `&contato=${filtroContato}&status=${filtroStatus}`;

         if (dtStartAgendamento && dtStartAgendamento !== '') {
            queryString = queryString + "&dataAgendamentoInicial=" + dtStartAgendamento;
         }
         if (dtEndAgendamento && dtEndAgendamento !== '') {
            queryString = queryString + "&dataAgendamentoFinal=" + dtEndAgendamento;
         }
         if (dtStartSubmissao && dtStartSubmissao !== ''){
            queryString = queryString + "&dataSubmissaoInicial=" + dtStartSubmissao;
         }
         if (dtEndSubmissao && dtEndSubmissao !== ''){
            queryString = queryString + "&dataSubmissaoFinal=" + dtEndSubmissao;
         }
         queryString = queryString + "&triagem=" + inputTipo;

         http.get(api_resource + queryString).then(
            (response) => {
               setRows(response?.data?.rows || []);
            },
            (error) => {
               setRows([]);
               console.log(error);
            }
         );
      }
      else {
         alert.error('Tipo Triagem Inválido');
      }
   }

   function changeBase(e) {
      setInputTipo(e.target.value);
   }


   useEffect(() => {
      //setSelection([]);  // Limpa a seleção ao trocar o tipo
      setRows([]); //Limpa o conteudo da tabela ao trocar o tipo
      get(0, []);
   }, []);

   function onFormSubmit(e) {
      e.preventDefault();
      get(0, []);
   }

   function handleKeyPress(e) {
      if (e.key === "Enter") {
         onFormSubmit(e);
      }
   }

   var columns = [
      { name: "Contato", title: "Contato"},
      { name: "Status Contato", title: "Status Contato"},
      { name: "Data Submissão", title: "Data Submissão" },
      { name: "ID Agendamento", title: "ID Agendamento" },
      { name: "Data Agendamento", title: "Data Agendamento" },
      { name: "Paciente", title: "Paciente" },
      { name: "WhatsApp", title: "WhatsApp" },
      { name: "Cirurgião", title: "Cirurgião" },
      { name: "Procedimento Principal", title: "Procedimento Principal" },
      { name: "Risco do Paciente", title: "Risco do Paciente" },
      { name: "Suspensão Medicação?", title: "Suspensão Medicação?" },
      { name: "Medicações em Uso", title: "Medicações em Uso" },
   ];

   const TableRowComponent = ({ row, ...restProps }) => (
      <TableRow
         {...restProps}
         style={{ cursor: "pointer" }} // Cursor to indicate clickable row
         onClick={() => handleClickOpen(row)} // Event handler to open modal
      />
   );

   return (
      <div id="wrapper">
         <Menu />
         <div id="page-wrapper" className="gray-bg">
            <div className="row wrapper border-bottom white-bg page-heading">
               <div className="col-lg-10">
                  <h2>Gestão de Contatos</h2>
               </div>
               
                  <div className="col-lg-2" style={{ paddingTop: "15px", display: 'flex', justifyContent: 'flex-end'}}>
                     <button 
                        className="btn btn-primary" 
                        onClick={() => ExportXlsx(api_resource, alert)}
                     >
                        Exportar
                     </button>
                  </div>
               
            </div>
            <div className="wrapper wrapper-content animated fadeInRight">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="ibox ">
                        <div className="ibox-content">
                           <form onSubmit={onFormSubmit} onKeyPress={handleKeyPress} style={{ marginLeft: 10 }}>
                              
                              <div className="row" >
                              <div className="col-md-3">
                                    <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                                       Data Submissão
                                    </label>
                                    <div className="d-flex" style={{ gap: "10px" }}>
                                       <input
                                          type="date"
                                          value={dtStartSubmissao}
                                          onChange={(e) => setDtStartSubmissao(e.target.value)}
                                          className="form-control col-sm-6"
                                          style={{ ma: "50%" }} // Espaço entre os inputs
                                       />
                                       <input
                                          type="date"
                                          value={dtEndSubmissao}
                                          onChange={(e) => setDtEndSubmissao(e.target.value)}
                                          className="form-control col-sm-6"
                                       />
                                    </div>
                              </div>
                              <div className="col-md-3">
                                 <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                                    Data Agendamento
                                 </label>
                                 <div className="d-flex" style={{ gap: "10px" }}>
                                    <input
                                       type="date"
                                       value={dtStartAgendamento}
                                       
                                       onChange={(e) => setDtStartAgendamento(e.target.value)}
                                       //defaultValue={inputDataInicial}
                                       className="form-control col-sm-6"
                                    />
                                    <input
                                       type="date"
                                       //defaultValue={inputDataFinal}
                                       value={dtEndAgendamento}
                                       onChange={(e) => setDtEndAgendamento(e.target.value)}
                                       className="form-control col-sm-6"
                                    />
                                 </div>
                              </div>
                              <div className="col-md-2">
                                 <label className="col-sm-12 col-form-label" >
                                    Contato?
                                 </label>
                                 <div className="row" >
                                    <div className="col-sm-12" >
                                    <select 
                                       value={filtroContato} 
                                       className="form-control col-sm-12"
                                       style={{height: 35.6}} //É o tamanho do input date, select por algum motivo não acompnha o tamanho
                                       onChange={(e) => setFiltroContato(e.target.value)}
                                    >
                                       <option value="all">Todos</option>
                                       <option value="sim">Sim</option>
                                       <option value="nao">Não</option>
                                    </select>
                                    </div>
                                 </div>                               
                              </div>
                              <div className="col-md-2">
                                 <label className="col-sm-12 col-form-label" >
                                    Status Contato
                                 </label>
                                 <div className="row" >
                                    <div className="col-sm-12" >
                                    <select 
                                       value={filtroStatus} 
                                       className="form-control col-sm-12"
                                       style={{height: 35.6}} //É o tamanho do input date, select por algum motivo não acompnha o tamanho
                                       onChange={(e) => setFiltroStatus(e.target.value)}
                                    >
                                       <option value="all">Todos</option>
                                       <option value="liberado">Liberado</option>
                                       <option value="nao_liberado">Não Liberado</option>
                                    </select>
                                    </div>
                                 </div>                               
                              </div>
                              <div className="col-md-2">
                                 <div className="col-sm-12" style={{ paddingTop: "35px" }}>
                                    <button className="btn btn-success col-sm-12" type="submit" onClick={onFormSubmit}>
                                       Buscar
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                        <div className="ibox-content">
                           <Grid rows={rows} columns={columns} getRowId={getRowId} onRowClick={(row) => handleClickOpen(row)} >
                              <PagingState defaultCurrentPage={0} pageSize={pageSize} />
                              <FilteringState />
                              <IntegratedFiltering />
                              <IntegratedPaging />
                              <Table columnExtensions={tableColumnExtensions}  rowComponent={TableRowComponent}/>
                              <BooleanCheckboxTypeProvider for={["Contato"]} />
                              <SelectTypeProvider for={["Status Contato"]} items={['Liberado','Não Liberado']}/>
                              <EditingState 
                                 editingRowIds={editingRowIds} 
                                 onEditingRowIdsChange={setEditingRowIds} 
                                 rowChanges={rowChanges} 
                                 onRowChangesChange={setRowChanges} 
                                 addedRows={addedRows} 
                                 onAddedRowsChange={changeAddedRows} 
                                 onCommitChanges={commitChanges}
                                 columnEditingEnabled={false}
                                 columnExtensions={tableColumnExtensions}
                              />
                              <SelectionState
                                 selection={ selection }
                                 onSelectionChange={setSelection }
                              />
                              <IntegratedSelection />
                              <TableHeaderRow />
                              {authenticatedUserContext.allow_edit(APPNAME_APADIGITAL) === true ? 
                                 <TableEditColumn showEditCommand messages={editColumnMessages} width={100} /> 
                                 : 
                                 <></>
                              }
                              <TableEditRow  />
                              <TableFilterRow 
                                 cellComponent={filterCell}
                                 showFilterSelector={false}/>
                              <PagingPanel />
                           </Grid>

                           <Dialog 
                              open={open} 
                              onClose={handleClose} 
                              maxWidth="md"
                              fullWidth
                              PaperProps={{
                                 style: {
                                    width: '600px', 
                                    margin: '0 auto'
                                 },
                              }}
                              >
                              
                              <DialogTitle style={{ position: 'relative', color: "#3f51b5", textAlign: 'center' }}>
                              <span style={{ display: 'inline-block', width: '100%' }}>APA Detalhes</span>
                              <IconButton
                                 edge="end"
                                 color="inherit"
                                 onClick={handleClose}
                                 aria-label="close"
                                 style={{
                                    position: 'absolute',
                                    right: 30,
                                    top: 20,
                                    padding: 0
                                 }}
                              >
                                 <CloseIcon />
                              </IconButton>
                           </DialogTitle>

                              
                              <DialogContent >
                                 <DialogContentText style={{ color: "black" }}>
                                    {selectedRow && (
                                    <> 
                                       <h2 style={{color: '#3f51b5'}}>Form</h2>
                                       <span>
                                          <b>ID:</b> {selectedRow.form_id}
                                          <br />
                                          <b>Data Submissão:</b> {selectedRow["Data Submissão"]}
                                          <br />
                                          <b>Contato?</b> {selectedRow["Contato"] ? "Sim" : "Não"}
                                          <br />
                                          <b>Status Contato: </b> {selectedRow["Status Contato"]}
                                       </span>
                                       <hr />
                                       <h2 style={{color: '#3f51b5'}}>Paciente</h2>
                                       <span>
                                          <b>Nome:</b> {selectedRow.Paciente}
                                          <br />
                                          <b>CPF:</b> {selectedRow.CPF}
                                          <br />
                                          <b>WhatsApp:</b> {selectedRow.WhatsApp}
                                       </span>
                                       <hr />
                                       <h2 style={{color: '#3f51b5'}}>Agendamento</h2>
                                       <span>
                                          <b>ID:</b> {selectedRow["ID Agendamento"]}
                                          <br />
                                          <b>Cirurgião:</b> {selectedRow["Cirurgião"]}
                                          <br />
                                          <b>Procedimento Principal:</b> {selectedRow["Procedimento Principal"]}
                                          <br />
                                          <b>Data:</b> {selectedRow["Data Agendamento"]}
                                          <br />
                                          <br />
                                          <b>Risco do Paciente:</b> {selectedRow["Risco do Paciente"]}
                                          <br />
                                          <b>Suspensão Medicação?</b> {selectedRow["Suspensão Medicação?"]}
                                          <br />
                                          <b>Medicações em Uso:</b> {selectedRow["Medicações em Uso"]}
                                       </span>
                                    </>
                                    )}
                                 </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                 <Button onClick={handleClose} color="primary">
                                    Fechar
                                 </Button>
                              </DialogActions>
                              </Dialog>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
