import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

const DiaSemanaEditor = ({ value, onValueChange, disabled }) => {
  if (disabled) {
    return value ?? "";
  }

  return (
    <Select input={<Input />} value={value} onChange={(event) => onValueChange(event.target.value)} style={{ width: "100%" }}>
      <MenuItem value="">todos</MenuItem>
      <MenuItem value="segunda-feira">segunda-feira</MenuItem>
      <MenuItem value="terça-feira">terça-feira</MenuItem>
      <MenuItem value="quarta-feira">quarta-feira</MenuItem>
      <MenuItem value="quinta-feira">quinta-feira</MenuItem>
      <MenuItem value="sexta-feira">sexta-feira</MenuItem>
      <MenuItem value="sábado">sábado</MenuItem>
      <MenuItem value="domingo">domingo</MenuItem>
    </Select>
  );
};
export const DiaSemanaTypeProvider = (props) => <DataTypeProvider editorComponent={DiaSemanaEditor} {...props} />;
