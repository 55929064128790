import IfEmpty from './operator/IfEmpty';


export default function AlertMessage(props)  {

    return (

        <>

            {
                props.messages?.map(({message}) => (
                    <div className="alert alert-danger alert-dismissable">
                        {/* <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button> */}
                        {message}.
                        {/* <a className="alert-link" href="#">Alert Link</a>. */}
                    </div>
                ))

            }

        {/* <IfEmpty test={props.messages}>
            <div className="alert alert-success alert-dismissable">
                <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                A wonderful serenity has taken possession. <a className="alert-link" href="#">Alert Link</a>.
            </div>
            <div className="alert alert-info alert-dismissable">
                <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                A wonderful serenity has taken possession. <a className="alert-link" href="#">Alert Link</a>.
            </div>
            <div className="alert alert-warning alert-dismissable">
                <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                A wonderful serenity has taken possession. <a className="alert-link" href="#">Alert Link</a>.
            </div>
            <div className="alert alert-danger alert-dismissable">
                <button aria-hidden="true" data-dismiss="alert" className="close" type="button">×</button>
                A wonderful serenity has taken possession. <a className="alert-link" href="#">Alert Link</a>.
            </div>

            </IfEmpty> */}
        </>
    );
}