import React, { useState } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import { PagingState, EditingState, CustomPaging, FilteringState, IntegratedFiltering, RowDetailState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableColumnVisibility, TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import { TIMER_FILTER } from "../utils/react_grid_utils";
import { ParticAnestesistaTypeProvider } from "../utils/react_grid_partic_anestesista_column";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import { NumberTypeProvider } from "../utils/react_grid_number_column";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import { APPNAME_GERACAOXML } from "../utils/react_grid_utils";
import authenticatedUserContext from "../security/AuthenticatedUserContext";

const getRowId = (row) => row.id;
const api_resource = "notafiscal/convenios";

const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    fontWeight: "bold",
    fontSize: "16px",
    maxWidth: "600px",
    transform: "translate(-50%, -50%)",
  },
};

export default function ConvenioLoteTable() {
  const alert = useAlert();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "id", width: 20 },
    { columnName: "lote", width: 60 },
    { columnName: "xml_date", width: 100 },
    { columnName: "convenio", width: 160 },
    { columnName: "created", width: 100 },
    { columnName: "data_vencimento", width: 140 },
    { columnName: "status_bot", width: 160 },
    { columnName: "status_description", width: 300, wordWrapEnabled: true },
    { columnName: "valor", width: 100 },
    { columnName: "sap_invoice_docnum", width: 100 },
    { columnName: "observacao", width: 100 },
  ]);

  const [defaultHiddenColumnNames] = useState(["id"]);
  const [filtersApi, setFiltersApi] = useState([]);
  const [concluirIsOpen, setConcluirIsOpen] = useState(false);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [observacao, setObservacao] = useState("");
  let inputLote, inputConvenio, inputDataInicial, inputDataFinal, inputDataVencimento;

  function get(page) {
    let queryString = `?page=${page + 1}`;

    if (inputLote?.value) {
      queryString = queryString + "&lote=" + inputLote.value;
    }
    if (inputConvenio?.value) {
      queryString = queryString + "&convenio=" + inputConvenio.value;
    }
    if (inputDataInicial?.value) {
      queryString = queryString + "&dataInicial=" + inputDataInicial.value;
    }
    if (inputDataFinal?.value) {
      queryString = queryString + "&dataFinal=" + inputDataFinal.value;
    }    

    http.get(api_resource + queryString).then(
      (response) => {
        console.log(response?.data?.data);
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function handlePage(page) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  var columns = [
    { name: "id", title: "ID" },
    { name: "lote", title: "Lote" },
    { name: "xml_date", title: "Data Lote" },
    { name: "convenio", title: "Convênio" },
    { name: "created", title: "Data Criação" },
    { name: "data_vencimento", title: "Data Vencimento" },
    { name: "status_bot", title: "Status" },
    { name: "status_description", title: "Mensagem" },
    { name: "valor", title: "Valor" },
    { name: "sap_invoice_docnum", title: "NF" },
  ];

  function onFormSubmit(e) {
    e.preventDefault();
    get(0, []);
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      onFormSubmit(e);
    }
  }

  function closeModal() {
    setConcluirIsOpen(false);
  }

  function handleExpandedRow(ids) {
    setObservacao("");

    console.log(ids);
    const newId = ids.slice(-1);
    if(newId.length > 0)
    {
      const currentRow = rows.find(i => i.id == newId[0])
      const obs = getObservacao(currentRow);
      setObservacao(obs);
    }

    setExpandedRowIds(newId);
  }

  function getDataVencimento(row) {

    if(row.data_vencimento == null)
    {
      let data = new Date();
      data.setMonth(data.getMonth() + 2);
      row.data_vencimento = data.toISOString().substring(0, 10);
    }
    
    return row.data_vencimento;
  }

  function getObservacao(row) {

      var obs = "";
      const dataVencimento = getDataVencimento(row);
      // PJ
      let vlr_bruto = (row.valor * 1).toFixed(2);
      let impostos = (vlr_bruto * 0.0615).toFixed(2);
      if(impostos < 10) {
        impostos = 0
      }
      let vlr_liquido = (vlr_bruto - impostos).toFixed(2);
      obs =   "" +
              `Serviços de Anestesia Prestados\n` +
              `\n` +
              `${dataVencimento ? 'Data Vencimento – ' + dataVencimento.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1") : ''}\n` +
              `Valor Bruto – R$ ${Intl.NumberFormat("pt-BR",{stype: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(vlr_bruto)}\n` +
              `Valor Líquido – R$ ${Intl.NumberFormat("pt-BR",{stype: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(vlr_liquido)}\n` +
              `\n` +
              `[1] Valor aproximando dos tributos 16,70%\n` +
              `Conforme Lei nº:12741 de 08.12.2012`;
      if (row.tipo_recebimento === "ASSOCIAÇÃO HIAE") {
        obs += "\n\n" +
              "Dr.Flavio Takaoka\n" +
              "CRM: 31.745";
      }

    return obs;
  } 
  
  function handleObservacaoChange(e)
  {
    setObservacao(e.target.value)
  }

  function concluir(row) {

    setConcluirIsOpen(false);

    row.observacao = observacao;

    if(row.data_vencimento == null || row.data_vencimento == undefined || row.data_vencimento == "")
    {
      window.alert("O campo data de vencimento é obrigatório!");
    }
    else
    {
      http.post(`${api_resource}`, row).then(
        (response) => {
          closeModal();
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Sucesso!");
          }          
          get(0, []);
        },
        (error) => {
          if (error?.messages) {
            error.messages.map((erro_msg) => {
              if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                alert.info("Nenhuma alteração enviada.");
              } else {
                alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
              }
            });
          } else {
            alert.error("Não foi possível excluir o registro!");
          }
        }
      );
    }
  }

  const RowDetail = ({ row }) => (
      row.notafiscal_id == null ? 

        <div className="ibox">
          <div className="row wrapper"> 
            <div className="col-lg-2">
            <TextField
                input={<Input />}
                required
                id="dateEnd"
                label="Data vencimento"
                type="date"
                defaultValue={getDataVencimento(row)}
                onChange={(e) => {
                    row.data_vencimento = e.target.value;
                    const obs = getObservacao(row);
                    setObservacao(obs);
                  }
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
          </div>
          <div className="col-lg-3">
            <TextField 
                style ={{width: "400px"}}
                input={<Input />}
                id="observacao"
                label="Observação de abertura e encerramento"
                type="text"
                multiline="true"
                variant="outlined"
                defaultValue={observacao}
                onBlur={(e) => handleObservacaoChange(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
          </div>
          <div className="col-lg-6">
                <button className="btn btn-sm btn-success" type="button" style={{ marginTop: "20px", marginLeft: "90px" }} onClick={() => setConcluirIsOpen(true)}>
                  Criar NF
                </button>
            </div>
          </div>
          <Modal isOpen={concluirIsOpen} style={customStyles}>
            <form>
              <div className="form-group  row">
                <div className="col-sm-12">
                Confirma a criação da nota fiscal?
              </div>
              </div>
              <div className="hr-line-dashed"></div>
              <div className="form-group row" style={{ textAlign: "right" }}>
                <div className="col-sm-12 col-sm-offset-2">
                  <button className="btn btn-primary btn-sm" type="button" onClick={closeModal} style={{ width: "70px" }}>
                    Voltar
                  </button>
                  <button className="btn btn-sm btn-success" style={{ marginLeft: "10px", width: "100px" }} type="button" onClick={() => concluir(row)}>
                    Confirmar
                  </button>
                </div>
              </div>
            </form>
          </Modal> 
        </div>

    : <></>
  );

  function reprocessar() {
    var confirmado = window.confirm("Confirma o reprocessamento?");
    if (confirmado === true) {
      http.put(api_resource + "/reprocessar").then(
        (response) => {
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Sucesso!");
          }
        },
        (error) => {
          alert.error("Erro ao solicitar o Reprocessamento NF");
        }
      );
    }
  }

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Notas Fiscais Convênios - Lotes</h2>
          </div>
          <div className="col-lg-2">
            <div className="row" style={{ paddingTop: "20px" }}>
              {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true ? (
                <button className="btn btn-sm btn-success" type="button" onClick={reprocessar}>
                  Reprocessar NF
                </button>
              ) : (
                <></>
              )}{" "}
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-content">
                    <form onSubmit={onFormSubmit} onKeyPress={handleKeyPress}>
                      <div className="row">
                        <div className="col-md-1">
                          <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                            Lote
                          </label>
                          <input
                            ref={(node) => {
                              inputLote = node;
                            }}
                            type="text"
                            className="col-sm-12"
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-3">
                          <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                            Convênio
                          </label>
                          <input
                            ref={(node) => {
                              inputConvenio = node;
                            }}
                            type="text"
                            className="form-control col-sm-12"
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                            Período
                          </label>
                          <div className="row">
                            <input
                              ref={(node) => {
                                inputDataInicial = node;
                              }}
                              type="date"
                              className="form-control col-sm-6"
                            />
                            <input
                              ref={(node) => {
                                inputDataFinal = node;
                              }}
                              type="date"
                              className="form-control col-sm-6"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-sm-3" style={{paddingTop:"35px"}}>
                              <button className="btn btn-primary" type="submit" type="button" onClick={onFormSubmit}>
                                Buscar
                              </button>
                          </div>
                        </div>
                      </div>
                    </form>
                </div>
                <div className="ibox-content">
                    <Grid rows={rows} columns={columns} getRowId={getRowId}>
                      <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                      <EditingState columnExtensions={tableColumnExtensions} />
                      <Table columnExtensions={tableColumnExtensions} />
                      <RowDetailState 
                        expandedRowIds={expandedRowIds}
                        onExpandedRowIdsChange={handleExpandedRow}                    
                      />
                      <FilteringState onFiltersChange={handleFilters} />
                      <IntegratedFiltering />
                      <DateTypeProvider for={["xml_date", "created", "data_vencimento"]} />
                      <NumberTypeProvider for={["valor"]} />
                      <ParticAnestesistaTypeProvider for={["partic_anestesista"]} />
                      <TableHeaderRow />
                      <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                      <CustomPaging totalCount={totalItens} />
                      <PagingPanel />
                      <TableRowDetail contentComponent={RowDetail} />
                    </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
