import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Main from "./Main";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const config = {
    auth: {
      clientId: process.env.REACT_APP_AUTH_CLIENTID,
      authority: process.env.REACT_APP_AUTH_AUTHORITY,
      clientSecret: process.env.REACT_APP_AUTH_CLIENTSECRET,
      grantType: process.env.REACT_APP_AUTH_GRANTTYPE
      // scope: process.env.REACT_APP_AUTH_SCOPE
    }
  };

const publicClientApplication = new PublicClientApplication(config);

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={publicClientApplication}>
            <Main />
        </MsalProvider>
    </React.StrictMode>, 
    document.getElementById("root"));

reportWebVitals();
