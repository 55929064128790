import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState, EditingState, CustomPaging, FilteringState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility, TableFixedColumns, TableSelection } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, ExportXlsx, TIMER_FILTER, APPNAME_TAKAOKA } from "../utils/react_grid_utils";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import { BooleanTypeProvider } from "../utils/react_grid_boolean_column";
import { TelefoneTypeProvider } from "../utils/react_grid_telefone_column";
import { CpfTypeProvider } from "../utils/react_grid_cpf_column";
import Import from "../utils/import";
import authenticatedUserContext from "../security/AuthenticatedUserContext";

const getRowId = (row) => row.id;
const api_resource = "medicos";

export default function MedicoTable() {
  const alert = useAlert();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [filteringStateColumnExtensions] = useState([
    { columnName: "h9j", filteringEnabled: false },
    { columnName: "hiae", filteringEnabled: false },
    { columnName: "hmvsc", filteringEnabled: false },
    { columnName: "cdb", filteringEnabled: false },
    { columnName: "hsv", filteringEnabled: false },
    { columnName: "dor", filteringEnabled: false },
    { columnName: "personalisado", filteringEnabled: false },
    { columnName: "atividades_nao_assistenciais", filteringEnabled: false },
    { columnName: "educacao_corporativa", filteringEnabled: false },
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: "id", width: 60 },
    { columnName: "nome_completo", width: 350 },
    { columnName: "email", width: 250 },
    { columnName: "cpf", width: 150 },
    { columnName: "telefone", width: 150 },
    { columnName: "data_de_nascimento", width: 220 },
    { columnName: "data_de_expedicao", width: 220 },
    { columnName: "data_de_ingresso", width: 220 },
    { columnName: "endereco", width: 450 },
    // { columnName: "hospital", editingEnabled: false },
  ]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);

  function get(page, filters) {
    const queryString = QueryString(page, pageSize, filters);
    http.get(api_resource + queryString).then(
      (response) => {
        console.log(response?.data?.data);
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    get(0, []);
  }, []);

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function handlePage(page) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      Create(api_resource, added[0], alert).then((response) => {
        var new_row = response.data[0];
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      var item = changedRows.find((row) => changed[row.id]);
      Update(api_resource, item, alert);
      setRows(changedRows);
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };

  var columns = [
    { name: "id", title: "ID" },
    { name: "nome_completo", title: "Nome completo" },
    { name: "crm", title: "CRM" },
    { name: "cpf", title: "CPF" },
    { name: "cns", title: "CNS" },
    { name: "email", title: "E-mail" },
    { name: "telefone", title: "Telefone" },
    { name: "data_de_nascimento", title: "Data Nasc." },
    { name: "rg", title: "RG" },
    { name: "status", title: "Status" },
    { name: "orgao_emissor", title: "Org. emissor" },
    { name: "data_de_expedicao", title: "Data exp." },
    { name: "endereco", title: "Endereço" },
    { name: "senioridade", title: "Senioridade" },
    { name: "especialidades", title: "Especialidades" },
    { name: "codigo_interno", title: "Cód. interno" },
    { name: "data_de_ingresso", title: "Data ingresso" },
    { name: "h9j", title: "h9j" },
    { name: "hiae", title: "hiae" },
    { name: "hmvsc", title: "hmvsc" },
    { name: "cdb", title: "cdb" },
    { name: "hsv", title: "hsv" },
    { name: "personalisado", title: "Personalisado" },
    { name: "dor", title: "Dor" },
    { name: "atividades_nao_assistenciais", title: "Ativ. não assist." },
    { name: "educacao_corporativa", title: "Educação corp." },
  ];

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Médicos</h2>
          </div>
          <div className="col-lg-2">
            <button className="btn btn-primary btn-sm" style={{ marginTop: "20px" }} type="button" onClick={() => ExportXlsx(api_resource, alert)}>
              Exportar
            </button>
            <Import api_resource={api_resource} alert={alert} appname={APPNAME_TAKAOKA} onFinish={() => get([0], [])} />
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} columnExtensions={tableColumnExtensions} />
                    <FilteringState onFiltersChange={handleFilters} columnExtensions={filteringStateColumnExtensions} />
                    <Table columnExtensions={tableColumnExtensions} />
                    <DateTypeProvider for={["data_de_nascimento", "data_de_expedicao"]} />
                    <BooleanTypeProvider for={["h9j", "hiae", "hmvsc", "cdb", "hsv", "personalisado", "dor", "atividades_nao_assistenciais", "educacao_corporativa"]} />
                    <CpfTypeProvider for={["cpf"]} />
                    <TelefoneTypeProvider for={["telefone"]} />
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <TableEditRow />
                    {authenticatedUserContext.allow_edit(APPNAME_TAKAOKA) === true ? <TableEditColumn showAddCommand={!addedRows.length} showEditCommand showDeleteCommand messages={editColumnMessages} /> : <></>}
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableFilterRow />
                    <TableFixedColumns leftColumns={[TableEditColumn.COLUMN_TYPE]} />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
