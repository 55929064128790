import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import Menu from "../Menu";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState,EditingState, CustomPaging, RowDetailState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableRowDetail, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility } from "@devexpress/dx-react-grid-material-ui";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import { ViaTypeProvider } from "../utils/react_grid_via_column";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import {QueryString, Create, Update, Remove, ExportXlsx, TIMER_FILTER, APPNAME_GERACAOXML } from "../utils/react_grid_utils";
import authenticatedUserContext from "../security/AuthenticatedUserContext";
import { MoneyTypeProvider } from "../utils/react_grid_money_column";
const getRowId = (row) => row.doc_num;
const getRowIdProc = (row) => row.id;
const api_resource = "processamento-manual-operadoras";
const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    fontWeight: "bold",
    fontSize: "16px",
    maxWidth: "600px",
    transform: "translate(-50%, -50%)",
  },
};

export default function CadastroManualTable() {
  console.log("teste5")
  const alert = useAlert();

  const [importIsOpen, setImportIsOpen] = useState(false);
  const [motivoInclusaoManual, setMotivoInclusaoManual] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [queryApi, setQueryApi] = useState("");
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "id", width: 60 },
    { columnName: "data", width: 120, editingEnabled: false },
    { columnName: "doc_num", width: 90, editingEnabled: false },
    { columnName: "via", width: 110 },
    { columnName: "paciente", width: 350, editingEnabled: false },
    { columnName: "hospital", width: 250, editingEnabled: false },
    { columnName: "convenio", width: 200, editingEnabled: false },
    { columnName: "anestesista", width: 350, editingEnabled: false },
    { columnName: "tuss", width: 100, editingEnabled: false },
    { columnName: "desc_proc", width: 600, editingEnabled: false },
    { columnName: "lote", width: 90, editingEnabled: false },

    { columnName: "vlr_base", width: 110},
    { columnName: "vlr_vias", width: 110},
    { columnName: "vlr_acresc30", width: 110 },
    { columnName: "vlr_2anest", width: 110},
    { columnName: "vlr_linha", width: 110},
    { columnName: "vlr_cumulativo", width: 110},
    { columnName: "vlr_total", width: 110},
    { columnName: "via", width: 110},

  ]);
  const [convenios, setConvenios] = useState([]);
  const motivoConclusaoRef = useRef('')
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);
  const [editingDetailRowIds, setEditingDetailRowIds] = useState([]);
  let inputPaciente, inputAnestesista, inputConvenio, inputDataInicial, inputDataFinal, inputPlano, inputHospital, inputProcedimento, inputDocNum;

  function get(page, filters) {
    console.log("teste5")
    let queryString = `?page=${page + 1}`;

    if (inputPaciente?.value) {
      queryString = queryString + "&paciente=" + inputPaciente.value;
    }
    if (inputAnestesista?.value) {
      queryString = queryString + "&anestesista=" + inputAnestesista.value;
    }
    if (inputConvenio?.value) {
      queryString = queryString + "&convenio=" + inputConvenio.value;
    }
    if (inputDataInicial?.value) {
      queryString = queryString + "&dataInicial=" + inputDataInicial.value;
    }
    if (inputDataFinal?.value) {
      queryString = queryString + "&dataFinal=" + inputDataFinal.value;
    }
    if (inputPlano?.value) {
      queryString = queryString + "&plano=" + inputPlano.value;
    }
    if (inputHospital?.value) {
      queryString = queryString + "&hospital=" + inputHospital.value;
    }
    if (inputProcedimento?.value) {
      queryString = queryString + "&procedimento=" + inputProcedimento.value;
    }
    if (inputDocNum?.value) {
      queryString = queryString + "&doc_num=" + inputDocNum.value;
    }

    setQueryApi(queryString);

    getApi(queryString);
  }

  function getApi(queryString) {
    console.log("teste5")
    http.get(api_resource + queryString).then(
      (response) => {
        console.log(response?.data?.data)
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function handleEditDetailClick(row) {
    console.log("teste")
    //row.procedimentos.map(proc => console.log(proc.id))
    setEditingDetailRowIds(row.procedimentos.map(proc => proc.id));
  }
  function AlterarStatus(row) {
    var confirmado = window.confirm("Confirma todos os valores preenchidos?");
    if (confirmado){      
      Update(api_resource+"/update_status", row, alert);
      const updatedRows = rows.filter((r) => r.doc_num !== row.doc_num);
      setRows(updatedRows);
    }


  
  }

  function getConvenios() {
    console.log("teste5")
    http.get("operadoras-all").then((response) => {
      setConvenios(response?.data);
    });
  }

  useEffect(() => {
    getConvenios();
  }, []);

  var columns = [
    { name: "doc_num", title: "DocNum" },
    { name: "data", title: "Data" },
    { name: "paciente", title: "Paciente" },
    { name: "anestesista", title: "Anestesista" },
    { name: "hospital", title: "Hospital" },
    { name: "convenio", title: "Convênio" },
  ];

  var detail_columns = [
    { name: "id", title: "id" },
    { name: "tuss", title: "TUSS" },
    
    { name: "vlr_base", title: "Valor Base"},
    { name: "vlr_vias", title: "Valor Vias"},
    { name: "vlr_acresc30", title: "Valor Acrescimo 30"},
    { name: "vlr_2anest", title: "Valor 2º Anestesista" },

    { name: "vlr_linha", title: "Valor Linha" },
    { name: "vlr_cumulativo", title: "Valor Cumulativo" },
    { name: "vlr_total", title: "Valor Total" },
    { name: "via", title: "Via" },
    { name: "desc_proc", title: "Procedimento" },
  ];

  function handlePage(page, totalRows) {
    console.log("teste5")
    setPageIndex(page);
    get(page, []);
  }


  const commitChanges = ({ added, changed, deleted }) => {
    console.log("teste2")
    let changedRows;
    if (added) {
      Create(api_resource, added[0], alert).then((response) => {
        var new_row = response.data[0];
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }

    
    if (changed) {
      console.log(`changed`);
      console.log(changed);
      const modifiedRows = [];
      changedRows = rows.map((row) => {
        
        const updatedRow = { ...row };
        const updatedProcedimentos = updatedRow.procedimentos.map((proc) => {
          if (proc.id in changed) {
            console.log(`achou`);
            console.log(proc)
            
            modifiedRows.push({ ...proc, ...changed[proc.id] }); // Adicione a linha modificada ao array modifiedRows
            //return { ...proc, ...changed[proc.id] };
            const updatedProc = { ...proc, ...changed[proc.id] };
            updatedProc.vlr_base = updatedProc.vlr_base !== null ? updatedProc.vlr_base : 0;
            updatedProc.vlr_vias = updatedProc.vlr_vias !== null ? updatedProc.vlr_vias : 0;
            updatedProc.vlr_acresc30 = updatedProc.vlr_acresc30 !== null ? updatedProc.vlr_acresc30 : 0;
            updatedProc.vlr_2anest = updatedProc.vlr_2anest !== null ? updatedProc.vlr_2anest : 0;
            updatedProc.vlr_linha = updatedProc.vlr_linha !== null ? updatedProc.vlr_linha : 0;
            updatedProc.vlr_cumulativo = updatedProc.vlr_cumulativo !== null ? updatedProc.vlr_cumulativo : 0;
            updatedProc.vlr_total = updatedProc.vlr_total !== null ? updatedProc.vlr_total : 0;
            updatedProc.via = updatedProc.via !== null ? updatedProc.via : 1;
            return updatedProc;
          }
          return proc;
        });
        updatedRow.procedimentos = updatedProcedimentos;
        
        return updatedRow;
      });
    
      console.log(`changedrows`);
      console.log(changedRows);
      
      // Assuming you are sending the entire changedRows array to the API
      Update(api_resource, modifiedRows, alert);
    
      setRows(changedRows);
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };



  
  function onFormSubmit(e) {
    e.preventDefault();
    get(0, []);
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      onFormSubmit(e);
    }
  }

  function closeModal() {
    setImportIsOpen(false);
  }


  const RowDetail = ({ row }) => (
    <div className="ibox ">
    <div className="ibox-content">
    <div className="row wrapper border-bottom white-bg page-heading">
      <div className="col-lg-6">
        {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true && row?.procedimentos?.some((proc) => proc.status_bot != "CONCLUIDO") ? (
          <>
          <button className="btn btn-sm btn-success" type="button" onClick={() => handleEditDetailClick(row)}>
            Editar Tudo
          </button>
          <t> </t>
          <button className="btn btn-sm btn-primary" type="button" onClick={() => AlterarStatus(row)}>
            Concluir edição
          </button>
        </>
          
        ) : (
          <></>
        )}
      </div>
    </div>
    
    <Grid rows={row.procedimentos} columns={detail_columns} getRowId={getRowIdProc}>
      <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
      <EditingState
        editingRowIds={editingDetailRowIds}
        onEditingRowIdsChange={setEditingDetailRowIds}
        onCommitChanges={commitChanges}
        columnExtensions={tableColumnExtensions}
      />
      <Table columnExtensions={tableColumnExtensions} />
      <TableHeaderRow />
      <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
      

      <TableEditRow />
      <TableEditColumn showEditCommand messages={editColumnMessages} />
    </Grid>
  </div>
  </div>
  );

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-6">
            <h2>Calculo Manual de Agendamento</h2>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <form onSubmit={onFormSubmit} onKeyPress={handleKeyPress}>
                    <div className="row">
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Paciente
                        </label>
                        <input
                          ref={(node) => {
                            inputPaciente = node;
                          }}
                          type="text"
                          className="col-sm-12"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Anestesista
                        </label>
                        <input
                          ref={(node) => {
                            inputAnestesista = node;
                          }}
                          type="text"
                          className="form-control col-sm-12"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Convênio
                        </label>
                        <input
                          ref={(node) => {
                            inputConvenio = node;
                          }}
                          type="text"
                          className="form-control col-sm-12"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Período
                        </label>
                        <div className="row">
                          <input
                            ref={(node) => {
                              inputDataInicial = node;
                            }}
                            type="date"
                            className="form-control col-sm-6"
                          />
                          <input
                            ref={(node) => {
                              inputDataFinal = node;
                            }}
                            type="date"
                            className="form-control col-sm-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Plano
                        </label>
                        <input
                          ref={(node) => {
                            inputPlano = node;
                          }}
                          type="text"
                          className="col-sm-12"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Hospital
                        </label>
                        <input
                          ref={(node) => {
                            inputHospital = node;
                          }}
                          type="text"
                          className="col-sm-12"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Procedimento
                        </label>
                        <input
                          ref={(node) => {
                            inputProcedimento = node;
                          }}
                          type="text"
                          className="col-sm-12"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-6 col-form-label" style={{ paddingLeft: "1px" }}>
                          Doc. Num.
                        </label>
                        <div className="row">
                          <input
                            ref={(node) => {
                              inputDocNum = node;
                            }}
                            type="text"
                            className="form-control col-sm-6"
                          />
                          <div className="col-sm-3">
                            <button className="btn btn-primary" type="submit" type="button" onClick={onFormSubmit}>
                              Buscar
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-3" style={{ textAlign: "right", verticalAlign: "bottom" }}></div> */}
                    </div>
                  </form>
                </div>
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <RowDetailState />
                    <Table columnExtensions={tableColumnExtensions} />
                    <DateTypeProvider for={["data"]} />
                    <TableHeaderRow />
                    <TableRowDetail contentComponent={RowDetail} />
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}