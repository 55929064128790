import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import { PagingState, EditingState, CustomPaging, FilteringState, RowDetailState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility, TableFixedColumns, TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, ExportXlsx, TIMER_FILTER, APPNAME_DEMONSTRATIVOPAGAMENTO } from "../utils/react_grid_utils";
import { DateTimeTypeProvider } from "../utils/react_grid_datetime_column";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import authenticatedUserContext from "../security/AuthenticatedUserContext";
import Import from "../utils/import";
import { downloadXlsx } from "../operator/Utils";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import Files from "react-files";
import { Send } from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";

let inputDataInicial, inputDataFinal,inputDataPag, inputTipo;
let inputDataContato =''

const getRowId = (row) => row.id;
const api_resource = "importacao-pgto-medico";

const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;


const ButtonBase = ({ row }) => {

  function disparar(row) {
    var confirmado = window.confirm("Confirma o reprocessamento?");
    row.status = "ENVIAR";
    
    var dt_inicio = new Date (row.data_inicio)
    var dt_fim = new Date (row.data_fim)
    var dt_pag = new Date (row.data_pag)
    var dt_contato

    if(row.data_contato === '' || row.data_contato === null)
    {
      dt_contato = ''  
      row.data_contato = ''}
    else
    {
      dt_contato = new Date (row.data_contato)
      row.data_contato = dt_contato.toLocaleDateString('pt-BR', {timeZone: 'UTC'})
    }
    row.data_inicio = dt_inicio.toLocaleDateString('pt-BR', {timeZone: 'UTC'})
    row.data_fim = dt_fim.toLocaleDateString('pt-BR', {timeZone: 'UTC'})
    row.data_pag = dt_pag.toLocaleDateString('pt-BR', {timeZone: 'UTC'})
    


    if (confirmado === true) {
      http.put(api_resource + "/processar",row).then(
        (response) => {
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Solicitação de processamento enviada!");
          }
        },
        (error) => {
          if (error?.messages) {
            error.messages.map((erro_msg) => {
              if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                alert.info("Nenhuma requisição enviada.");
              } else {
                alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
              }
            });
          } else {
            alert.error("Não foi possível enviar o registro!");
          }
        }
      );
    }
  }
    

  return (
    row?.id > 0 & row?.status == 'PENDENTE ENVIO'?
      
      <button className="btn btn-sm btn-success" type="button" onClick={() =>  disparar(row)}>
        Disparar
      </button>    
    :
      <></>
  );
};

const ButtonBase2 = ({ row }) => {

  function cancelar(row) {
    var confirmado = window.confirm("Confirma o cancelamento?");
    
  
    if (confirmado === true) {
      http.put(api_resource + "/cancelar",row).then(
        (response) => {
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Cancelado com Sucesso!");
          }
        },
        (error) => {
          if (error?.messages) {
            error.messages.map((erro_msg) => {
              if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                alert.info("Nenhuma requisição enviada.");
              } else {
                alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
              }
            });
          } else {
            alert.error("Não foi possível cancelar o registro!");
          }
        }
      );
    }
  }
    

  return (
    row?.id > 0 & row?.status == 'PENDENTE ENVIO' & row?.status != 'CANCELADO'?
      
      <button className="btn btn-sm btn-danger" type="button" onClick={() =>  cancelar(row)}>
      Cancelar
      </button>    
    :
      <></>
  );
};

const ButtonBase3 = ({ row }) => {

  return (
    row?.id > 0 & row?.status != 'CONCLUIDO' & row?.status != 'CANCELADO' & row?.status != 'FALHA'?
      
      <LinearProgress style={{ marginVertical: 30 }} value={row.progresso} variant="determinate"/>   
    :
      <></>
  );
};

export const ButtonTypeProvider = (props) => <DataTypeProvider formatterComponent={ButtonBase} editorComponent={ButtonBase} {...props} />;
export const ButtonTypeProvider2 = (props) => <DataTypeProvider formatterComponent={ButtonBase2} editorComponent={ButtonBase2} {...props} />;
export const ButtonTypeProvider3 = (props) => <DataTypeProvider formatterComponent={ButtonBase3} editorComponent={ButtonBase3} {...props} />;

export default function ImportacaoLoteAgendamentosD2ITable() {
  const alert = useAlert();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "cancelar", width: 110 },
    { columnName: "enviar_emails", width: 140 },
    { columnName: "id", width: 60 },
    { columnName: "data_importacao", width: 150 },
    { columnName: "file_path_name", width: 230 },
    { columnName: "type", width: 150 },
    { columnName: "total_importado", width: 150 },
    { columnName: "status", width: 150 },
    { columnName: "data_inicio", width: 150 },
    { columnName: "data_fim", width: 150 },
    { columnName: "data_pag", width: 150 },
    { columnName: "data_contato", width: 150 },
    { columnName: "description", width: 400 },
    { columnName: "nao_enviados", width: 150 },
    { columnName: "progresso", width: 150 },
    { columnName: "requester", width: 250 }
  ]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);
  
  function get(page, filters) {
    const queryString = QueryString(page, pageSize, filters);
    http.get(api_resource + queryString).then(
      (response) => {
        console.log(response?.data?.data);
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    get(0, []);
  }, []);

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function handlePage(page) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      Create(api_resource, added[0], alert).then((response) => {
        var new_row = response.data[0];
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      var item = changedRows.find((row) => changed[row.id]);
      Update(api_resource, item, alert);
      setRows(changedRows);
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };

  var columns = [
    { name: "cancelar", title: "Cancelar" },
    { name: "enviar_emails", title: "Disparar E-Mails" },
    { name: "id", title: "ID" },
    { name: "data_importacao", title: "Data importação" },
    { name: "file_path_name", title: "Arquivo" },
    { name: "type", title: "Tipo" },
    { name: "total_importado", title: "Qtd Registros" },
    { name: "progresso", title: "Progresso" },
    { name: "status", title: "Status" },
    { name: "data_inicio", title: "Data Inicio" },
    { name: "data_fim", title: "Data Fim" },
    { name: "data_pag", title: "Previsão Pgto" },
    { name: "data_contato", title: "Data Contato" },
    { name: "nao_enviados", title: "Qtd Ñ Enviada" },
    { name: "description", title: "Descrição" },
    { name: "requester", title: "Solicitante" },
    
  ];

  function onFormSubmit(e) {
    e.preventDefault();
    Enviar()
    get(0, []);
  }

  function Enviar()
  {
    var data = {
      data_inicio : inputDataInicial.value,
      data_fim: inputDataFinal.value,
      data_pag: inputDataPag.value,
      data_contato: inputDataContato.value,
      status: 'VALIDAR',
      type: inputTipo.value
    }
    if(inputDataInicial.value =='' || inputDataFinal.value =='' || inputDataPag.value =='' || inputTipo.value =='')
    {
      alert.error("Todos os campos são obrigatórios.");
    }
    else
    {

      var confirmado = window.confirm('Confirma o envio para validação?');
      if (confirmado === true) {
        http.put(api_resource + "/processar",data).then(
          (response) => {
            if (response?.data) {
              alert.info(response?.data, { timeout: INFO_TIMEOUT });
            } else {
              alert.success("Solicitação Enviada! Aguarde a inserção na tabela abaixo.");
            }
          },
          (error) => {
            if (error?.messages) {
              error.messages.map((erro_msg) => {
                if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                  alert.info("Nenhuma registro enviado.");
                } else {
                  alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
                }
              });
            } else {
              alert.error("Não foi possível enviar o registro!");
            }
          }
        );
      }
    }

  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      onFormSubmit(e);
    }
  }

  window.setInterval(get([0], []), 10000);

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-6">
            <h2>Demonstrativo Pagamento Médico</h2>
          </div>
          <div className="col-lg-6">
            <div className="row" style={{ paddingTop: "20px" }}>
            </div>  
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                <form onSubmit={onFormSubmit} onKeyPress={handleKeyPress} style={{ marginBottom: "50px" }}>
                    <div className="row" >
                                           
                      <div className="col-md-4" style={{ paddingLeft: "30px", marginBottom:"15px" }}>
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Data Inicial
                        </label>
                        <div className="row">
                          <input
                            ref={(node) => {
                              inputDataInicial = node;
                            }}
                            type="date"
                            className="form-control col-sm-6"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Data Final
                        </label>
                        <div className="row">
                          <input
                            ref={(node) => {
                              inputDataFinal = node;
                            }}
                            type="date"
                            className="form-control col-sm-6"                           
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Previsão de Pagamento
                        </label>
                        <div className="row">
                          <input
                            ref={(node) => {
                              inputDataPag = node;
                            }}
                            type="date"
                            className="form-control col-sm-6"                            
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Data Contato
                        </label>
                        <div >
                          <input
                            ref={(node) => {
                              inputDataContato = node;
                            }}
                            type="date"
                            className="form-control col-sm-6"                           
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Tipo Demonstrativo
                        </label>  
                        <select ref={(node) => {
                            inputTipo = node;
                          }}
                          className="form-control col-sm-12"                          
                          >
                            <option selected value=""></option>
                            <option value="PADRONIZADO">PADRONIZADO</option>
                            <option value="PERSONALIZADO">PERSONALIZADO</option>
                            <option value="PADRONIZADO-PERSONALIZADO">PADRONIZADO-PERSONALIZADO</option>
                        </select>
                      </div>                                 
                      <div className="col-md-2">
                          <div className="col-sm-3" style={{paddingTop:"35px"}}>
                            <button className="btn btn-primary" type="submit" onClick={onFormSubmit}>
                              Validar
                            </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div>
                    <button className="btn btn-primary btn-sm" style={{ marginLeft: "20px" }}  type="button" onClick={() => get([0], [])}>
                      Atualizar tabela
                    </button>     
                  </div>
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} columnExtensions={tableColumnExtensions} />
                    <FilteringState onFiltersChange={handleFilters} />
                    <Table columnExtensions={tableColumnExtensions} />
                    <RowDetailState />
                    <DateTimeTypeProvider for={["data_importacao"]} />
                    <ButtonTypeProvider3 for={["progresso"]} /> 
                    <ButtonTypeProvider2 for={["cancelar"]} /> 
                    <ButtonTypeProvider for={["enviar_emails"]} /> 
                    <DateTypeProvider for={["data_inicio"]} />
                    <DateTypeProvider for={["data_fim"]} /> 
                    <DateTypeProvider for={["data_pag"]} />    
                    <DateTypeProvider for={["data_contato"]} />                 
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableFilterRow />          
                    <TableFixedColumns leftColumns={[TableEditColumn.COLUMN_TYPE]} />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
