import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/img/logo_takaoka_bots.png";
import "./loginStyle.css";
import CookieService from "../../services/CookieService";
import AlertMessage from "../AlertMessage";
import ReactLoading from "react-loading";
import TakaokaContext from "../contexts/TakaokaContext";
import AuthService from "../../services/AuthService";
import {
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

export default function Login(props) {

  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    const accessTokenRequest = {
      scopes: [process.env.REACT_APP_AUTH_SCOPE],
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        let accessToken = accessTokenResponse.accessToken;
        let date = new Date();
        date.setTime(date.getTime() + 8 * 3600000);
        const options = { path: "/", expires: date };

        CookieService.set("api_token", accessToken, options);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        }
      });

  }, [instance, accounts, inProgress]);

  const history = useHistory();

  let inputUserName;
  let inputPassword;

  var consumer = useContext(TakaokaContext);

  const [messageAlert, setMessageAlert] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    let userName = inputUserName.value;
    let password = inputPassword.value;

    setLoading(true);

    AuthService.login(userName, password).then(
      (response) => {
        let user = response?.data?.user;

        consumer.updateContext({
          user,
        });

        history.push("/");
        setLoading(false);
      },
      (error) => {
        setLoading(false);

        let error_message = "Acesso negado. Favor entrar em contato com o administrador do sistema";

        if (error?.messages) {
          error.messages.map((erro_msg) => {
            if (erro_msg?.message) {
              setMessageAlert([{ message: erro_msg?.message }]);
            } else {
              setMessageAlert([{ message: error_message }]);
            }
          });
        } else {
          setMessageAlert([{ message: error_message }]);
        }
      }
    );
  }

  return (
    <div id="wrapper">
      <div className="middle-box text-center loginscreen animated fadeInDown">
        <div>
          <img alt="image" src={logo} />
          <form className="m-t" role="form" onSubmit={handleSubmit}>
            <AlertMessage messages={messageAlert} />
            <div className="form-group">
              <input
                type="email"
                ref={(node) => {
                  inputUserName = node;
                }}
                className="form-control"
                placeholder="Username"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                ref={(node) => {
                  inputPassword = node;
                }}
                className="form-control"
                placeholder="Password"
                required
              />
            </div>

            {loading ? (
              <div className="form-group" style={{ display: "inline-table" }}>
                <ReactLoading type="spinningBubbles" color="#fff" width="50px" />
              </div>
            ) : (
              <button type="submit" className="btn btn-primary block full-width m-b">
                Login
              </button>
            )}

            {/* <a href="#"><small>Forgot password?</small></a> */}
            {/* <p className="text-muted text-center"><small>Do not have an account?</small></p> */}
            {/* <a className="btn btn-sm btn-white btn-block" href="register.html">Create an account</a> */}
          </form>
          <p className="m-t">
            {" "}
            <small>Takaoka © 2021</small>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
