import Modal from "react-modal";
import React, { useState } from "react";
import Files from "react-files";
import { useHistory } from "react-router-dom";
import { http } from "../../services/http";
import authenticatedUserContext from "../security/AuthenticatedUserContext";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export default function Import(props) {
  const [importIsOpen, setImportIsOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");

  const history = useHistory();

  function onFilesChange(files) {
    var file = files[0];
    setFile(file);
    setFileName(file?.name);
    console.log(files);
  }

  function onFilesError(error, file) {
    console.log("error code " + error.code + ": " + error.message);
  }

  function closeModal() {
    setImportIsOpen(false);
  }

  function importar(excluir) {
    if (file) {
      const formData = new FormData();
      formData.append("upload", new Blob([file], { type: file.type }), file.name || "file");

      var url = props.api_resource + "/import";
      if (excluir === true) {
        url = url + "?excluir=true";
      }

      http.post(url, formData).then(
        (response) => {
          props.alert.success("Sucesso!");
          setImportIsOpen(false);
          props.onFinish();
        },
        (error) => {
          if (error?.messages) {
            error.messages.map((erro_msg) => {
              props.alert.error("Erro. " + erro_msg?.error?.message, { timeout: 20000 });
            });
          } else {
            props.alert.error("Erro!");
          }
          setImportIsOpen(false);
        }
      );
    } else {
      alert("Informe um arquivo para importação!");
    }
  }

  return (
    <>
      {authenticatedUserContext.allow_edit(props.appname) === true ? (
        <button
          className="btn btn-sm btn-success"
          style={{ marginTop: "20px", marginLeft: "10px" }}
          type="button"
          onClick={() => {
            setFileName("");
            setImportIsOpen(true);
          }}
        >
          Importar
        </button>
      ) : (
        <></>
      )}

      <Modal isOpen={importIsOpen} style={customStyles} contentLabel="Teste">
        <form>
          <div className="form-group  row">
            <div className="col-sm-12">
              <Files className="files-dropzone" onChange={onFilesChange} onError={onFilesError} accepts={[".xls", ".xlsx", ".csv"]} multiple={false} maxFileSize={10000000} minFileSize={0} clickable>
                <div className="input-group">
                  <div className="custom-file">
                    <input readOnly={true} type="text" className="form-control" value={fileName} style={{ width: "300px" }} />
                    <label className="custom-file-label" for="inputGroupFile01">
                      {fileName}
                    </label>
                  </div>
                </div>
              </Files>
            </div>
          </div>
          <div className="hr-line-dashed"></div>
          <div className="form-group row" style={{ textAlign: "right" }}>
            <div className="col-sm-12 col-sm-offset-2">
              <button className="btn btn-primary btn-sm" type="button" onClick={closeModal} style={{ width: "70px" }}>
                Fechar
              </button>
              <button className="btn btn-sm btn-success" style={{ marginLeft: "10px", width: "70px" }} type="button" onClick={importar}>
                Importar
              </button>
              {props.excluir === true ? (
                <button className="btn btn-sm btn-danger" style={{ marginLeft: "10px", width: "150px" }} type="button" onClick={() => importar(true)}>
                  Excluir e Importar
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
