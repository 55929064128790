import Input from "@material-ui/core/Input";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import { TableFilterRow } from "@devexpress/dx-react-grid-material-ui";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = (theme) => ({
  cell: {
    width: "100%",
    padding: theme.spacing(1),
  },
  input: {
    fontSize: "12px",
    width: "100%",
  },
});

const ProcedimentosSmartFilterCellBase = ({ filter, onFilter, classes }) => {


  return (
  <TableCell className={classes.cell}>
    <TextField
      input={<Input />}
      className={classes.input}
      id="dateBegin"
      type="text"
      // value={begin ? begin : ""}
      onChange={(event) => onFilter({value: event.target.value})}
      InputLabelProps={{
        shrink: true,
      }}
      // InputProps={{
      //   startAdornment: <InputAdornment position="start">De:</InputAdornment>,
      // }}
    />
    
  </TableCell>
  )};
const ProcedimentosSmartFilterCell = withStyles(styles, { name: "ProcedimentosSmartFilterCell" })(ProcedimentosSmartFilterCellBase);

export const ProcedimentosH9JFilterCell = (props) => {
  const { column } = props;
  if (column.name === "codigo_descricao_smart") {
    return <ProcedimentosSmartFilterCell {...props} />;
  }
  return <TableFilterRow.Cell {...props} />;
};