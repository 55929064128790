import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import Menu from "../Menu";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { APPNAME_GERACAOXML } from "../utils/react_grid_utils";
import authenticatedUserContext from "../security/AuthenticatedUserContext";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility, TableFixedColumns, TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, ExportXlsx, TIMER_FILTER, APPNAME_AGENDAMENTOS } from "../utils/react_grid_utils";
import { PagingState, EditingState, CustomPaging, FilteringState, RowDetailState } from "@devexpress/dx-react-grid";
import { DateTimeTypeProvider } from "../utils/react_grid_datetime_column";


const getRowId = (row) => row.id;
const api_resource = "sap/smart-sap";
const api_resource2 = "sap/processar-manual";
const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;
let inputTipo = '';

export default function ReprocessarSapTable() {
  const alert = useAlert();

  const [importIsOpen, setImportIsOpen] = useState(false);
  const [concluirIsOpen, setConcluirIsOpen] = useState(false);
  const [resetViaIsOpen, setResetViaIsOpen] = useState(false);
  const [motivoInclusaoManual, setMotivoInclusaoManual] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [queryApi, setQueryApi] = useState("");
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "created", width: 160 },
    { columnName: "id", width: 60 },
    { columnName: "schedule_id", width: 120 },
    { columnName: "status_bot", width: 100 },
    //{ columnName: "comentarios", width: 200},
  ]);
  let inputSmartId;
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);

  function processar_SmartID() {

    if (inputTipo?.value == 'D2I' || inputTipo?.value == 'SMART') {

      if (inputSmartId.value) {
        let smarts = inputSmartId.value.trim().split(/[\s,;."+" ]+/);
        var smartSapManual = [];
        smarts.forEach(function (smart) {
          if (!isNaN(smart) && smart !== "" && inputTipo?.value == "D2I") {
            var smart_inset = {
              d2i_id: smart,
              status_bot: "PENDENTE",
            };
            smartSapManual.push(smart_inset);
          }
          else if (!isNaN(smart) && smart !== "" && inputTipo?.value == "SMART") {
            var smart_inset = {
              smart_docnum: smart,
              status_bot: "PENDENTE",
            };
            smartSapManual.push(smart_inset);
          }
          else {
            smarts.splice(smarts.indexOf(smart), 1)
          }
        });

        if (smartSapManual.length > 0) {
          if (window.confirm("Confirma o processamento do ID " + smarts + "?")) {
            smartSapManual.forEach(function (i) {
              Create(api_resource2 + "?db=" + inputTipo.value, i, alert).then((response) => {
                console.log(inputSmartId.value);
              });
              processar_manual_start()
              getProcessarManual(`?page=${1}`)
            });


          }
          inputSmartId.value = ""
        }
      }
    }
    else {
      alert.error('Escolha a Base de Dados!')
    }

  }

  function get(page, filters) {
    if (inputTipo?.value == 'D2I' || inputTipo?.value == 'SMART') {
      //let queryString = `?page=${page + 1}`;
      let queryString = QueryString(page, pageSize, filters);
      queryString = queryString + "db=" + inputTipo.value
      setQueryApi(queryString);

      getApi(queryString);

      getProcessarManual(queryString);
    }
    else {
      alert.error('Escolha a Base de Dados!')
    }
  }

  function getApi(queryString) {
    http.get(api_resource + queryString).then(
      (response) => {
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function getProcessarManual(queryString) {

    http.get(api_resource2 + queryString).then(
      (response) => {
        console.log(response?.data?.data);
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }



  useEffect(() => {
    get(0, []);
  }, []);


  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };



  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      Create(api_resource2, added[0], alert).then((response) => {
        var new_row = response.data[0];
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      var item = changedRows.find((row) => changed[row.id]);
      Update(api_resource2, item, alert);
      setRows(changedRows);
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };

  function reprocessar() {
    if (inputTipo?.value == 'D2I' || inputTipo?.value == 'SMART') {
      var confirmado = window.confirm("Confirma o reprocessamento?");
      if (confirmado === true) {
        http.put(api_resource + "/reprocessar" + "?db=" + inputTipo.value).then(
          (response) => {
            if (response?.data) {
              alert.info(response?.data, { timeout: INFO_TIMEOUT });
            } else {
              alert.success("Sucesso!");
            }
          },
          (error) => {
            if (error?.messages) {
              error.messages.map((erro_msg) => {
                if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                  alert.info("Nenhuma alteração enviada.");
                } else {
                  alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
                }
              });
            } else {
              alert.error("Não foi possível excluir o registro!");
            }
          }
        );
      }
    }
    else {
      alert.error('Escolha a Base de Dados!')
    }
  }

  function processar_manual_start() {
    http.put(api_resource2 + "?db=" + inputTipo.value).then(
      (response) => {
        if (response?.data) {
          alert.info(response?.data, { timeout: INFO_TIMEOUT });
        } else {
          alert.success("Processamento iniciado");
        }
      },
      (error) => {
        alert.error("Erro ao comunicar com Jenkins" + error?.messages);
      }
    );

  }

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function handlePage(page) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  function changeBase() {
    setRows([])
  }

  var columns = [
    { name: "created", title: "Adicionado em" },
    { name: "id", title: "ID" },
    { name: "schedule_id", title: "ID" },
    { name: "status_bot", title: "Status" },
    { name: "comentarios", title: "comentarios" },
  ];

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-6">
            <h2>Reprocessar SAP</h2>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-content">
                  <div className="row" style={{ paddingLeft: "15px" }}>
                    <label className="col-sm-12 col-form-label" >
                      Base de Dados
                    </label>
                    <select ref={(node) => {
                      inputTipo = node;
                    }}
                      onClick={changeBase}
                      className="form-control col-sm-3"
                    >
                      <option selected value=""></option>
                      <option value="D2I">D2I</option>
                      <option value="SMART">SMART</option>
                    </select>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                        ID
                      </label>
                      <input
                        ref={(node) => {
                          inputSmartId = node;
                        }}
                        type="text"
                        className="form-control col-sm-12"
                      />
                    </div>
                    <div className="col-md-2">
                      <div className="col-sm-3" style={{ paddingTop: "35px" }}>
                        <button className="btn btn-primary" type="button" onClick={processar_SmartID}>
                          Adicionar
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row" style={{ paddingTop: "20px", paddingLeft: "75%", height: "65px" }}>
                        <div className="col-lg-3" style={{ display: "flex" }}>
                          {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true ? (
                            <button className="btn btn-sm btn-success" type="button" onClick={reprocessar}>
                              Reprocessar
                            </button>
                          ) : (
                            <></>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br><br></br>
                  <div>
                    <button className="btn btn-primary btn-sm" style={{ left: "20px", backgroundColor: "cadetblue" }} type="button" onClick={() => get([0], [])}>
                      Atualizar tabela
                    </button>
                  </div>
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} columnExtensions={tableColumnExtensions} />
                    <FilteringState onFiltersChange={handleFilters} />
                    <Table columnExtensions={tableColumnExtensions} />
                    <RowDetailState />
                    <DateTimeTypeProvider for={["created"]} />
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableFilterRow />
                    <TableFixedColumns leftColumns={[TableEditColumn.COLUMN_TYPE]} />
                  </Grid>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
