import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState, EditingState, CustomPaging, FilteringState, DataTypeProvider } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableColumnVisibility, TableFixedColumns } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, TIMER_FILTER, APPNAME_AGENDAMENTOS } from "../utils/react_grid_utils";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import authenticatedUserContext from "../security/AuthenticatedUserContext";
import SettingsIcon from '@mui/icons-material/Settings';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import CookieService from "../../services/CookieService";
let user = CookieService.get("user");
console.log(user);
const getRowId = (row) => row.id;
const api_resource = "/parameters";

const DateFormatter = ({ value }) => {
  if (!value) return "";
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const DateEditor = ({ value, onValueChange }) => {
  const handleChange = (e) => {
    onValueChange(e.target.value);
  };
  return (
    <input
      type="date"
      value={value ? new Date(value).toISOString().split("T")[0] : ""}
      onChange={handleChange}
    />
  );
};

const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={DateFormatter}
    editorComponent={DateEditor}
    {...props}
  />
);

const BooleanFormatter = ({ value }) => (
  <input type="checkbox" checked={value} readOnly />
);

const BooleanEditor = ({ value, onValueChange, disabled }) => (
  <input
    type="checkbox"
    checked={value}
    onChange={(e) => onValueChange(e.target.checked)}
    disabled={disabled}
  />
);

const BooleanTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    editorComponent={(editorProps) => <BooleanEditor {...editorProps} disabled={props.disabled} />}
    {...props}
  />
);

const PasswordFormatter = ({ value }) => {
  if (value === null || value === undefined || value === "") return "";
  return "******";
};

const PasswordEditor = ({ value, onValueChange }) => (
  <input
    type="password"
    value={value || ""}
    onChange={(e) => onValueChange(e.target.value)}
  />
);

const PasswordTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={PasswordFormatter}
    editorComponent={PasswordEditor}
    {...props}
  />
);

const TypeFormatter = ({ value }) => {
  return value === 'Parâmetro' ? <SettingsIcon /> : <VpnKeyIcon />;
};

const TypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={TypeFormatter}
    {...props}
  />
);

const ParametroTable = () => {
  const alert = useAlert();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(0);
  const [rows, setRows] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [defaultHiddenColumnNames, setDefaultHiddenColumnNames] = useState(["id"]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filters, setFilters] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "enable", width: 60 },
    { columnName: "id", width: 60 },
    { columnName: "name", width: 200, editingEnabled: false },
    { columnName: "key", width: 200 },
    { columnName: "value", width: 200 },
    { columnName: "type", width: 100 },
    { columnName: "password", width: 200 },
    { columnName: "description", width: 275 },
    { columnName: "expirated_at", width: 200 },
    { columnName: "bot_id", width: 200 },
  ]);
  const [bots, setBots] = useState([]);
  const [rowTypes, setRowTypes] = useState({});
  const [userAccess, setUserAccess] = useState([]);

  useEffect(() => {
    if (user && user.user_access) {
      setUserAccess(user.user_access);
    }
  }, [user]);

  const formatDateForApi = (date) => {
    if (!date) return "";
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const typeMapping = {
    parameter: "Parâmetro",
    credential: "Credencial",
  };

  const determineRowType = (row) => {
    return row.password ? "credential" : "parameter";
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added && added.length > 0) {
      const newRows = added.map((row) => ({
        ...row,
        enable: true,
        bot_id: selectedOption.id,
        expirated_at: formatDateForApi(row.expirated_at),
        type: determineRowType(row),
      }));
      Create(api_resource, newRows[0], alert).then((response) => {
        var new_row = response.data;
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...newRows.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
        updateRowTypes(newRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => {
        if (changed[row.id]) {
          const updatedRow = {
            ...row,
            ...changed[row.id],
            bot_id: selectedOption.id,
            expirated_at: formatDateForApi(changed[row.id].expirated_at),
          };
          Update(api_resource + '/' + row.id, updatedRow, alert)
            .then(() => {
              setRows((prevRows) => prevRows.map(r => (r.id === row.id ? updatedRow : r)));
              updateRowType(row.id, updatedRow);
            })
            .catch((error) => {
              alert.show("Erro ao atualizar o item", { type: "error" });
              console.error("Erro ao atualizar:", error);
            });
        }
        return row;
      });
    }
    if (deleted && deleted.length > 0) {
      const confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert)
          .then(() => {
            const deletedSet = new Set(deleted);
            changedRows = rows.filter((row) => !deletedSet.has(row.id));
            setRows(changedRows);
          })
          .catch((error) => {
            alert.show("Erro ao excluir o item", { type: "error" });
            console.error("Erro ao excluir:", error);
          });
      }
    }
  };

  function getAllBots() {
    return http.get("/bots");
  }

  function getParameters(botId) {
    const queryString = QueryString(pageIndex, pageSize, filters);
    http.get(`${api_resource}/${botId}?${queryString}`).then(
      (response) => {
        // Filtra os itens que têm enable = true
        const filteredRows = response.data.filter(row => row.enable);
        setRows(filteredRows);
        setTotalItens(filteredRows.length);

        // Atualiza os tipos das linhas recuperadas
        updateRowTypes(filteredRows);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    getAllBots()
      .then((response) => {
        setBots(response.data);
      })
      .catch((error) => {
        console.error("Error fetching bots:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedOption) {
      getParameters(selectedOption.id);
    } else {
      setRows([]);
      setTotalItens(0);
    }
  }, [selectedOption, pageIndex, pageSize, filters]);

  const handleSelectionChange = (event) => {
    const selectedId = parseInt(event.target.value, 10);
    const selectedRow = bots.find((bot) => bot.id === selectedId);
    setSelectedOption(selectedRow);
  };

  const changeAddedRows = (value) => {
    const initialized = value.map((row) =>
      Object.keys(row).length ? row : {}
    );
    setAddedRows(initialized);

    // Atualiza os tipos das linhas adicionadas
    updateRowTypes(initialized);
  };

  const updateRowTypes = (updatedRows) => {
    const updatedTypes = {};
    updatedRows.forEach((row) => {
      updatedTypes[row.id] = determineRowType(row);
    });
    setRowTypes(updatedTypes);
  };

  const updateRowType = (rowId, updatedRow) => {
    const updatedType = determineRowType(updatedRow);
    setRowTypes((prevTypes) => ({
      ...prevTypes,
      [rowId]: updatedType,
    }));
  };

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFilters(filters);

    setTimer(
      setTimeout(() => {
        if (selectedOption) {
          getParameters(selectedOption.id);
        }
      }, TIMER_FILTER)
    );
  }

  useEffect(() => {
    if (editingRowIds.length > 0) {
      setDefaultHiddenColumnNames(["id"]);
    } else {
      setDefaultHiddenColumnNames(["id", "enable"]);
    }
  }, [editingRowIds]);

  const columns = [
    { name: "type", title: "Tipo", getCellValue: (row) => row.password ? 'Credencial' : 'Parâmetro' },
    { name: "name", title: "Nome" },
    { name: "id", title: "ID" },
    { name: "key", title: "Chave" },
    { name: "value", title: "Valor" },
    { name: "password", title: "Senha" },
    { name: "description", title: "Descrição" },
    { name: "expirated_at", title: "Data de Expiração" },
    { name: "enable", title: "Status" },
  ];

  const isUserAuthorizedForBot = (botName) => {
    const access = userAccess.find(access => access.name === botName && access.checked);
    return access ? access.allow_edit : false;
  };

  const hasUserAccessToBot = (botName) => {
    return userAccess.some(access => access.name === botName && access.checked);
  };

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Automações</h2>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <select onChange={handleSelectionChange}>
                    <option value="">Selecione uma Automação</option>
                    {bots.map((bot) => (
                      <option key={bot.id} value={bot.id}>
                        {bot.name}
                      </option>
                    ))}
                  </select>
                  {selectedOption && hasUserAccessToBot(selectedOption.name) ? (
                    <Grid rows={rows} columns={columns} getRowId={getRowId}>
                      <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={setPageIndex} />
                      <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} columnExtensions={tableColumnExtensions} />
                      <FilteringState filters={filters} onFiltersChange={handleFilters} />
                      <Table columnExtensions={tableColumnExtensions} />
                      <TableHeaderRow />
                      <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                      <DateTypeProvider for={["expirated_at"]} />
                      <BooleanTypeProvider for={["enable"]} disabled={!isUserAuthorizedForBot(selectedOption.name)} />
                      <PasswordTypeProvider for={["password"]} />
                      <TypeProvider for={["type"]} />
                      <TableEditRow /> 
                      {isUserAuthorizedForBot(selectedOption.name) ? (
                        <TableEditColumn 
                          showEditCommand
                          showDeleteCommand 
                          messages={editColumnMessages} 
                        /> 
                      ) : null}
                      <CustomPaging totalCount={totalItens} />
                      <PagingPanel />
                      <TableFixedColumns leftColumns={[TableEditColumn.COLUMN_TYPE]} />
                    </Grid>
                  ) : (
                    <div>Você não tem acesso a esta automação.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParametroTable;
