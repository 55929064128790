import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState, EditingState, CustomPaging, FilteringState, IntegratedFiltering, TableInlineCellEditing } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, ExportXlsx, TIMER_FILTER, APPNAME_PESQUISAMAILING } from "../utils/react_grid_utils";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import { SapStatusTypeProvider } from "../utils/react_grid_status_smart_sap_column";
import Import from "../utils/import";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import { FilterCell } from "../utils/react_grid_date_filter";
import authenticatedUserContext from "../security/AuthenticatedUserContext";
import { MailingTypeProvider } from "../utils/react_grid_status_mailing_column";

const getRowId = (row) => row.id;
const api_resource = "mailing/pesquisa-avaliacao";
const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;

export default function PesquisadeAvaliacaoTable() {
  const alert = useAlert();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "id", editingEnabled: false, width: 80},
    { columnName: "name",  editingEnabled: false,width: 300},
    { columnName: "email", editingEnabled: false,width: 100},
    { columnName: "unit", editingEnabled: false,width: 250},
    { columnName: "status", editingEnabled: true,width: 120},
    { columnName: "created_at", editingEnabled: false,width: 250},
    { columnName: "updated_at", editingEnabled: false,width: 160},
    { columnName: "send_survey", editingEnabled: false,width: 160},
    { columnName: "status_description", editingEnabled: false,width: 160}
  ]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);

  function get(page, filters) {
    const queryString = QueryString(page, pageSize, filters);
    http.get(api_resource + queryString).then(
      (response) => {
        console.log(response?.data?.data);
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    get(0, []);
  }, []);

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function handlePage(page, totalRows) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  function processar() {
    var confirmado = window.confirm("Confirma o reprocessamento?");
    if (confirmado === true) {
      http.put(api_resource + "/processar").then(
        (response) => {
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Sucesso!");
          }
        },
        (error) => {
          if (error?.messages) {
            error.messages.map((erro_msg) => {
              if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                alert.info("Nenhuma alteração enviada.");
              } else {
                alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
              }
            });
          } else {
            alert.error("Não foi possível processar o registro!");
          }
        }
      );
    }
  }

  function enviar() {
    var confirmado = window.confirm("Confirma o reprocessamento?");
    if (confirmado === true) {
      http.put(api_resource + "/enviar").then(
        (response) => {
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Sucesso!");
          }
        },
        (error) => {
          if (error?.messages) {
            error.messages.map((erro_msg) => {
              if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                alert.info("Nenhuma alteração enviada.");
              } else {
                alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
              }
            });
          } else {
            alert.error("Não foi possível processar o registro!");
          }
        }
      );
    }
  }

  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      Create(api_resource, added[0], alert).then((response) => {
        var new_row = response.data[0];
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      var item = changedRows.find((row) => changed[row.id]);
      Update(api_resource, item, alert);
      setRows(changedRows);
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };

  var columns = [
    { name: "id", title: "ID" },
    { name: "name", title: "Nome" },
    { name: "email", title: "E-mail" },
    { name: "unit", title: "Unidade" },
    { name: "status", title: "Status" },
    { name: "status_description", title: "Descrição" },
    { name: "created_at", title: "Criado" },
    { name: "updated_at", title: "Atualizado" },
    { name: "send_survey", title: "Enviado" }
    
  ];

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Pesquisa de Avaliação - Mailing</h2>
          </div>
          <div className="col-lg-10">
            <div className="row" style={{ paddingTop: "5px", paddingLeft: "80%",height:"65px" }}>
              <div className="col-lg-3" style={{ display: "flex", marginLeft: "15%" }}>
                {authenticatedUserContext.allow_edit(APPNAME_PESQUISAMAILING) === true ? (
                  <button className="btn btn-sm btn-success" type="button" onClick={processar}>
                    Processar
                  </button>
                ) : (
                  <></>
                )}{" "}
              </div>
              <div className="col-lg-3" style={{ display: "flex",marginLeft: "15%" }}>
                {authenticatedUserContext.allow_edit(APPNAME_PESQUISAMAILING) === true ? (
                  <button className="btn btn-sm btn-success" type="button" onClick={enviar}>
                    Enviar
                  </button>
                ) : (
                  <></>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} columnExtensions={tableColumnExtensions} />
                    <Table columnExtensions={tableColumnExtensions} />
                    <FilteringState onFiltersChange={handleFilters} />
                    <IntegratedFiltering />
                    <DateTypeProvider for={["created_at"]} />
                    <DateTypeProvider for={["updated_at"]} />
                    <DateTypeProvider for={["send_survey"]} />
                    <MailingTypeProvider for={["status"]} />
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <TableEditRow />
                    {authenticatedUserContext.allow_edit(APPNAME_PESQUISAMAILING) === true ? <TableEditColumn showEditCommand showDeleteCommand={false} messages={editColumnMessages}/> : <></>}
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableFilterRow cellComponent={FilterCell} />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
