import React, { useState, useRef, useEffect } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import { IntegratedFiltering, SelectionState, IntegratedSelection, SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, VirtualTable, TableColumnVisibility, TableSelection } from "@devexpress/dx-react-grid-material-ui";
import { NumberTypeProvider } from "../utils/react_grid_number_column";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@material-ui/lab';
import Input from "@material-ui/core/Input";
import { APPNAME_GERACAOXML } from "../utils/react_grid_utils";
import authenticatedUserContext from "../security/AuthenticatedUserContext";

const getRowId = (row) => row?.DocNum;
const api_resource = "notafiscal/n-pedidos";

const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    fontWeight: "bold",
    fontSize: "16px",
    maxWidth: "600px",
    transform: "translate(-50%, -50%)",
  },
};


export default function NPedidosTable() {
  const alert = useAlert();

  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "DocNum", width: 100 },
    { columnName: "U_Inicio", width: 130 },
    { columnName: "U_Paciente", width: 350 },
    { columnName: "U_Nome_Cirurgiao", width: 350 },
    { columnName: "U_Tipo_Recebimento", width: 150 },
    { columnName: "DocTotal", width: 120 },
  ]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [selection, setSelection] = useState([]);
  const [observacao, setObservacao] = useState("");
  const [valor, setValor] = useState(0);
  const [concluirIsOpen, setConcluirIsOpen] = useState(false);
  const [cardCode, setCardCode] = useState();

  let data = new Date();
  const data1 = data.toISOString().substring(0, 10);
  data.setMonth(data.getMonth() - 2);
  const data2 = data.toISOString().substring(0, 10);

  const [dataInicial, setDataInicial] = useState(data2);
  const [dataFinal, setDataFinal] = useState(data1);

  data = new Date();
  data.setMonth(data.getMonth() + 1);
  const data3 = data.toISOString().substring(0, 10);
  const [dataVencimento, setDataVencimento] = useState(data3);

  function get() {
    
    setSelection([]);

    let queryString = '';

    if (cardCode) {
      queryString = queryString + "&cardcode=" + cardCode;
    }
    if (dataInicial) {
      queryString = queryString + "&datainicial=" + dataInicial;
    }
    if (dataFinal) {
      queryString = queryString + "&datafinal=" + dataFinal;
    }

    http.get(`${api_resource}/orders?${queryString}`).then(
      (response) => {
        if(response?.data) {
          setRows(response?.data?.data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  var columns = [
    { name: "DocNum", title: "DocNum" },
    { name: "U_Inicio", title: "Data Início" },
    { name: "U_Paciente", title: "Paciente" },
    { name: "U_Nome_Cirurgiao", title: "Cirurgião" },
    { name: "U_Tipo_Recebimento", title: "Tipo Recebimento" },
    { name: "DocTotal", title: "Valor" },
  ];

  function onFormSubmit(e) {
    e.preventDefault();

    if(!dataInicial)
    {
      window.alert("Informe uma data inicial.");
      return;
    }
    if(!dataFinal)
    {
      window.alert("Informe uma data final.");
      return;
    }

    get();
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      onFormSubmit(e);
    }
  }

  function closeModal() {
    setConcluirIsOpen(false);
  }

  function concluir() {

    setConcluirIsOpen(false);

    console.log(selection);

    http.post(`${api_resource}`, {data_vencimento: dataVencimento, valor: valor, observacao: observacao, pedidos: selection}).then(
      (response) => {
        closeModal();
        setSelection([]);
        get();
        if (response?.data) {
          alert.info(response?.data, { timeout: INFO_TIMEOUT });
        } else {
          alert.success("Sucesso!");
        }
      },
      (error) => {
        if (error?.messages) {
          error.messages.map((erro_msg) => {
            if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
              alert.info("Nenhuma alteração enviada.");
            } else {
              alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
            }
          });
        } else {
          alert.error("Não foi possível excluir o registro!");
        }
      }
    );
  }

  const [options, setOptions] = useState([]);

  function searchProcedimentos(value) {

      http.get(`${api_resource}/partners?cliente=${value}`).then(
        (response) => {
          console.log(response);
          setOptions(response?.data?.data);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const [timer, setTimer] = useState(null);

  function triggerChange(inputText) {
    if (inputText.length > 4) {
      searchProcedimentos(inputText);
    }
    else
    {
      setOptions([]);
    }
  }

  function handleKeyUp(value) {

    clearTimeout(timer);

    setTimer(
      setTimeout(function () {
        triggerChange(value);
      }, 800)
    );
  }

  function getText(codigo, descricao)
  {
    let text = "";
    if(codigo != undefined && codigo != "")
      text = `${codigo} - `;
    
    if(descricao != undefined && descricao != "")
      text = text + descricao;

      return text;
  }

  function onValueChange(newValue)
  {
    setCardCode(getCardCode(newValue));
  }

  function getCardCode(value)
  {
    if(value.includes("-"))
    {
      let cliente = value.replace(/\s+/g, '').split((/-(.+)/));
      return cliente[0];
    }
    else
    {
      return null;
    }
  }

  useEffect(() => {
    handleSelectionRow(dataVencimento);
  }, [selection]);  

  function handleSelectionRow(data_vencimento)
  {
    setObservacao("");

    var observacao = "";

    var vlr_bruto = 0;
    var row = {};

    selection.map((id) => {
      row = rows.find(e => e.DocNum == id);

      vlr_bruto = vlr_bruto + row.DocTotal;
    });

    let impostos = (vlr_bruto * 0.0615).toFixed(2);
    
    if(impostos < 10) {
      impostos = 0
    }

    let vlr_liquido = (vlr_bruto - impostos).toFixed(2);

    observacao =   "" +
            `Serviços de Anestesia Prestados\n` +
            `\n` +
            `${data_vencimento ? 'Data Vencimento – ' + data_vencimento.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1") : ''}\n` +
            `Valor Bruto – R$ ${Intl.NumberFormat("pt-BR",{stype: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(vlr_bruto)}\n` +
            `Valor Líquido – R$ ${Intl.NumberFormat("pt-BR",{stype: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(vlr_liquido)}\n` +
            `\n` +
            `[1] Valor aproximando dos tributos 16,70%\n` +
            `Conforme Lei nº:12741 de 08.12.2012`;

    if (row?.U_Tipo_Recebimento?.toUpperCase() === "ASSOCIAÇÃO HIAE") {
      observacao += "\n\n" +
            "Dr.Flavio Takaoka\n" +
            "CRM: 31.745";
    }

    setValor(vlr_bruto);

    setObservacao(observacao);
  }   
  
  function handleObservacaoChange(e)
  {
    setObservacao(e.target.value)
  }

  function reprocessar() {
    var confirmado = window.confirm("Confirma o reprocessamento?");
    if (confirmado === true) {
      http.put(api_resource + "/reprocessar").then(
        (response) => {
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Sucesso!");
          }
        },
        (error) => {
          alert.error("Erro ao solicitar o Reprocessamento NF");
        }
      );
    }
  }

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Notas Fiscais N Pedidos</h2>
          </div>
          <div className="col-lg-2">
            <div className="row" style={{ paddingTop: "20px" }}>
              {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true ? (
                <button className="btn btn-sm btn-success" type="button" onClick={reprocessar}>
                  Reprocessar NF
                </button>
              ) : (
                <></>
              )}{" "}
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-content">
                  <form onSubmit={onFormSubmit} onKeyPress={handleKeyPress}>
                    <div className="row">
                      <div className="col-md-5">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Cliente / Fornecedor
                        </label>
                        <Autocomplete
                            options={options}
                            getOptionLabel={(option) => getText(option?.CardCode, option?.CardName)}
                            onInputChange={(event, newInputValue) => {
                              if(event?.type == "change")
                              {
                                handleKeyUp(newInputValue);
                              }
                              onValueChange(newInputValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="" variant="standard" />}/>
                      </div>
                      <div className="col-md-2">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>Data inicial</label>
                          <TextField
                            input={<Input />}
                            id="date"
                            type="date"
                            defaultValue={dataInicial}
                            required={true}
                            onChange={(event) => setDataInicial(event.target.value)}
                            InputLabelProps={{
                              shrink: true,
                              required: true
                            }}
                          />
                      </div>
                      <div className="col-md-2">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>Data final</label>
                          <TextField
                            input={<Input />}
                            id="date"
                            type="date"
                            defaultValue={dataFinal}
                            required={true}
                            onChange={(event) => setDataFinal(event.target.value)}
                            InputLabelProps={{
                              shrink: true,
                              required: true
                            }}
                          />
                      </div>                      
                      <div className="col-md-3">
                          <div className="col-sm-12" style={{paddingTop:"35px"}}>
                            <button className="btn btn-primary" type="submit" style={{marginRight:"5px"}} onClick={onFormSubmit}>
                              Buscar
                            </button>
                          </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="ibox-content">
                  <div className="row"> 
                    <div className="col-md-2">
                      <TextField
                          input={<Input />}
                          id="dateEnd"
                          label="Data vencimento"
                          type="date"
                          disabled={!(selection?.length > 0)}
                          defaultValue={dataVencimento}                          
                          onChange={(e) => {
                              setDataVencimento(e.target.value);
                              handleSelectionRow(e.target.value);
                            }
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                    </div>
                    <div className="col-md-4">
                      <TextField 
                          style ={{width: "400px"}}
                          input={<Input />}
                          id="observacao"
                          label="Observação de abertura e encerramento"
                          type="text"
                          multiline="true"
                          variant="outlined"
                          value={observacao}      
                          onChange={handleObservacaoChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                    </div>
                    <div className="col-md-4">
                        <button 
                          className="btn btn-sm btn-success" 
                          type="button" 
                          style={{ marginTop: "20px", marginLeft: "30px" }} 
                          disabled={!(selection?.length > 0)}
                          onClick={() => setConcluirIsOpen(true)}>Criar NF
                        </button>
                    </div>
                  </div>                  
                </div>
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <Table columnExtensions={tableColumnExtensions} />
                    <SelectionState
                      selection={selection}
                      onSelectionChange={setSelection}
                    />
                    <SortingState
                      defaultSorting={[{ columnName: 'U_Paciente', direction: 'asc' }]}
                    />
                    <IntegratedSorting/>
                    <IntegratedFiltering />
                    <NumberTypeProvider for={["DocTotal"]} />
                    <DateTypeProvider for={["U_Inicio"]} />
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <IntegratedSelection />
                    <TableSelection showSelectAll={true}/>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={concluirIsOpen} style={customStyles}>
        <form>
          <div className="form-group  row">
            <div className="col-sm-12">
            Confirma a criação da nota fiscal?
          </div>
          </div>
          <div className="hr-line-dashed"></div>
          <div className="form-group row" style={{ textAlign: "right" }}>
            <div className="col-sm-12 col-sm-offset-2">
              <button className="btn btn-primary btn-sm" type="button" onClick={closeModal} style={{ width: "70px" }}>
                Voltar
              </button>
              <button className="btn btn-sm btn-success" style={{ marginLeft: "10px", width: "100px" }} type="button" onClick={() => concluir()}>
                Confirmar
              </button>
            </div>
          </div>
        </form>
      </Modal> 
    </div>
  );
}
