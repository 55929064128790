import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState, CustomPaging, RowDetailState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import { ViaTypeProvider } from "../utils/react_grid_via_column";
import { APPNAME_GERACAOXML } from "../utils/react_grid_utils";
import authenticatedUserContext from "../security/AuthenticatedUserContext";

const getRowId = (row) => row.doc_num;
const api_resource = "revisao-lotes";
const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    fontWeight: "bold",
    fontSize: "16px",
    maxWidth: "600px",
    transform: "translate(-50%, -50%)",
  },
};

export default function RevisaoLotesTable() {
  const alert = useAlert();

  const [importIsOpen, setImportIsOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [queryApi, setQueryApi] = useState("");
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "id", width: 60 },
    { columnName: "data", width: 120, editingEnabled: false },
    { columnName: "doc_num", width: 90, editingEnabled: false },
    { columnName: "via", width: 80 },
    { columnName: "paciente", width: 350, editingEnabled: false },
    { columnName: "hospital", width: 250, editingEnabled: false },
    { columnName: "convenio", width: 200, editingEnabled: false },
    { columnName: "anestesista", width: 350, editingEnabled: false },
    { columnName: "tuss", width: 100, editingEnabled: false },
    { columnName: "desc_proc", width: 600, editingEnabled: false },
  ]);
  let inputPaciente, inputAnestesista, inputConvenio, inputDataInicial, inputDataFinal, inputPlano, inputHospital, inputProcedimento, inputLote;

  function get(page, filters) {
    let queryString = `?page=${page + 1}`;

    if (inputLote?.value) {
      queryString = queryString + "&lote=" + inputLote.value;
    }

    setQueryApi(queryString);

    getApi(queryString);
  }

  function getApi(queryString) {
    http.get(api_resource + queryString).then(
      (response) => {
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  var columns = [
    { name: "doc_num", title: "DocNum" },
    { name: "data", title: "Data" },
    { name: "paciente", title: "Paciente" },
    { name: "anestesista", title: "Anestesista" },
    { name: "hospital", title: "Hospital" },
    { name: "convenio", title: "Convênio" },
  ];

  var detail_columns = [
    { name: "tuss", title: "TUSS" },
    { name: "desc_proc", title: "Procedimento" },
    { name: "status_bot", title: "Status" },
    { name: "plano", title: "Plano" },
    { name: "via", title: "Via" },
  ];

  function handlePage(page, totalRows) {
    setPageIndex(page);
    get(page, []);
  }

  function cancelar(status_bot) {
    if (inputLote?.value > 0) {
      var confirmado = window.confirm(`Confirma o cancelamento do lote ${inputLote.value}?`);
      if (confirmado === true) {
        http.put(api_resource + "/cancelar", { lote: inputLote.value, status_bot: status_bot }).then(
          (response) => {
            if (response?.data) {
              alert.info(response?.data, { timeout: INFO_TIMEOUT });
            } else {
              alert.success("Sucesso!");
            }
          },
          (error) => {
            if (error?.messages) {
              error.messages.map((erro_msg) => {
                if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                  alert.info("Nenhuma alteração enviada.");
                } else {
                  alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
                }
              });
            } else {
              alert.error("Não foi possível excluir o registro!");
            }
          }
        );
      }
    } else {
      window.alert("Informe um número de lote!");
    }
  }

  function reset(row) {
    var status_bot = "PENDENTE";
    if (row.status_bot == "CONCLUIDO") {
      status_bot = "GERAR_XML";
    }

    http.put(`${api_resource}/reset`, { doc_num: row.doc_num, status_bot: status_bot }).then(
      (response) => {
        closeModal();
        alert.success("Sucesso!");
        getApi(queryApi);
      },
      (error) => {
        if (error?.messages) {
          error.messages.map((erro_msg) => {
            if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
              alert.info("Nenhuma alteração enviada.");
            } else {
              alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
            }
          });
        } else {
          alert.error("Não foi possível excluir o registro!");
        }
      }
    );
  }

  function onFormSubmit(e) {
    e.preventDefault();
    get(0, []);
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      onFormSubmit(e);
    }
  }

  function closeModal() {
    setImportIsOpen(false);
  }

  const RowDetail = ({ row }) => (
    <div className="ibox ">
      <Grid rows={row.procedimentos} columns={detail_columns}>
        <Table columnExtensions={tableColumnExtensions} />
        <DateTypeProvider for={["data"]} />
        <ViaTypeProvider for={["via"]} />
        <TableHeaderRow />
      </Grid>
    </div>
  );

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Revisão de Lotes</h2>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <form onSubmit={onFormSubmit} onKeyPress={handleKeyPress}>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="col-sm-6 col-form-label" style={{ paddingLeft: "1px" }}>
                          Lote
                        </label>
                        <div className="row" style={{ paddingLeft: "15px" }}>
                          <input
                            ref={(node) => {
                              inputLote = node;
                            }}
                            type="text"
                            className="form-control col-sm-3"
                          />
                          <div className="col-sm-3">
                            <button className="btn btn-primary" type="submit" type="button" onClick={onFormSubmit}>
                              Buscar
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8" style={{ display: "flex", alignItems: "flex-end", flexFlow: "row-reverse" }}>
                        <button className="btn btn-success" type="submit" type="button" onClick={() => cancelar("PENDENTE")}>
                          Cancelar (Voltar Status para PENDENTE)
                        </button>
                        &nbsp;
                        <button className="btn btn-success" type="submit" type="button" onClick={() => cancelar("GERAR_XML")}>
                          Cancelar (Voltar Status para GERAR_XML)
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <RowDetailState />
                    <Table columnExtensions={tableColumnExtensions} />
                    <DateTypeProvider for={["data"]} />
                    <TableHeaderRow />
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableRowDetail contentComponent={RowDetail} expanded={true} />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
