import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

const TipoAcomodacaoFormatter = ({ value }) => <TextField value={value == "1" ? "Enfermaria" : value == "2" ? "Apartamento" : ""} InputProps={{ disableUnderline: true }} />;

const TipoAcomodacaoEditor = ({ value, onValueChange }) => (
  <Select input={<Input />} value={value} onChange={(event) => onValueChange(event.target.value)} style={{ width: "100%" }}>
    <MenuItem value="">Todos</MenuItem>
    <MenuItem value="1">Enfermaria</MenuItem>
    <MenuItem value="2">Apartamento</MenuItem>
  </Select>
);

export const TipoAcomodacaoTypeProvider = (props) => <DataTypeProvider formatterComponent={TipoAcomodacaoFormatter} editorComponent={TipoAcomodacaoEditor} {...props} />;
