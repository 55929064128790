import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const SelectEditor = ({ value, onValueChange, items }) => (
    <Select
      value={value || ''}
      onChange={event => onValueChange(event.target.value)}
      displayEmpty
    >
      {items.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );

export const SelectTypeProvider = ({ items, ...props }) => (
<DataTypeProvider
    formatterComponent={({ value }) => value} // Usando o valor diretamente como o displayValue
    editorComponent={(editorProps) => <SelectEditor {...editorProps} items={items} />}
    {...props}
/>
);
