import React, { useState } from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import { size } from "lodash";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  input: {
    fontSize: "14px",
    paddingRight: "5px",
    textAlign: "right",
    width: "80%",
  },
});

const TelefoneEditorBase = ({ value, onValueChange }) => {
  const [mask, setMask] = useState("(99) 99999-9999");

  const handleMask = (e) => {
    if (size(e.target.value.replace(/[^0-9.]/g, "")) < 11) {
      setMask("(99) 9999-99999");
    } else {
      setMask("(99) 99999-9999");
    }
  };

  return (
    <InputMask mask={mask} onKeyUp={handleMask} onFocus={handleMask} maskChar={null} value={value} onChange={(event) => onValueChange(event.target.value)}>
      {(inputProps) => <MaterialInput {...inputProps} type="tel" />}
    </InputMask>
  );
};

const TelefoneEditor = withStyles(styles)(TelefoneEditorBase);

export const TelefoneTypeProvider = (props) => <DataTypeProvider editorComponent={TelefoneEditor} {...props} />;
