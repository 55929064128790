import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

const ChNumberFormatter = ({ value }) => {
  if (value) return Number(value).toFixed(4).replace(".", ",");

  return "";
};

const ChNumberEditor = ({ value, onValueChange, disabled }) => {
  const [valueCurrency, setValueCurrency] = React.useState(value);

  if (disabled) {
    return null;
  }

  function handleValue(event, value) {
    setValueCurrency(value);
    let newValue = value.replace(",", ".");
    onValueChange(newValue);
  }

  return <CurrencyTextField variant="standard" value={valueCurrency} currencySymbol="" outputFormat="string" decimalCharacter="," decimalPlaces="4" digitGroupSeparator="" onChange={handleValue} style={{ width: "100px" }} />;
};

export const ChNumberTypeProvider = (props) => <DataTypeProvider editorComponent={ChNumberEditor} formatterComponent={ChNumberFormatter} {...props} />;
