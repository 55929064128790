import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { Link, useHistory } from "react-router-dom";
import Header from "../Header";
import Switch from "react-switch";
import { http } from "../../services/http";

export default function UserDetails(props) {
  const history = useHistory();

  let user_id = props.location?.user_id;

  const [appsPermissions, setAppsPermissions] = useState([]);

  const [user_name, setUserName] = useState("");
  const [user, setUser] = useState({});

  function searchUser() {
    http.get("users/" + user_id).then(
      (response) => {
        console.log(response.data);
        setUserName(response.data?.name);
        setActive(response.data?.active);
        setUser(response.data);
        setAppsPermissions(response.data?.user_access);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    searchUser();
  }, []);

  const [active, setActive] = useState(true);

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <Header />

        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Users</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/users">
                  <a>Home</a>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <a>{user_name} </a>
              </li>
              <li className="breadcrumb-item">
                <a>Details</a>
              </li>
            </ol>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <form>
                    <div className="tabs-container">
                      <ul className="nav nav-tabs">
                        <li>
                          <a className="nav-link active" data-toggle="tab" href="#tab-3">
                            {" "}
                            <i className="fa fa-user"></i>
                          </a>
                        </li>
                        <li>
                          <a className="nav-link" data-toggle="tab" href="#tab-4">
                            <i className="fa fa-unlock"></i>
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content">
                        <div id="tab-3" className="tab-pane active">
                          <br />
                          <div className="form-group  row">
                            <label className="col-sm-2 col-form-label">Name</label>
                            <label className="col-form-label">{user?.name}</label>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group  row">
                            <label className="col-sm-2 col-form-label">Email</label>

                            <label className="col-form-label">{user?.email}</label>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Active</label>
                            {/* <label className="col-form-label">{active?.toString()}</label>  */}
                            <Switch checked={active} />
                          </div>
                          <div className="hr-line-dashed"></div>
                        </div>
                        <div id="tab-4" className="tab-pane">
                          <br />

                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Permissões</label>
                          </div>

                          {appsPermissions?.map(({ name, checked }) =>
                            checked ? (
                              <div className="form-group row">
                                <label className="col-sm-2 col-form-label">{name}</label>
                              </div>
                            ) : (
                              <></>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group row" style={{ textAlign: "right" }}>
                      <div className="col-sm-12 col-sm-offset-2">
                        <Link to="/users">
                          <button className="btn btn-white btn-sm" style={{ color: "#343a40", marginRight: "5px" }} type="submit">
                            Back
                          </button>
                        </Link>
                        <Link to={{ pathname: "/edit-user", user_id: user_id }}>
                          <button className="btn btn-primary btn-sm" style={{ marginRight: "5px" }}>
                            Edit
                          </button>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
