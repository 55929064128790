import React from 'react';
import profile_small from '../assets/img/profile_small.jpg'
import { Link, useHistory } from 'react-router-dom';
import CookieService from '../services/CookieService';
import {
    useMsal,
  } from "@azure/msal-react";

export default function Header() {

    const history = useHistory();

    const { accounts, instance } = useMsal();

    function handleLogout() {
        CookieService.remove("access_token");
        CookieService.remove("email");
        CookieService.remove("api_token");
    
        signOutClickHandler(instance);
    
        history.push({ pathname: "/" });
    }

    function signOutClickHandler(instance) {
        const logoutRequest = {
          account: instance.getAccountByHomeId(accounts[0].homeAccountId),
        };
        instance.logoutRedirect(logoutRequest);
      }

    return (
        <div className="row border-bottom">
            <nav className="navbar navbar-static-top" role="navigation" style={{ marginBottom: '0' }}>
                <div className="navbar-header">
                    {/* <span>takaokabots</span> */}
                </div>
                <ul className="nav navbar-top-links navbar-right">
                    <li>
                        <a onClick={handleLogout}>
                            <i className="fa fa-sign-out"></i> Log out
                        </a>
                    </li>
                </ul>

            </nav>
        </div>
    );
}
