import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

const NumberFormatter = ({ value }) => {
  if (value) return Number(value).toFixed(2).replace(".", ",");

  return "";
};

const NumberEditor = ({ value, onValueChange, disabled }) => {
  const [valueCurrency, setValueCurrency] = React.useState(value);

  if (disabled) {
    return null;
  }

  function handleValue(event, value) {
    setValueCurrency(value);
    let newValue = value.replace(",", ".");
    onValueChange(newValue);
  }

  return <CurrencyTextField variant="standard" value={valueCurrency} currencySymbol="" outputFormat="string" decimalCharacter="," decimalPlaces="2" digitGroupSeparator="" onChange={handleValue} style={{ width: "100px" }} />;
};

export const NumberTypeProvider = (props) => <DataTypeProvider editorComponent={NumberEditor} formatterComponent={NumberFormatter} {...props} />;
