import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { Link, useHistory } from "react-router-dom";
import Header from "../Header";
import { toInputUppercase, toInputLowercase } from "../operator/Utils";
import Switch from "react-switch";
import AlertMessage from "../AlertMessage";
import { http } from "../../services/http";

export default function UserEditForm(props) {
  const history = useHistory();

  let user_id = props.location?.user_id;

  const [appsPermissions, setAppsPermissions] = useState([]);
  const [messagesValidation, setMessageValidations] = useState([]);
  const [user, setUser] = useState({});

  function searchUser() {
    http.get("users/" + user_id).then(
      (response) => {
        console.log(response.data);
        setUserName(response.data?.name);
        setActive(response.data?.active);
        setUser(response.data);
        setAppsPermissions(response.data?.user_access);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    searchUser();
  }, []);

  let inputName, inputEmail;

  const [user_name, setUserName] = useState("");
  const [user_application_access, setUserApplicationAccess] = useState([]);

  function handleSubmit(e) {
    e.preventDefault();

    var user = {
      id: user_id,
      name: inputName.value,
      active: active,
      email: inputEmail.value,
      password: "12345678",
      ui_access: appsPermissions?.filter((item) => item.checked === true),
    };

    http.put("users", user).then(
      () => {
        history.push("/users");
      },
      (error) => {
        let arrayMessages = [];
        if (error?.messages) {
          error.messages.map((erro_msg) => {
            arrayMessages.push({ message: erro_msg?.error?.message });
          });
        } else {
          arrayMessages.push({ message: "Erro ao salvar usuário" });
        }

        setMessageValidations(arrayMessages);
      }
    );
  }

  function handleDelete(e) {
    e.preventDefault();

    http.delete("users/" + user_id).then(
      () => {
        history.push("/users");
      },
      (error) => {
        let arrayMessages = [];
        if (error?.messages) {
          error.messages.map((erro_msg) => {
            arrayMessages.push({ message: erro_msg?.error?.message });
          });
        } else {
          arrayMessages.push({ message: "Erro ao salvar usuário" });
        }

        setMessageValidations(arrayMessages);
      }
    );
  }

  const [active, setActive] = useState(true);

  function handleActive(checked) {
    setActive(checked);
  }

  function onUpdateApplication(id, checked, allow_edit) {
    setAppsPermissions(
      appsPermissions?.map((item) => {
        if (item.id === id) {
          item.checked = checked;
          item.allow_edit = allow_edit;
        }

        return {
          id: item.id,
          name: item.name,
          checked: item.checked,
          allow_edit: item.allow_edit,
        };
      })
    );
  }

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <Header />

        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Users</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/users">
                  <a>Home</a>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <a>{user_name} </a>
              </li>
              <li className="breadcrumb-item">
                <a>Edit</a>
              </li>
            </ol>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <AlertMessage messages={messagesValidation} />
                  <form onSubmit={handleSubmit}>
                    <div className="tabs-container">
                      <ul className="nav nav-tabs">
                        <li>
                          <a className="nav-link active" data-toggle="tab" href="#tab-3">
                            {" "}
                            <i className="fa fa-user"></i>
                          </a>
                        </li>
                        <li>
                          <a className="nav-link" data-toggle="tab" href="#tab-4">
                            <i className="fa fa-unlock"></i>
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content">
                        <div id="tab-3" className="tab-pane active">
                          <br />
                          <div className="form-group  row">
                            <label className="col-sm-2 col-form-label">Name</label>

                            <div className="col-sm-10">
                              <input
                                autoFocus
                                ref={(node) => {
                                  inputName = node;
                                }}
                                type="text"
                                className="form-control"
                                defaultValue={user?.name}
                                required
                                onInput={toInputUppercase}
                              />
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group  row">
                            <label className="col-sm-2 col-form-label">Email</label>

                            <div className="col-sm-10">
                              <input
                                ref={(node) => {
                                  inputEmail = node;
                                }}
                                type="email"
                                className="form-control"
                                defaultValue={user?.email}
                                required
                                onInput={toInputLowercase}
                              />
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Active</label>
                            <Switch onChange={handleActive} checked={active} />
                          </div>
                          <div className="hr-line-dashed"></div>
                        </div>
                        <div id="tab-4" className="tab-pane">
                          <br />
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Permissões</label>
                          </div>

                          {appsPermissions?.map(({ name, id, checked, allow_edit }) => (
                            <div className="form-group row">
                              <label className="col-sm-2 col-form-label">{name}</label>
                              <Switch className="col-sm-2" onChange={(value) => onUpdateApplication(id, value, allow_edit)} checked={checked} />
                              <label className="col-sm-2 col-form-label">Permite edição</label>
                              <Switch className="col-sm-2" onChange={(value) => onUpdateApplication(id, checked, value)} checked={allow_edit} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group row" style={{ textAlign: "right" }}>
                      <div className="col-sm-12 col-sm-offset-2">
                        <Link to="/users">
                          <button className="btn btn-white btn-sm" style={{ color: "#343a40", marginRight: "5px" }} type="submit">
                            Cancel
                          </button>
                        </Link>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: "5px" }} onClick={handleDelete}>
                          Delete
                        </button>
                        <button className="btn btn-primary btn-sm" type="submit">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
