import CookieService from "./CookieService";
import possuiValor from "../components/operator/Utils";
import { http } from "./http";

const AuthService = () => {
  const isLoggedIn = () => {
    return possuiValor(CookieService.get("user"));
  };

  const login = (userName) => {
    var data = {
      email: userName,
    };

    return new Promise((resolve, reject) => {
      http
        .post("sessions", data)
        .then(function (result) {
          let user = result?.data?.user;

          let date = new Date();
          date.setTime(date.getTime() + 8 * 3600000);
          const options = { path: "/", expires: date };
          CookieService.set("user", JSON.stringify(user), options);

          resolve(result);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  return {
    isLoggedIn,
    login,
  };
};

export default AuthService();
