import React, { useEffect, useContext, useState } from "react";
import AuthService from "../../services/AuthService";
import { Redirect, Route } from "react-router-dom";
// import SetAuthenticatedUserContext from "./AuthenticatedUserContext";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import CookieService from "../../services/CookieService";
import TakaokaContext from "../contexts/TakaokaContext";
import AlertMessage from './../AlertMessage';
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.
  const isLoggedIn = AuthService.isLoggedIn();

  const { instance, inProgress, accounts } = useMsal();
  var consumer = useContext(TakaokaContext);

  const [messageAlert, setMessageAlert] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const history = useHistory();
  const [apiToken, setApiToken] = useState();

  useEffect(() => {
    const accessTokenRequest = {
      scopes: [process.env.REACT_APP_AUTH_SCOPE],
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        let accessToken = accessTokenResponse.accessToken;
        let date = new Date();
        date.setTime(date.getTime() + 8 * 3600000);
        const options = { path: "/", expires: date };

        CookieService.set("api_token", accessToken, options);
        setApiToken(accessToken)
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        }
      });

  }, [instance, accounts, inProgress]);

  useEffect(() => {

    if(!user && accounts?.[0] && apiToken) {
      AuthService.login(accounts[0].username).then(
        (response) => {
          let responseUser = response?.data?.user;
          setUser(responseUser);
  
          consumer.updateContext({
            responseUser,
          });
  
          history.push("/");
          setLoading(false);
        },
        (error) => {
          setLoading(false);
  
          let error_message = "Acesso negado. Favor entrar em contato com o administrador do sistema";
  
          if (error?.messages) {
            error.messages.map((erro_msg) => {
              if (erro_msg?.message) {
                setMessageAlert([{ message: erro_msg?.message }]);
              } else {
                setMessageAlert([{ message: error_message }]);
              }
            });
          } else {
            setMessageAlert([{ message: error_message }]);
          }
        }
      );  
    }

  }, [accounts, user, apiToken, consumer, history]);

  return (

    <Route {...rest} render={(props) => (isLoggedIn ?
      loading ? (
        <div className="form-group" style={{ display: "inline-table" }} >
          <ReactLoading type="spinningBubbles" color="#fff" width="50px" />
        </div >
      ) : (
        <Component {...props} />) : <AlertMessage messages={messageAlert} />)} />
  );

};

export default PrivateRoute;
