import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { useAlert } from "react-alert";
import { http } from "../../services/http";
import { PagingState, FilteringState, IntegratedFiltering, SelectionState, IntegratedSelection, IntegratedPaging } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableFilterRow, TableSelection } from "@devexpress/dx-react-grid-material-ui";
import { QueryString } from "../utils/react_grid_utils";
import { DateTypeProvider } from "../utils/react_grid_date_column";

const getRowId = (row) => row.doc_num;
const api_resource = "sap/agendamento-retroativo";
const api_resource2 = "sap/processar-manual";
const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;
let inputTipo;

export default function AgendamentoRetroativo() {

   const alert = useAlert();
   const [pageSize] = useState(10);
   const [rows, setRows] = useState([]);
   const [tableColumnExtensions] = useState([
      { columnName: "doc_num", editingEnabled: false, width: 80 },
      { columnName: "data", editingEnabled: false, width: 150 },
      { columnName: "paciente", editingEnabled: false, width: 250 },
      { columnName: "anestesista", editingEnabled: false, width: 250 },
      { columnName: "cirurgiao", editingEnabled: false, width: 250 },
      { columnName: "hospital", editingEnabled: false, width: 250 },
      { columnName: "cobranca", editingEnabled: false, width: 250 },
   ]);
   // const [defaultHiddenColumnNames] = useState(["id"]);
   const [selection, setSelection] = useState([]);

   let defaultDataInicial = new Date();
   defaultDataInicial.setDate(defaultDataInicial.getDate() - 90);
   let inputDataInicial = defaultDataInicial.toISOString().split('T')[0];

   let defaultDataFinal = new Date();
   defaultDataFinal.setDate(defaultDataFinal.getDate() - 1);
   let inputDataFinal = defaultDataFinal.toISOString().split('T')[0];

   function get(page, filters) {
      if (inputTipo?.value == 'D2I' || inputTipo?.value == 'SMART') {
         let queryString = QueryString(page, pageSize, filters);

         if (inputDataInicial?.value) {
            queryString = queryString + "&dataInicial=" + inputDataInicial.value;
         }
         if (inputDataFinal?.value) {
            queryString = queryString + "&dataFinal=" + inputDataFinal.value;
         }


         queryString = queryString + "&db=" + inputTipo.value;

         http.get(api_resource + queryString).then(
            (response) => {
               setRows(response?.data?.rows || []);
            },
            (error) => {
               setRows([])
               console.log(error);
            }
         );
      }
      else {
         alert.error('Escolha a Base de Dados!')
      }
   }

   function changeBase() {
      setRows([])
   }

   function processar() {
      if (inputTipo?.value == 'D2I' || inputTipo?.value == 'SMART') {
         let docnum = { docnum: selection }

         http.post(api_resource + "?db=" + inputTipo.value, docnum).then(
            (response) => {
               console.log(response?.data);
               if (response.data.status === 'OK' || response.data.docnum_ok.length > 0) {
                  alert.success("Importação realizada com sucesso!!!", { timeout: INFO_TIMEOUT });
                  processar_manual_start();
                  setRows(rows.filter((row) => !selection.includes(getRowId(row))));
                  setSelection([]);
               } else {
                  alert.error(`Falha ao importar agendamento(s). Motivo: ${response.data.message}`, { timeout: ERROR_TIMEOUT });
               }
            },
            (error) => {
               console.log(error);
            }
         );

         setSelection([]);
         get(0, []);
      }
      else {
         alert.error('Escolha a Base de Dados!')
      }

   }

   function processar_manual_start() {
      http.put(api_resource2 + "?db=" + inputTipo.value).then(
         (response) => {
            if (response?.data) {
               alert.info(response?.data, { timeout: INFO_TIMEOUT });
            } else {
               alert.success("Processamento iniciado");
            }
         },
         (error) => {
            alert.error("Erro ao comunicar com Jenkins" + error?.messages);
         }
      );
   }

   useEffect(() => {
      get(0, []);
   }, []);

   function onFormSubmit(e) {
      e.preventDefault();
      get(0, []);
   }

   function handleKeyPress(e) {
      if (e.key === "Enter") {
         onFormSubmit(e);
      }
   }

   var columns = [
      { name: "doc_num", title: "ID" },
      { name: "data", title: "Data" },
      { name: "paciente", title: "Paciente" },
      { name: "anestesista", title: "Anestesista" },
      { name: "cirurgiao", title: "Cirurgião" },
      { name: "hospital", title: "Hospital" },
      { name: "cobranca", title: "Cobrança" },
   ];

   return (
      <div id="wrapper">
         <Menu />
         <div id="page-wrapper" className="gray-bg">
            <div className="row wrapper border-bottom white-bg page-heading">
               <div className="col-lg-10">
                  <h2>Agendamento Retroativo</h2>
               </div>
            </div>
            <div className="wrapper wrapper-content animated fadeInRight">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="ibox ">
                        <div className="ibox-content">
                           <form onSubmit={onFormSubmit} onKeyPress={handleKeyPress} style={{ marginLeft: 10 }}>
                              <div className="row">
                                 <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                                    Base de Dados
                                 </label>
                                 <select ref={(node) => {
                                    inputTipo = node;
                                 }}
                                    className="form-control col-sm-3"
                                    onClick={changeBase}
                                 >
                                    <option selected value=""></option>
                                    <option value="D2I">D2I</option>
                                    <option value="SMART">SMART</option>
                                 </select>
                              </div>
                              <div className="row">
                                 <div className="col-md-3">
                                    <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                                       Período
                                    </label>
                                    <div className="row">
                                       <input
                                          ref={(node) => {
                                             inputDataInicial = node;
                                          }}
                                          type="date"
                                          defaultValue={inputDataInicial}
                                          className="form-control col-sm-6"
                                       />
                                       <input
                                          ref={(node) => {
                                             inputDataFinal = node;
                                          }}
                                          type="date"
                                          defaultValue={inputDataFinal}
                                          className="form-control col-sm-6"
                                       />
                                    </div>
                                 </div>
                                 <div className="col-md-3">
                                    <div className="col-sm-3" style={{ paddingTop: "35px" }}>
                                       <button className="btn btn-primary" type="submit" onClick={onFormSubmit}>
                                          Buscar
                                       </button>
                                    </div>
                                 </div>
                                 <div className="col-md-3" style={{ display: "flex", alignItems: "flex-end", flexFlow: "row-reverse" }}>
                                    <button className="btn btn-success" type="submit" onClick={() => processar()}>
                                       Processar
                                    </button>
                                 </div>
                              </div>
                           </form>
                        </div>
                        <div className="ibox-content">
                           <Grid rows={rows} columns={columns} getRowId={getRowId} >
                              <PagingState defaultCurrentPage={0} pageSize={pageSize} />
                              <FilteringState />
                              <IntegratedFiltering />
                              <IntegratedPaging />
                              <Table columnExtensions={tableColumnExtensions} />
                              <SelectionState selection={selection} onSelectionChange={setSelection} />
                              <IntegratedSelection />
                              <TableSelection showSelectAll />
                              <TableHeaderRow />
                              <DateTypeProvider for={["data"]} />
                              <TableFilterRow />
                              <PagingPanel />
                           </Grid>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
