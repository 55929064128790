import React, { useState, useEffect } from "react";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { http } from "../../services/http";

const AbasEditor = ({ value, onValueChange, disabled }) => {
  const [options, setOptions] = useState([]);

  function getAbas() {
    http.get("portes/abas").then((response) => {
      setOptions(response?.data);
    });
  }

  useEffect(() => {
    getAbas();
  }, []);

  if (disabled) {
    return value;
  }

  return (
    <Select input={<Input />} value={value} onChange={(event) => onValueChange(event.target.value)} style={{ width: "95%" }}>
      <MenuItem value="">Todos</MenuItem>
      {options?.map((item) => {
        return (
          <MenuItem key={item.aba} value={item.aba}>
            {item.aba ?? item.aba}
          </MenuItem>
        );
      })}
    </Select>
  );
};
export const AbasTypeProvider = (props) => <DataTypeProvider editorComponent={AbasEditor} {...props} />;
