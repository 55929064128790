import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import Switch from "react-switch";
import Modal from "react-modal";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState, EditingState, CustomPaging, FilteringState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility, TableFixedColumns } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, ExportXlsx, TIMER_FILTER, APPNAME_AUTOMACAOCARGAH9J } from "../utils/react_grid_utils";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import { ProcedimentosSmartTypeProvider } from "../utils/react_grid_procedimentos_smart_column";
import { ProcedimentosH9JFilterCell } from "../utils/react_grid_procedimentosh9j_filter";
import Import from "../utils/import";
import authenticatedUserContext from "../security/AuthenticatedUserContext";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    fontWeight: "bold",
    fontSize: "16px",
    maxWidth: "600px",
    transform: "translate(-50%, -50%)",
  },
};

const getRowId = (row) => row.id;
const api_resource = "carga-h9j/de-para-procedimentos";

export default function DeParaProcedimentosH9JTable() {
  const alert = useAlert();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [pendentes, setPendentes] = useState(false);
  const [avisoPendentes, setAvisoPendentes] = useState(false);
  const [tableColumnExtensions] = useState([
    { columnName: "id", width: 60 },
    { columnName: "codigo_h9j", width: 95 },
    { columnName: "descricao_h9j", width: 500 },
    { columnName: "codigo_descricao_smart", width: 600 },
  ]);
  const [filteringStateColumnExtensions] = useState([{ columnName: "codigo_smart", filteringEnabled: false }]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);

  function get(page, filters) {
    const queryString = QueryString(page, pageSize, filters);
    http.get(api_resource + queryString).then(
      (response) => {
        console.log(response?.data?.data);        
        addRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function contain_pendentes() {
    const queryString = QueryString(0, pageSize, []);
    http.get(api_resource + "/pendentes/" + queryString).then(
      (response) => {
        console.log(response);
        console.log(response?.data?.data);
        if(response?.data?.total > 0)
          setAvisoPendentes(true);
        else
          setAvisoPendentes(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function get_pendentes(page, filters) {
    const queryString = QueryString(page, pageSize, filters);
    http.get(api_resource + "/pendentes/" + queryString).then(
      (response) => {
        console.log(response);
        addRows(response?.data?.data)
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function addRows(rows)
  {
    rows.forEach(function(element)
    {
      if(element.codigo_smart != null && element.descricao_smart != null)
        element.codigo_descricao_smart = `${element.codigo_smart} - ${element.descricao_smart}`
      else
        element.codigo_descricao_smart = "";
    });

    setRows(rows);
  }

  useEffect(() => {
    get(0, []);
    contain_pendentes();
  }, []);

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function closeModal() {
    setAvisoPendentes(false);
  }

  function handlePage(page) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  function handlePendentes(checked) {
    setPendentes(checked);
    if(checked)
      get_pendentes(0,[]);
    else
      get(0, []);
    
    closeModal();
  }

  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      added[0].codigo_smart = added[0].codigo_descricao_smart?.split('-')[0];
      added[0].descricao_smart = added[0].codigo_descricao_smart?.split('-')[1]
      Create(api_resource, added[0], alert).then((response) => {
        var new_row = response.data[0];
        new_row.codigo_descricao_smart = `${new_row?.codigo_smart} - ${new_row?.descricao_smart}`;
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      var item = changedRows.find((row) => changed[row.id]);
      var smart = [] 
      smart = item?.codigo_descricao_smart?.split((/-(.+)/));
      if (smart.length > 0)
        item.codigo_smart = smart[0];
      
      if(smart.length > 1)
        item.descricao_smart = smart[1];

      Update(api_resource, item, alert);
      setRows(changedRows);
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };

  var columns = [
    { name: "id", title: "ID" },
    { name: "codigo_h9j", title: "Código H9J" },
    { name: "descricao_h9j", title: "Descrição H9J" },
    { name: "codigo_descricao_smart", title: "Código e descrição D2I" },
  ];

  return (
    <div id="wrapper">
      <Menu />
      <Modal isOpen={avisoPendentes} style={customStyles} contentLabel="Aviso pendentes">
        <form>
          <div className="form-group  row">
            <div className="col-sm-12">{"Há procedimentos pendentes para parametrização de código e descrição D2I. Deseja realizar as parametrizações para os itens pendentes?"}</div>
          </div>
          <div className="hr-line-dashed"></div>
          <div className="form-group row" style={{ textAlign: "right" }}>
            <div className="col-sm-12 col-sm-offset-2">
              <button className="btn btn-primary btn-sm" type="button" onClick={closeModal} style={{ width: "70px" }}>
                Fechar
              </button>
              <button className="btn btn-sm btn-success" style={{ marginLeft: "10px", width: "70px" }} type="button" onClick={() => handlePendentes(true)}>
                Sim
              </button>
            </div>
          </div>
        </form>
      </Modal> 
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-6">
            <h2>De-Para Procedimentos H9J</h2>
          </div>
          <div className="col-lg-4 form-group row">
          <label style={{ paddingRight:"10px", paddingTop:"25px" }}>Pendentes</label>
            <div style={{ marginTop: "20px" }}>
              <Switch onChange={handlePendentes} checked={pendentes} />
            </div>
          </div>
          <div className="col-lg-2">
            <button className="btn btn-primary btn-sm" style={{ marginTop: "20px" }} type="button" onClick={() => ExportXlsx(api_resource, alert)}>
              Exportar
            </button>
            <Import api_resource={api_resource} alert={alert} appname={APPNAME_AUTOMACAOCARGAH9J} excluir={true} onFinish={() => get([0], [])} />
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} columnExtensions={tableColumnExtensions} />
                    <FilteringState onFiltersChange={handleFilters} columnExtensions={filteringStateColumnExtensions}/>
                    <Table columnExtensions={tableColumnExtensions} />
                    <ProcedimentosSmartTypeProvider for={["codigo_descricao_smart"]} />
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <TableEditRow />
                    {authenticatedUserContext.allow_edit(APPNAME_AUTOMACAOCARGAH9J) === true ? <TableEditColumn showAddCommand={!addedRows.length} showEditCommand showDeleteCommand messages={editColumnMessages} /> : <></>}
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableFilterRow cellComponent={ProcedimentosH9JFilterCell}/>
                    <TableFixedColumns leftColumns={[TableEditColumn.COLUMN_TYPE]} />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
