import React, { useState } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import { PagingState, EditingState, CustomPaging, FilteringState, IntegratedFiltering, RowDetailState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableColumnVisibility, TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import { Create, Update, Remove, TIMER_FILTER } from "../utils/react_grid_utils";
import { ParticAnestesistaTypeProvider } from "../utils/react_grid_partic_anestesista_column";
import { NumberTypeProvider } from "../utils/react_grid_number_column";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import TextField from "@material-ui/core/TextField";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Input from "@material-ui/core/Input";
import { APPNAME_GERACAOXML } from "../utils/react_grid_utils";
import authenticatedUserContext from "../security/AuthenticatedUserContext";

const getRowId = (row) => row.doc_num;
const api_resource = "notafiscal/individuais";

const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    fontWeight: "bold",
    fontSize: "16px",
    maxWidth: "600px",
    transform: "translate(-50%, -50%)",
  },
};


export default function IndividualTable() {
  const alert = useAlert();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "paciente", width: 300 },
    { columnName: "cpf", width: 300 },
    { columnName: "doc_num", width: 100 },
    { columnName: "data_agendamento", width: 150 },
    { columnName: "data_vencimento", width: 150 },
    { columnName: "status_bot", width: 150 },
    { columnName: "tipo_recebimento", width: 200 },
    { columnName: "cirurgiao", width: 300 },
    { columnName: "valor", width: 150 },
    { columnName: "observacao", width: 400 },
    { columnName: "sap_invoice_docnum", width: 100 },
    { columnName: "relative_name", width: 200 },
    { columnName: "anestesista", width: 200 },
    { columnName: "crm", width: 200 },
  ]);
  const [defaultHiddenColumnNames] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);
  const [dataVencimento, setDataVencimento] = useState();
  const [concluirIsOpen, setConcluirIsOpen] = useState(false);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [observacao, setObservacao] = useState("");
  let inputPaciente, inputDataInicial, inputDataFinal, inputCirurgiao, inputCobranca, inputDocNum;

  function get(page) {
    let queryString = `?page=${page + 1}`;

    if (inputPaciente?.value) {
      queryString = queryString + "&paciente=" + inputPaciente.value;
    }
    if (inputCirurgiao?.value) {
      queryString = queryString + "&cirurgiao=" + inputCirurgiao.value;
    }
    if (inputCobranca?.value) {
      queryString = queryString + "&cobranca=" + inputCobranca.value;
    }
    if (inputDocNum?.value) {
      queryString = queryString + "&docnum=" + inputDocNum.value;
    }
    if (inputDataInicial?.value) {
      queryString = queryString + "&dataInicial=" + inputDataInicial.value;
    }
    if (inputDataFinal?.value) {
      queryString = queryString + "&dataFinal=" + inputDataFinal.value;
    }

    let data = new Date();
    data.setMonth(data.getMonth() + 1);
    setDataVencimento(data.toISOString().substring(0, 10));

    http.get(api_resource + queryString).then(
      (response) => {
        console.log(response?.data?.data);
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function handlePage(page, totalRows) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };


  var columns = [
    { name: "paciente", title: "Paciente" },
    { name: "doc_num", title: "DocNum" },
    { name: "data_agendamento", title: "Data Agendamento" },
    { name: "data_vencimento", title: "Data Vencimento" },
    { name: "status_bot", title: "Status" },
    { name: "tipo_recebimento", title: "Tipo Recebimento" },
    { name: "cirurgiao", title: "Cirurgião" },
    { name: "relative_name", title: "Responsável" },
    { name: "valor", title: "Valor" },
    { name: "sap_invoice_docnum", title: "NF" },
  ];

  function onFormSubmit(e) {
    e.preventDefault();
    get(0, []);
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      onFormSubmit(e);
    }
  }

  function closeModal() {
    setConcluirIsOpen(false);
  }

  function concluir(row) {

    setConcluirIsOpen(false);

    row.observacao = observacao;

    if(row.data_vencimento == null || row.data_vencimento == undefined || row.data_vencimento == "")
    {
      window.alert("O campo data de vencimento é obrigatório!");
    }
    else if(row.valor == null || row.valor == undefined)
    {
      window.alert("O campo valor é obrigatório!");
    }
    else
    {
      http.post(`${api_resource}`, row).then(
        (response) => {
          closeModal();
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Sucesso!");
          }          
          get(0);
        },
        (error) => {
          if (error?.messages) {
            error.messages.map((erro_msg) => {
              if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                alert.info("Nenhuma alteração enviada.");
              } else {
                alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
              }
            });
          } else {
            alert.error("Não foi possível excluir o registro!");
          }
        }
      );
    }
  }

  function handleExpandedRow(ids) {
    setObservacao("");

    console.log(ids);
    const newId = ids.slice(-1);
    if(newId.length > 0)
    {
      const currentRow = rows.find(i => i.doc_num == newId[0])
      const obs = getObservacao(currentRow);
      setObservacao(obs);
    }

    setExpandedRowIds(newId);
  }

  function getDataVencimento(row) {
    if(row.data_vencimento == null && !["CONVÊNIO","REPASSE"].includes(row.tipo_recebimento))
    {
      let data = new Date(row.data_agendamento);
      let months = 1;
      if (row.tipo_recebimento === "REEMBOLSO") {
        months = 2;
      } else if (row.tipo_recebimento === "CONVÊNIO CONSULTÓRIO") {
        months = 3;
      }
      data.setMonth(data.getMonth() + months);
      row.data_vencimento = data.toISOString().substring(0, 10);
    }
    return row.data_vencimento;
  }

  function getObservacao(row) {

    var obs = "";

      var doc = row.cpf.replace(/[^0-9]/g,'');

      const dataVencimento = getDataVencimento(row);

      if (doc.length > 11) {
          // PJ
          let vlr_bruto = (row.valor * 1).toFixed(2);
          let impostos = (vlr_bruto * 0.0615).toFixed(2);
          if(impostos < 10) {
            impostos = 0
          }
          let vlr_liquido = (vlr_bruto - impostos).toFixed(2);
          obs =   "" +
                  `Serviços de Anestesia Prestados\n` +
                  `\n` +
                  `${dataVencimento ? 'Data Vencimento – ' + dataVencimento.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1") : ''}\n` +
                  `Valor Bruto – R$ ${Intl.NumberFormat("pt-BR",{stype: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(vlr_bruto)}\n` +
                  `Valor Líquido – R$ ${Intl.NumberFormat("pt-BR",{stype: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(vlr_liquido)}\n` +
                  `\n` +
                  `[1] Valor aproximando dos tributos 16,70%\n` +
                  `Conforme Lei nº:12741 de 08.12.2012`;
          if (row.tipo_recebimento === "ASSOCIAÇÃO HIAE") {
            obs += "\n\n" +
                  "Dr.Flavio Takaoka\n" +
                  "CRM: 31.745";
          }
      } else {
          // PF
          obs = ""+
                `Serviços de Anestesia Prestados a paciente:\n` +
                `${row.paciente}\n` +
                `\n` +
                `${dataVencimento ? 'Data Vencimento – ' + dataVencimento.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1") : ''}\n` +
                `\n` +
                `[1]Valor aproximado dos tributos 15,55%\n` +
                `Conforme Lei nº 12741 de 08.12.2012\n` +
                `\n` +
                `DR(A). ${row.anestesista}\n` +
                `CRM: ${!!row.crm?row.crm:""}`;
      }

    return obs;
  }

  function handleObservacaoChange(e)
  {
    setObservacao(e.target.value)
  }

  function reprocessar() {
    var confirmado = window.confirm("Confirma o reprocessamento?");
    if (confirmado === true) {
      http.put(api_resource + "/reprocessar").then(
        (response) => {
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Sucesso!");
          }
        },
        (error) => {
          alert.error("Erro ao solicitar o Reprocessamento NF");
        }
      );
    }
  }

  const RowDetail = ({ row }) => (

    row.notafiscal_id == null ? 

      <div className="ibox">
        <div className="row wrapper"> 
          <div className="col-lg-2">
            <TextField
                input={<Input />}
                id="dateEnd"
                label="Data vencimento"
                type="date"
                variant="outlined"                                
                defaultValue={getDataVencimento(row)}
                onChange={(e) => {
                    row.data_vencimento = e.target.value;
                    const obs = getObservacao(row);
                    setObservacao(obs);
                  }
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
          </div>
          <div className="col-lg-2">
            <CurrencyTextField
                id="valor"
                variant="outlined"
                label="Valor"
                defaultValue={row.valor}                               
                currencySymbol="R$"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                style={{ width: "180px" }}
                onChange={(e) => {row.valor = e.target.value}}
                InputLabelProps={{
                  shrink: true,
                }}
              />
          </div>
          <div className="col-lg-3">
            <TextField 
                style ={{width: "400px"}}
                input={<Input />}
                id={row.id}
                label="Observação de abertura e encerramento"
                type="text"
                multiline="true"
                variant="outlined"
                defaultValue={observacao}
                onBlur={(e) => handleObservacaoChange(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
          </div>
          <div className="col-lg-2">
              <button className="btn btn-sm btn-success" type="button" style={{ marginTop: "20px" }} onClick={() => setConcluirIsOpen(true)}>
                Criar NF
              </button>
          </div>
        </div>
        <Modal isOpen={concluirIsOpen} style={customStyles}>
          <form>
            <div className="form-group  row">
              <div className="col-sm-12">
              Confirma a criação da nota fiscal?
            </div>
            </div>
            <div className="hr-line-dashed"></div>
            <div className="form-group row" style={{ textAlign: "right" }}>
              <div className="col-sm-12 col-sm-offset-2">
                <button className="btn btn-primary btn-sm" type="button" onClick={closeModal} style={{ width: "70px" }}>
                  Voltar
                </button>
                <button className="btn btn-sm btn-success" style={{ marginLeft: "10px", width: "100px" }} type="button" onClick={() => concluir(row)}>
                  Confirmar
                </button>
              </div>
            </div>
          </form>
        </Modal> 
      </div>

    : <></>
  );

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Notas Fiscais Individuais</h2>
          </div>
          <div className="col-lg-2">
            <div className="row" style={{ paddingTop: "20px" }}>
              {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true ? (
                <button className="btn btn-sm btn-success" type="button" onClick={reprocessar}>
                  Reprocessar NF
                </button>
              ) : (
                <></>
              )}{" "}
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
              <div className="ibox-content">
                  <form onSubmit={onFormSubmit} onKeyPress={handleKeyPress}>
                    <div className="row">
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Paciente
                        </label>
                        <input
                          ref={(node) => {
                            inputPaciente = node;
                          }}
                          type="text"
                          className="form-control col-sm-12"
                        />
                      </div>      
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Cirurgião
                        </label>
                        <input
                          ref={(node) => {
                            inputCirurgiao = node;
                          }}
                          type="text"
                          className="form-control col-sm-12"
                        />
                      </div>                                     
                      <div className="col-md-4">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Período
                        </label>
                        <div className="row">
                          <input
                            ref={(node) => {
                              inputDataInicial = node;
                            }}
                            type="date"
                            className="form-control col-sm-6"
                          />
                          <input
                            ref={(node) => {
                              inputDataFinal = node;
                            }}
                            type="date"
                            className="form-control col-sm-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Tipo Recebimento
                        </label>  
                        <select ref={(node) => {
                            inputCobranca = node;
                          }}
                          className="form-control col-sm-12"
                          >
                            <option selected value=""></option>
                            <option value="ASSOCIAÇÃO HIAE">ASSOCIAÇÃO HIAE</option>
                            <option value="CONVÊNIO">CONVÊNIO</option>
                            <option value="CONVÊNIO CONSULTÓRIO">CONVÊNIO CONSULTÓRIO</option>
                            <option value="CONVÊNIO HOSPITAL">CONVÊNIO HOSPITAL</option>
                            <option value="CORTESIA">CORTESIA</option>
                            <option value="INDEFINIDO">INDEFINIDO</option>
                            <option value="PARTICULAR">PARTICULAR</option>
                            <option value="PROJETO CUIDAR">PROJETO CUIDAR</option>
                            <option value="REEMBOLSO">REEMBOLSO</option>
                            <option value="REPASSE CDB">REPASSE CDB</option>
                            <option value="REPASSE H9J">REPASSE H9J</option>
                            <option value="REPASSE HIAE">REPASSE HIAE</option>
                            <option value="REPASSE HIAE TX">REPASSE HIAE TX</option>
                            <option value="REPASSE SANTA VIRGÍNIA">REPASSE SANTA VIRGÍNIA</option>
                            <option value="REPASSE VILA">REPASSE VILA</option>
                            <option value="REPASSE VITA">REPASSE VITA</option>
                        </select>
                      </div>
                      <div className="col-md-2">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          DocNum
                        </label>
                        <input
                          ref={(node) => {
                            inputDocNum = node;
                          }}
                          type="text"
                          className="form-control col-sm-12"
                        />
                      </div>                                     
                      <div className="col-md-2">
                          <div className="col-sm-3" style={{paddingTop:"35px"}}>
                            <button className="btn btn-primary" type="submit" type="button" onClick={onFormSubmit}>
                              Buscar
                            </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} columnExtensions={tableColumnExtensions} />
                    <Table columnExtensions={tableColumnExtensions} />
                    <RowDetailState 
                      expandedRowIds={expandedRowIds}
                      onExpandedRowIdsChange={handleExpandedRow}                    
                    />
                    <FilteringState onFiltersChange={handleFilters} />
                    <IntegratedFiltering />
                    <DateTypeProvider for={["data_agendamento", "data_vencimento"]} />
                    <ParticAnestesistaTypeProvider for={["partic_anestesista"]} />
                    <NumberTypeProvider for={["valor"]} />
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableRowDetail contentComponent={RowDetail} />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
