import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import Menu from "../Menu";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState, CustomPaging, RowDetailState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import { ViaTypeProvider } from "../utils/react_grid_via_column";
import { APPNAME_GERACAOXML } from "../utils/react_grid_utils";
import authenticatedUserContext from "../security/AuthenticatedUserContext";

const getRowId = (row) => row.doc_num;
const api_resource = "administracao-guias";
const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    fontWeight: "bold",
    fontSize: "16px",
    maxWidth: "600px",
    transform: "translate(-50%, -50%)",
  },
};

export default function RevisaoAgendamentosTable() {
  const alert = useAlert();

  const [importIsOpen, setImportIsOpen] = useState(false);
  const [concluirIsOpen, setConcluirIsOpen] = useState(false);
  const [resetViaIsOpen, setResetViaIsOpen] = useState(false);
  const [motivoInclusaoManual, setMotivoInclusaoManual] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [queryApi, setQueryApi] = useState("");
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "id", width: 60 },
    { columnName: "data", width: 120, editingEnabled: false },
    { columnName: "doc_num", width: 90, editingEnabled: false },
    { columnName: "via", width: 110 },
    { columnName: "paciente", width: 350, editingEnabled: false },
    { columnName: "hospital", width: 250, editingEnabled: false },
    { columnName: "convenio", width: 200, editingEnabled: false },
    { columnName: "anestesista", width: 350, editingEnabled: false },
    { columnName: "tuss", width: 100, editingEnabled: false },
    { columnName: "desc_proc", width: 600, editingEnabled: false },
    { columnName: "lote", width: 90, editingEnabled: false },
  ]);
  const [convenios, setConvenios] = useState([]);
  const [convenioSelecionado, setConvenioSelecionado] = useState();
  const motivoConclusaoRef = useRef('')
  let inputPaciente, inputAnestesista, inputConvenio, inputDataInicial, inputDataFinal, inputPlano, inputHospital, inputProcedimento, inputDocNum;

  function get(page, filters) {
    let queryString = `?page=${page + 1}`;

    if (inputPaciente?.value) {
      queryString = queryString + "&paciente=" + inputPaciente.value;
    }
    if (inputAnestesista?.value) {
      queryString = queryString + "&anestesista=" + inputAnestesista.value;
    }
    if (inputConvenio?.value) {
      queryString = queryString + "&convenio=" + inputConvenio.value;
    }
    if (inputDataInicial?.value) {
      queryString = queryString + "&dataInicial=" + inputDataInicial.value;
    }
    if (inputDataFinal?.value) {
      queryString = queryString + "&dataFinal=" + inputDataFinal.value;
    }
    if (inputPlano?.value) {
      queryString = queryString + "&plano=" + inputPlano.value;
    }
    if (inputHospital?.value) {
      queryString = queryString + "&hospital=" + inputHospital.value;
    }
    if (inputProcedimento?.value) {
      queryString = queryString + "&procedimento=" + inputProcedimento.value;
    }
    if (inputDocNum?.value) {
      queryString = queryString + "&doc_num=" + inputDocNum.value;
    }

    setQueryApi(queryString);

    getApi(queryString);
  }

  function getApi(queryString) {
    http.get(api_resource + queryString).then(
      (response) => {
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function getConvenios() {
    http.get("operadoras-all").then((response) => {
      setConvenios(response?.data);
    });
  }

  useEffect(() => {
    getConvenios();
  }, []);

  var columns = [
    { name: "doc_num", title: "DocNum" },
    { name: "data", title: "Data" },
    { name: "paciente", title: "Paciente" },
    { name: "anestesista", title: "Anestesista" },
    { name: "hospital", title: "Hospital" },
    { name: "convenio", title: "Convênio" },
  ];

  var detail_columns = [
    { name: "tuss", title: "TUSS" },
    { name: "desc_proc", title: "Procedimento" },
    { name: "status_bot", title: "Status" },
    { name: "plano", title: "Plano" },
    { name: "via", title: "Via" },
    { name: "lote", title: "Lote" },
  ];

  function handlePage(page, totalRows) {
    setPageIndex(page);
    get(page, []);
  }

  function reprocessar() {
    var confirmado = window.confirm("Confirma o reprocessamento?");
    if (confirmado === true) {
      http.put(api_resource + "/reprocessar").then(
        (response) => {
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Sucesso!");
          }
        },
        (error) => {
          if (error?.messages) {
            error.messages.map((erro_msg) => {
              if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                alert.info("Nenhuma alteração enviada.");
              } else {
                alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
              }
            });
          } else {
            alert.error("Não foi possível excluir o registro!");
          }
        }
      );
    }
  }

  function gerarxmlpdf() {
    if (convenioSelecionado > 0) {
      var confirmado = window.confirm("Confirma a geração?");
      if (confirmado === true) {
        http.put(api_resource + "/gerarxmlpdf/" + convenioSelecionado).then(
          (response) => {
            if (response?.data) {
              alert.info(response?.data, { timeout: INFO_TIMEOUT });
            } else {
              alert.success("Sucesso!");
            }
          },
          (error) => {
            if (error?.messages) {
              error.messages.map((erro_msg) => {
                if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                  alert.info("Nenhuma alteração enviada.");
                } else {
                  alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
                }
              });
            } else {
              alert.error("Não foi possível excluir o registro!");
            }
          }
        );
      }
    } else {
      window.alert("Selecione uma operadora!");
    }
  }

  function reset(row) {
    var status_bot = "PENDENTE";
    if (row.status_bot == "CONCLUIDO") {
      status_bot = "GERAR_XML";
    }

    http.put(`${api_resource}/reset`, { doc_num: row.doc_num, status_bot: status_bot }).then(
      (response) => {
        closeModal();
        alert.success("Sucesso!");
        getApi(queryApi);
      },
      (error) => {
        if (error?.messages) {
          error.messages.map((erro_msg) => {
            if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
              alert.info("Nenhuma alteração enviada.");
            } else {
              alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
            }
          });
        } else {
          alert.error("Não foi possível excluir o registro!");
        }
      }
    );
  }

  function resetVia(row) {
    http.put(`${api_resource}/reset-via`, { doc_num: row.doc_num }).then(
      (response) => {
        closeModal();
        alert.success("Sucesso!");
        getApi(queryApi);
      },
      (error) => {
        if (error?.messages) {
          error.messages.map((erro_msg) => {
            if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
              alert.info("Nenhuma alteração enviada.");
            } else {
              alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
            }
          });
        } else {
          alert.error("Não foi possível excluir o registro!");
        }
      }
    );
  }

  function concluir(row) {

    http.put(`${api_resource}/concluir`, { doc_num: row.doc_num, motivo: motivoConclusaoRef.current.value }).then(
      (response) => {
        closeModal();
        alert.success("Sucesso!");
        getApi(queryApi);
      },
      (error) => {
        if (error?.messages) {
          error.messages.map((erro_msg) => {
            if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
              alert.info("Nenhuma alteração enviada.");
            } else {
              alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
            }
          });
        } else {
          alert.error("Não foi possível excluir o registro!");
        }
      }
    );
  }

  function onFormSubmit(e) {
    e.preventDefault();
    get(0, []);
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      onFormSubmit(e);
    }
  }

  function closeModal() {
    setImportIsOpen(false);
    setConcluirIsOpen(false);
    setResetViaIsOpen(false);
  }

  const RowDetail = ({ row }) => (
    <div className="ibox ">
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-6">
          {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true && row?.procedimentos?.some((proc) => proc.status_bot != "CONCLUIDO") ? (
            <button className="btn btn-sm btn-success" style={{ marginTop: "20px" }} type="button" onClick={() => setImportIsOpen(true)}>
              Alterar Status / Reset
            </button>
          ) : (
            <></>
          )}
          {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true && row?.procedimentos?.some((proc) => proc.status_bot != "CONCLUIDO") ? (
            <button className="btn btn-sm btn-success" style={{ marginTop: "20px", marginLeft:"5px" }} type="button" onClick={() => setResetViaIsOpen(true)}>
              Reset Vias
            </button>
          ) : (
            <></>
          )}
          {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true && row?.procedimentos?.some((proc) => proc.status_bot != "CONCLUIDO") ? (
            <button className="btn btn-primary btn-sm" style={{ marginTop: "20px", marginLeft:"5px" }} type="button" onClick={() => setConcluirIsOpen(true)}>
              Concluir Manualmente
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Grid rows={row.procedimentos} columns={detail_columns}>
        <Table columnExtensions={tableColumnExtensions} />
        <DateTypeProvider for={["data"]} />
        <ViaTypeProvider for={["via"]} />
        <TableHeaderRow />
      </Grid>
      <Modal isOpen={importIsOpen} style={customStyles} contentLabel="Teste">
        <form>
          <div className="form-group  row">
            <div className="col-sm-12">{row.status_bot == "CONCLUIDO" ? "O status de todas as guias deste lote será alterado para GERAR_XML. Deseja confirmar?" : "O status de todos os itens que possuem o mesmo número de documento será alterado para PENDENTE. Deseja confirmar?"}</div>
          </div>
          <div className="hr-line-dashed"></div>
          <div className="form-group row" style={{ textAlign: "right" }}>
            <div className="col-sm-12 col-sm-offset-2">
              <button className="btn btn-primary btn-sm" type="button" onClick={closeModal} style={{ width: "70px" }}>
                Voltar
              </button>
              <button className="btn btn-sm btn-success" style={{ marginLeft: "10px", width: "100px" }} type="button" onClick={() => reset(row)}>
                Confirmar
              </button>
            </div>
          </div>
        </form>
      </Modal>
      <Modal isOpen={concluirIsOpen} style={customStyles}>
        <form>
          <div className="form-group  row">
            <div className="col-sm-12">
            Informe um motivo para conclusão manual:
          </div>
          </div>
          <div className="form-group  row">
            <div className="col-sm-12">
            <TextField id="motivo" type="text" inputRef={motivoConclusaoRef} fullWidth={true} /></div>
          </div>
          <div className="hr-line-dashed"></div>
          <div className="form-group row" style={{ textAlign: "right" }}>
            <div className="col-sm-12 col-sm-offset-2">
              <button className="btn btn-primary btn-sm" type="button" onClick={closeModal} style={{ width: "70px" }}>
                Voltar
              </button>
              <button className="btn btn-sm btn-success" style={{ marginLeft: "10px", width: "100px" }} type="button" onClick={() => concluir(row)}>
                Confirmar
              </button>
            </div>
          </div>
        </form>
      </Modal>  
      <Modal isOpen={resetViaIsOpen} style={customStyles} contentLabel="Reset Vias">
        <form>
          <div className="form-group  row">
            <div className="col-sm-12">{"A Via de todos os itens não concluídos que possuem este mesmo número de documento será alterada para 0 e retornará para o status 'PENDENTE'. Deseja confirmar?"}</div>
          </div>
          <div className="hr-line-dashed"></div>
          <div className="form-group row" style={{ textAlign: "right" }}>
            <div className="col-sm-12 col-sm-offset-2">
              <button className="btn btn-primary btn-sm" type="button" onClick={closeModal} style={{ width: "70px" }}>
                Voltar
              </button>
              <button className="btn btn-sm btn-success" style={{ marginLeft: "10px", width: "100px" }} type="button" onClick={() => resetVia(row)}>
                Confirmar
              </button>
            </div>
          </div>
        </form>
      </Modal>          
    </div>
  );

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-6">
            <h2>Revisão de Agendamentos</h2>
          </div>
          <div className="col-lg-6">
            <div className="row" style={{ paddingTop: "20px" }}>
              <div className="col-lg-9" style={{ paddingRight: "40px" }}>
                <div className="row">
                  <div className="col-lg-12" style={{ display: "inline-flex" }}>
                    {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true ? (
                      <>
                        <Select input={<Input />} style={{ width: "70%" }} onChange={(event) => setConvenioSelecionado(event.target.value)}>
                          <MenuItem value="">...</MenuItem>
                          {convenios?.map((item) => {
                            return (
                              <MenuItem key={item.nome} value={item.id}>
                                {item.nome ?? item.nome}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <button className="btn btn-sm btn-success" type="button" onClick={gerarxmlpdf} style={{ marginLeft: "10px" }}>
                          Gerar XML/PDF
                        </button>
                      </>
                    ) : (
                      <></>
                    )}{" "}
                  </div>
                </div>
              </div>
              <div className="col-lg-3" style={{ display: "flex" }}>
                {authenticatedUserContext.allow_edit(APPNAME_GERACAOXML) === true ? (
                  <button className="btn btn-sm btn-success" type="button" onClick={reprocessar}>
                    Reprocessar
                  </button>
                ) : (
                  <></>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <form onSubmit={onFormSubmit} onKeyPress={handleKeyPress}>
                    <div className="row">
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Paciente
                        </label>
                        <input
                          ref={(node) => {
                            inputPaciente = node;
                          }}
                          type="text"
                          className="col-sm-12"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Anestesista
                        </label>
                        <input
                          ref={(node) => {
                            inputAnestesista = node;
                          }}
                          type="text"
                          className="form-control col-sm-12"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Convênio
                        </label>
                        <input
                          ref={(node) => {
                            inputConvenio = node;
                          }}
                          type="text"
                          className="form-control col-sm-12"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Período
                        </label>
                        <div className="row">
                          <input
                            ref={(node) => {
                              inputDataInicial = node;
                            }}
                            type="date"
                            className="form-control col-sm-6"
                          />
                          <input
                            ref={(node) => {
                              inputDataFinal = node;
                            }}
                            type="date"
                            className="form-control col-sm-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Plano
                        </label>
                        <input
                          ref={(node) => {
                            inputPlano = node;
                          }}
                          type="text"
                          className="col-sm-12"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Hospital
                        </label>
                        <input
                          ref={(node) => {
                            inputHospital = node;
                          }}
                          type="text"
                          className="col-sm-12"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                          Procedimento
                        </label>
                        <input
                          ref={(node) => {
                            inputProcedimento = node;
                          }}
                          type="text"
                          className="col-sm-12"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="col-sm-6 col-form-label" style={{ paddingLeft: "1px" }}>
                          Doc. Num.
                        </label>
                        <div className="row">
                          <input
                            ref={(node) => {
                              inputDocNum = node;
                            }}
                            type="text"
                            className="form-control col-sm-6"
                          />
                          <div className="col-sm-3">
                            <button className="btn btn-primary" type="submit" type="button" onClick={onFormSubmit}>
                              Buscar
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-3" style={{ textAlign: "right", verticalAlign: "bottom" }}></div> */}
                    </div>
                  </form>
                </div>
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <RowDetailState />
                    <Table columnExtensions={tableColumnExtensions} />
                    <DateTypeProvider for={["data"]} />
                    <TableHeaderRow />
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableRowDetail contentComponent={RowDetail} />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
