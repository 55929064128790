import { http } from "../../services/http";
import { downloadXlsx } from "../operator/Utils";

export const TIMER_FILTER = 700;

const ERROR_TIMEOUT = 20000;

export const APPNAME_GERACAOXML = "Geração XML";
export const APPNAME_DEMONSTRATIVOPAGAMENTO = "Demonstrativo Pagamento";
export const APPNAME_AGENDAMENTOS = "Agendamentos";
export const APPNAME_AUTOMACAOCARGAH9J = "Automação Carga H9J";
export const APPNAME_TAKAOKA = "Takaoka";
export const APPNAME_IMPORTACAOSAP = "Importação Smart x SAP";
export const APPNAME_PESQUISAMAILING = "Pesquisa de Avaliação - Mailing"
export const APPNAME_RELATORIOPERSONALIZADO = "Relatório Personalizado"
export const APPNAME_APADIGITAL = "APA Digital"


export const QueryString = (page, limit, filters) => {
  let filter = filters
    .reduce((acc, { columnName, value }) => {
      acc.push(`${columnName}=${encodeURIComponent(value)}`);
      return acc;
    }, [])
    .join("&");

  if (filters.length > 1) {
    filter = `${filter}`;
  }

  return `?page=${page + 1}&limit=${limit}&${filter}`;
};

export const Create = (api_resource, data, alert) => {
  return new Promise((resolve, reject) => {
    http.post(api_resource, data).then(
      (response) => {
        alert.success("Sucesso!");
        resolve(response);
      },
      (error) => {
        customError(error, alert);
        reject(error);
      }
    );
  });
};

export const Update = (api_resource, data, alert) => {
  return new Promise((resolve, reject) => {
    http.put(api_resource, data).then(
      (response) => {
        alert.success("Sucesso!");
        resolve(response);
      },
      (error) => {
        customError(error, alert);
        reject(error);
      }
    );
  });
};

export const Remove = (api_resource, id, alert) => {
  return new Promise((resolve, reject) => {
    http.delete(`${api_resource}/` + id).then(
      (response) => {
        alert.success("Sucesso!");
        resolve(response);
      },
      (error) => {
        customError(error, alert);
        reject(error);
      }
    );
  });
};

function customError(error, alert) {
  if (error?.messages) {
    error.messages.map((erro_msg) => {
      if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
        alert.info("Nenhuma alteração enviada.");
      } else {
        alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
      }
    });
  } else {
    alert.error("Não foi possível excluir o registro!");
  }
}

export const ExportXlsx = (api_resource, alert) => {
  http.get(`${api_resource}/export/xlsx`, { responseType: "blob" }).then(
    (response) => {
      console.log(response);

      downloadXlsx(response.data);
    },
    (error) => {
      console.log(error);
    }
  );
};
