import Input from "@material-ui/core/Input";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import { TableFilterRow } from "@devexpress/dx-react-grid-material-ui";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = (theme) => ({
  cell: {
    width: "100%",
    padding: theme.spacing(1),
  },
  input: {
    fontSize: "12px",
    width: "100%",
  },
});

const DateRangeFilterCellBase = ({ filter, onFilter, classes }) => {
  const [begin, setBegin] = React.useState(null);
  const [end, setEnd] = React.useState(null);

  const handleBeginChange = (event) => {
    setBegin(event.target.value);
    handleChange(event.target.value, end);
  };

  const handleEndChange = (event) => {
    setEnd(event.target.value);
    handleChange(begin, event.target.value);
  };

  const handleChange = (begin, end) => {
    onFilter({value: (begin ?? "") + "~" + (end ?? "")});
  };
  

  return (
  <TableCell className={classes.cell}>
    <TextField
      input={<Input />}
      id="dateBegin"
      type="date"
      value={begin ? begin : ""}
      onChange={handleBeginChange}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">De:</InputAdornment>,
      }}
    />
    <TextField
      input={<Input />}
      id="dateEnd"
      type="date"
      value={end ? end : ""}
      onChange={handleEndChange}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">Até:</InputAdornment>,
      }}
    />
  </TableCell>
  )};
const DateRangeFilterCell = withStyles(styles, { name: "DateRangeFilterCell" })(DateRangeFilterCellBase);

export const FilterCell = (props) => {
  const { column } = props;
  if (column.name === "dia" || column.name === "data") {
    return <DateRangeFilterCell {...props} />;
  }
  return <TableFilterRow.Cell {...props} />;
};

// const DateFormatter = ({ value }) => OnlyDate(value).replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1");

// const DateEditor = ({ value, onValueChange, disabled }) => {
//   if (disabled) {
//     return value ? DateFormatter({ value }) : "";
//   }

//   let dateField = "";
//   if (value) {
//     dateField = OnlyDate(value);
//   }
//   return (
//     <TextField
//       input={<Input />}
//       id="date"
//       type="date"
//       defaultValue={dateField}
//       onChange={(event) => onValueChange(event.target.value)}
//       InputLabelProps={{
//         shrink: true,
//       }}
//     />
//   );
// };

// const OnlyDate = (value) => value.substr(0, 10);

// export const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={DateFormatter} editorComponent={DateEditor} {...props} />;
