import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState, EditingState, CustomPaging, FilteringState, RowDetailState } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility, TableFixedColumns, TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, ExportXlsx, TIMER_FILTER, APPNAME_AGENDAMENTOS } from "../utils/react_grid_utils";
import { DateTimeTypeProvider } from "../utils/react_grid_datetime_column";
import authenticatedUserContext from "../security/AuthenticatedUserContext";
import Import from "../utils/import";
import { downloadXlsx } from "../operator/Utils";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

const getRowId = (row) => row.id;
const api_resource = "d2i/importacao-lote-agendamentos";

const ERROR_TIMEOUT = 20000;
const INFO_TIMEOUT = 10000;


const ButtonBase = ({ row }) => {

  function export_falhas(id) {
    http.get(`d2i/importacao-lote-agendamentos/export/xlsx?id=${id}`, { responseType: "blob" }).then(
      (response) => {
        console.log(response);
  
        downloadXlsx(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }  

  return (
    row?.id > 0 && row.status !== 'PENDENTE' ?
      <button className="btn btn-sm btn-success" type="button" onClick={() =>  export_falhas(row.id)}>
        Exportar agendamentos
      </button>    
    :
      <></>
  );
};

export const ButtonTypeProvider = (props) => <DataTypeProvider formatterComponent={ButtonBase} editorComponent={ButtonBase} {...props} />;

export default function ImportacaoLoteAgendamentosD2ITable() {
  const alert = useAlert();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "id", width: 60 },
    { columnName: "data_importacao", width: 200 },
    { columnName: "external_name", width: 300 },
    { columnName: "status", width: 200 },
    { columnName: "exportacao", width: 300 },
  ]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);

  function get(page, filters) {
    const queryString = QueryString(page, pageSize, filters);
    http.get(api_resource + queryString).then(
      (response) => {
        console.log(response?.data?.data);
        setRows(response?.data?.data);
        setTotalItens(response?.data?.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    get(0, []);
  }, []);

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function handlePage(page) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      Create(api_resource, added[0], alert).then((response) => {
        var new_row = response.data[0];
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      var item = changedRows.find((row) => changed[row.id]);
      Update(api_resource, item, alert);
      setRows(changedRows);
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };

  function reprocessar() {
    var confirmado = window.confirm("Confirma o reprocessamento?");
    if (confirmado === true) {
      http.put(api_resource + "/reprocessar").then(
        (response) => {
          if (response?.data) {
            alert.info(response?.data, { timeout: INFO_TIMEOUT });
          } else {
            alert.success("Sucesso!");
          }
        },
        (error) => {
          if (error?.messages) {
            error.messages.map((erro_msg) => {
              if (erro_msg?.error?.message?.includes("Empty .update() call detected")) {
                alert.info("Nenhuma alteração enviada.");
              } else {
                alert.error(erro_msg?.error?.message, { timeout: ERROR_TIMEOUT });
              }
            });
          } else {
            alert.error("Não foi possível excluir o registro!");
          }
        }
      );
    }
  }  

  var columns = [
    { name: "id", title: "ID" },
    { name: "data_importacao", title: "Data importação" },
    { name: "external_name", title: "Arquivo" },
    { name: "status", title: "Status" },
    { name: "exportacao", title: "Exportar" },
  ];

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-6">
            <h2>Importação de agendamentos em lote</h2>
          </div>
          <div className="col-lg-6">
            <div className="row" style={{ paddingTop: "20px" }}>

            <div className="col-lg-6" style={{ display: "flex" }}>
                {authenticatedUserContext.allow_edit(APPNAME_AGENDAMENTOS) === true ? (
                  <>
                    <a href="modelos/modelo-importacao.xlsx" style={{ marginTop: "25px", paddingRight: "10px" }} download="arquivo_modelo">Arquivo Modelo</a>  
                    <button className="btn btn-primary btn-sm" style={{ marginTop: "20px" }}  type="button" onClick={reprocessar}>
                      Processar
                    </button>                  
                    <Import api_resource={api_resource} alert={alert} appname={APPNAME_AGENDAMENTOS} excluir={false} onFinish={() => {
                        alert.info('Arquivo enviado para processamento!', { timeout: INFO_TIMEOUT });
                        get([0], []);
                      }} />
                  </>
                ) : (
                  <></>
                )}{" "}
              </div>
            </div>  
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <div>
                    <button className="btn btn-primary btn-sm" style={{ marginLeft: "20px" }}  type="button" onClick={() => get([0], [])}>
                      Atualizar tabela
                    </button>     
                  </div>
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} columnExtensions={tableColumnExtensions} />
                    <FilteringState onFiltersChange={handleFilters} />
                    <Table columnExtensions={tableColumnExtensions} />
                    <RowDetailState />
                    <DateTimeTypeProvider for={["data_importacao"]} />
                    <ButtonTypeProvider for={["exportacao"]} />                    
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableFilterRow />          
                    <TableFixedColumns leftColumns={[TableEditColumn.COLUMN_TYPE]} />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
