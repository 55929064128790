import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import { http } from "../../services/http";
import { useAlert } from "react-alert";
import { PagingState, EditingState, CustomPaging, FilteringState, IntegratedFiltering, TableInlineCellEditing } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn, TableFilterRow, TableColumnVisibility } from "@devexpress/dx-react-grid-material-ui";
import { QueryString, Create, Update, Remove, ExportXlsx, TIMER_FILTER, APPNAME_IMPORTACAOSAP } from "../utils/react_grid_utils";
import { editColumnMessages } from "../utils/react_grid_edit_command_localization";
import { SapStatusTypeProvider } from "../utils/react_grid_status_smart_sap_column";
import Import from "../utils/import";
import { DateTypeProvider } from "../utils/react_grid_date_column";
import { FilterCell } from "../utils/react_grid_date_filter";
import authenticatedUserContext from "../security/AuthenticatedUserContext";

const getRowId = (row) => row.id;
const api_resource = "sap/alterar-sap";
let inputTipo = '';

export default function AlterarStatusTable() {
  const alert = useAlert();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [rows, setRows] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: "doc_num", editingEnabled: false, width: 80 },
    { columnName: "status", editingEnabled: false, width: 300 },
    { columnName: "data_smart", editingEnabled: false, width: 100 },
    { columnName: "paciente", editingEnabled: false, width: 250 },
    { columnName: "cpf", editingEnabled: false, width: 120 },
    { columnName: "cirurgiao", editingEnabled: false, width: 250 },
    { columnName: "status_robo", editingEnabled: true, width: 160 }
  ]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [filtersApi, setFiltersApi] = useState([]);

  function get(page, filters) {
    if (inputTipo?.value == 'D2I' || inputTipo?.value == 'SMART') {
      const queryString = QueryString(page, pageSize, filters);
      http.get(api_resource + queryString + "&db=" + inputTipo.value).then(
        (response) => {
          console.log(response?.data?.data);
          setRows(response?.data?.data);
          setTotalItens(response?.data?.total);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      alert.error('Escolha a Base de Dados!')
    }
  }

  useEffect(() => {
    get(0, []);
  }, []);

  const [timer, setTimer] = useState(null);

  function handleFilters(filters) {
    clearTimeout(timer);
    setFiltersApi(filters);

    setTimer(
      setTimeout(function () {
        get(pageIndex, filters);
      }, TIMER_FILTER)
    );
  }

  function handlePage(page, totalRows) {
    setPageIndex(page);
    get(page, filtersApi);
  }

  const changeAddedRows = (value) => {
    console.log(value);
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      Create(api_resource + "?db=" + inputTipo.value, added[0], alert).then((response) => {
        var new_row = response.data[0];
        const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        changedRows = [
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...new_row,
          })),
          ...rows,
        ];
        setRows(changedRows);
      });
    }
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      var item = changedRows.find((row) => changed[row.id]);
      Update(api_resource + "?db=" + inputTipo.value, item, alert);
      setRows(changedRows);
    }
    if (deleted) {
      var confirmado = window.confirm("Confirma a exclusão?");
      if (confirmado === true) {
        Remove(api_resource, deleted[0], alert);
        console.log(deleted);
        const deletedSet = new Set(deleted);
        changedRows = rows.filter((row) => !deletedSet.has(row.id));
      } else {
        changedRows = rows;
      }
      setRows(changedRows);
    }
  };

  var columns = [
    { name: "id", title: "ID" },
    { name: "doc_num", title: "ID" },
    { name: "status_robo", title: "Status" },
    { name: "data_smart", title: "Data" },
    { name: "paciente", title: "Paciente" },
    { name: "cpf", title: "CPF" },
    { name: "cirurgiao", title: "Cirurgião" },
    { name: "status", title: "Descrição" },

  ];

  function changeBase() {
    setRows([])
  }

  return (
    <div id="wrapper">
      <Menu />
      <div id="page-wrapper" className="gray-bg">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Alterar Status - SAP</h2>
          </div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-content">
                  <div className="row">
                    <label className="col-sm-12 col-form-label" style={{ paddingLeft: "1px" }}>
                      Base de Dados
                    </label>
                    <select ref={(node) => {
                      inputTipo = node;
                    }}
                      onClick={changeBase}
                      className="form-control col-sm-3"
                    >
                      <option selected value=""></option>
                      <option value="D2I">D2I</option>
                      <option value="SMART">SMART</option>
                    </select>

                  </div>
                  <div className="row" style={{ marginTop: "20px" }}>
                    <button className="btn btn-primary btn-sm" style={{ left: "20px", backgroundColor: "cadetblue" }} type="button" onClick={() => get([0], [])}>
                      Atualizar tabela
                    </button>
                  </div>
                </div>
                <div>
                </div>
              </div>
              <div className="ibox ">
                <div className="ibox-content">
                  <Grid rows={rows} columns={columns} getRowId={getRowId}>
                    <PagingState currentPage={pageIndex} pageSize={pageSize} onCurrentPageChange={handlePage} />
                    <EditingState editingRowIds={editingRowIds} onEditingRowIdsChange={setEditingRowIds} rowChanges={rowChanges} onRowChangesChange={setRowChanges} addedRows={addedRows} onAddedRowsChange={changeAddedRows} onCommitChanges={commitChanges} columnExtensions={tableColumnExtensions} />
                    <Table columnExtensions={tableColumnExtensions} />
                    <FilteringState onFiltersChange={handleFilters} />
                    <IntegratedFiltering />
                    <DateTypeProvider for={["data_smart"]} />
                    <SapStatusTypeProvider for={["status_robo"]} />
                    <TableHeaderRow />
                    <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
                    <TableEditRow />
                    {authenticatedUserContext.allow_edit(APPNAME_IMPORTACAOSAP) === true ? <TableEditColumn showEditCommand showDeleteCommand={false} messages={editColumnMessages} /> : <></>}
                    <CustomPaging totalCount={totalItens} />
                    <PagingPanel />
                    <TableFilterRow cellComponent={FilterCell} />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
