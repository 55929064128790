import Input from "@material-ui/core/Input";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import TextField from "@material-ui/core/TextField";

const TimeFormatter = ({ value }) => value?.substring(0, 5);

const TimeEditor = ({ value, onValueChange, disabled }) => {

  return (
    <TextField
      input={<Input />}
      id="time"
      type="time"
      defaultValue={value}
      onChange={(event) => onValueChange(event.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export const TimeTypeProvider = (props) => <DataTypeProvider formatterComponent={TimeFormatter} editorComponent={TimeEditor} {...props} />;
