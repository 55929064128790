import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Checkbox from '@material-ui/core/Checkbox';

const BooleanFormatter = ({ value }) => (
  <Checkbox checked={value} disabled />
);

const BooleanEditor = ({ value, onValueChange }) => (
  <Checkbox
    checked={value}
    onChange={event => onValueChange(event.target.checked)}
  />
);

export const BooleanCheckboxTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    editorComponent={BooleanEditor}
    {...props}
  />
);
